import { useState, type FC } from 'react';
import styles from '../TickerSelector.module.css';
import { useDispatch } from 'react-redux';
import { addCreatedTicker } from 'redux/customTicker/reducer';
import {
  type ICustomTickerCreationProps,
  MODE_CREATING_INDEX_STEP_ONE,
  MODE_LIST_OF_INDEX_TICKERS,
} from '../types';
import useWindowWidth from 'hooks/useWindowWidth';
import { parseTicker } from 'utils/parseTicker';
import { apiTicker } from 'services/api/Ticker/ApiTicker';
import ClosedLock from 'assets/images/Strategy/ClosedLock.svg';
import RedClosedLock from 'assets/images/Strategy/ClosedLockRed.svg';
import OpenedLock from 'assets/images/Strategy/OpenedLock.svg';
import { TickerSelectorCloseIcon } from 'assets/icons/TickerSelectorCloseIcon';

export const CustomTickerCreationTwo: FC<ICustomTickerCreationProps> = ({
  onClose,
  setTickerSelectorState,
  selectedIndexTicker,
  selectedTickersPercentsArray,
  setSelectedTickersPercentsArray,
  setSelectedIndexTicker,
  setCreatedTicker,
  createdTicker,
}) => {
  const dispatch = useDispatch();
  const [inputsLockData, setInputsLockData] = useState<boolean[]>(
    new Array(selectedTickersPercentsArray.length).fill(false),
  );
  const isMobileVersion = useWindowWidth() < 768;
  const [locksHighlighted, setLocksHighlighted] = useState(false);
  const arr = [...selectedTickersPercentsArray].slice();
  const changeData = (
    e: string,
    takeIndex: number,
    min: number,
    max: number,
    element?: any,
  ): void => {
    let value = parseFloat(e);
    const firstFalseIndex = inputsLockData.findIndex((value, index) => {
      return !value && index !== takeIndex;
    });
    const falseIndexes = inputsLockData.reduce((acc, value, index) => {
      if (value || index === takeIndex) {
        acc.push(index);
      }
      return acc;
    }, []);
    const falseIndexesNoTakeIndex = inputsLockData.reduce((acc, value, index) => {
      if (value && index !== takeIndex) {
        acc.push(index);
      }
      return acc;
    }, []);
    const sumOfFalseIndexValuesNoTakeIndex = falseIndexesNoTakeIndex.reduce(
      (acc: number, index) => {
        if (index >= 0 && index < arr.length) {
          acc += Number(arr[index][0]);
        }
        return acc;
      },
      0,
    );
    const sumOfFalseIndexValues = falseIndexes.reduce((acc: number, index) => {
      if (index >= 0 && index < arr.length) {
        acc += Number(arr[index][0]);
      }
      return acc;
    }, 0);

    const newMaxValue = 100 - sumOfFalseIndexValuesNoTakeIndex;
    if (isNaN(value) || value < min) {
      value = min;
    } else if (value > max) {
      value = newMaxValue;
    }

    const modifiedElem = arr[takeIndex].slice();
    if (!modifiedElem) return;

    modifiedElem[0] = value;
    arr[takeIndex] = modifiedElem as [number];

    arr.forEach((e: number[], i: number) => {
      if (i === takeIndex) return;
      e = e.slice();

      if (e[0] < 0) {
        e[0] = 0;
      } else {
        const sum = arr.reduce((a: number, b: number[]) => a + b[0], 0);

        if (sum > 100) {
          if (firstFalseIndex === -1 || sumOfFalseIndexValues > 100) {
            return;
          }
          if (firstFalseIndex !== -1) {
            if (!inputsLockData[i]) {
              e[0] -= sum - 100;
              if (e[0] < 0) e[0] = 0;
            }
          }
        } else if (sum < 100) {
          if (firstFalseIndex === -1 || sumOfFalseIndexValues > 100) {
            return;
          }
          if (!inputsLockData[i]) {
            e[0] += 100 - sum;
          }
        }
      }

      arr[i] = e as [number];
    });

    if (!element) {
      setSelectedTickersPercentsArray(arr);
      setCreatedTicker({
        ...createdTicker,
        tickers: createdTicker.tickers.map((item, index) => ({
          ...item,
          percent: arr[index][0],
        })),
      });
    }
  };
  const highLightLocks = (): void => {
    setLocksHighlighted(true);
    setTimeout(() => {
      setLocksHighlighted(false);
    }, 1000);
  };
  return (
    <>
      <div
        style={!isMobileVersion ? { padding: '4.5vh 3vh' } : {}}
        className={styles.tickerSelectorHeader}
      >
        <span>Proportionality setting</span>
        <TickerSelectorCloseIcon className={styles.tickerSelectorHeaderClose} onClick={onClose} />
      </div>
      <div className={styles.tickerSettingsTickersContent}>
        {selectedIndexTicker.map(({ exchange, baseAsset, quoteAsset, since }, key) => {
          const name = parseTicker(exchange, `${baseAsset}${quoteAsset}`);
          return (
            <div key={key} onClick={() => {}} className={styles.tickerSettingsTickersContentItem}>
              <span className={styles.tickerSettingsTickersContentItemName}>
                <img
                  className={styles.tickerSelectorContentImage}
                  src={`${process.env.PUBLIC_URL}/coins/${baseAsset}.png`}
                  alt=""
                />
                <span>
                  {name} | From {new Date(since).toLocaleDateString()}
                </span>
              </span>
              <span className={styles.tickerSettingsTickersContentItemInput}>
                <input
                  key={key + Math.random()}
                  type="number"
                  style={{
                    border: selectedTickersPercentsArray[key][0] === 0 ? '1px solid red' : 'none',
                  }}
                  value={Number(selectedTickersPercentsArray[key][0]).toFixed(
                    selectedTickersPercentsArray[key][0].toString().includes('.') ? 2 : 0,
                  )}
                  step={0.1}
                  onChange={(e) => {
                    const falseIndexesNoTakeIndex = inputsLockData.reduce((acc, value, index) => {
                      if (value && index !== key) {
                        acc.push(index);
                      }
                      return acc;
                    }, []);
                    const sumOfFalseIndexValuesNoTakeIndex = falseIndexesNoTakeIndex.reduce(
                      (acc: number, index) => {
                        if (index >= 0 && index < arr.length) {
                          acc += Number(arr[index][0]);
                        }
                        return acc;
                      },
                      0,
                    );
                    const newMaxValue = 100 - sumOfFalseIndexValuesNoTakeIndex;
                    const firstFalseIndex = inputsLockData.findIndex((value, index) => {
                      return !value && index !== key;
                    });
                    if (newMaxValue > 0) {
                      if (inputsLockData.length !== 1 ? firstFalseIndex !== -1 : true) {
                        changeData(e.target.value, key, 1, newMaxValue);
                      } else {
                        highLightLocks();
                      }
                    }
                  }}
                />
                %
                <img
                  className={styles.tickerSettingsTickersContentItemLockImage}
                  src={
                    locksHighlighted
                      ? RedClosedLock
                      : inputsLockData?.[key]
                      ? ClosedLock
                      : OpenedLock
                  }
                  onClick={() => {
                    const newArr = [...inputsLockData];
                    newArr[key] = !newArr[key];
                    setInputsLockData(newArr);
                  }}
                  alt="S"
                />
              </span>
            </div>
          );
        })}
      </div>
      <div className={styles.tickerSelectorRevertContainer}>
        <button
          className={styles.tickerSelectorRevert}
          onClick={() => {
            const selectedTickerPercent = Number(100 / selectedIndexTicker.length).toFixed(2);
            const array = [];
            for (let i = 0; i < selectedIndexTicker.length; i++) {
              array.push([Number(selectedTickerPercent)]);
            }
            setSelectedTickersPercentsArray(array);
          }}
        >
          Set equally
        </button>
      </div>
      <div className={styles.tickerSelectorFooter}>
        <button
          className={styles.tickerSelectorFooterClear}
          onClick={() => {
            setTickerSelectorState(MODE_CREATING_INDEX_STEP_ONE);
          }}
        >
          Back
        </button>
        <button
          className={styles.tickerSelectorFooterNext}
          onClick={() => {
            if (createdTicker.tickers.every((item) => item.percent !== 0)) {
              setTickerSelectorState(MODE_LIST_OF_INDEX_TICKERS);
              const created = {
                ...createdTicker,
              };
              apiTicker.addCustomTicker(created).then((data) => {
                dispatch(addCreatedTicker(data));
              });
              setSelectedIndexTicker([]);
              setSelectedTickersPercentsArray([]);
            }
          }}
        >
          Create
        </button>
      </div>
    </>
  );
};
