import { type Dispatch, useState, type FC, useEffect } from 'react';
import './BackTestTypeDropDown.scss';
import Arrow from 'assets/images/Strategy/DropDownTriangle.svg';
import { useDispatch } from 'react-redux';
import { setBackTest, setIsFullBackTest } from 'redux/strategy/reducer';
import { useSelector } from 'redux/rootReducer';
import { setIsShowStatisticsData, setShowStatistics } from 'redux/chart/reducer';
interface IProps {
  onChange: Dispatch<React.SetStateAction<boolean>>;
  nameFontWeight?: string;
  largeList?: boolean;
  numbersOnly?: boolean;
}
const backTestTypes = [
  { id: 1, name: 'Full live backtest' },
  { id: 2, name: 'Quick backtest' },
];
const BackTestTypeDropDown: FC<IProps> = ({ nameFontWeight, largeList, numbersOnly, onChange }) => {
  const [isOpened, setOpened] = useState(false);
  const { isFullBackTest, selectedStrategyId } = useSelector((state) => state.strategy);
  const [activeOption, setActiveOption] = useState(backTestTypes[isFullBackTest ? 0 : 1]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isFullBackTest) {
      selectedStrategyId && dispatch(setIsShowStatisticsData(true));
      selectedStrategyId && dispatch(setShowStatistics(true));
    }
  }, [isFullBackTest]);
  return (
    <div
      className={'back-test-dropdown'}
      tabIndex={0}
      onBlur={() => {
        onChange(false);
        setOpened(false);
      }}
    >
      <div
        className={'back-test-dropdown__selected'}
        onClick={() => {
          onChange(!isOpened);
          setOpened(!isOpened);
        }}
      >
        <div style={{ fontWeight: nameFontWeight }} className="back-test-dropdown__selected_name">
          {activeOption.name.charAt(0).toUpperCase() + activeOption.name.slice(1)}
        </div>
        <img
          className={
            'back-test-dropdown__selected_arrow' +
            (isOpened ? ' back-test-dropdown__selected_arrowOpened' : '')
          }
          src={Arrow}
          alt=">"
        />
      </div>
      {isOpened &&
        !largeList &&
        backTestTypes.map((option, index) => {
          const isFirstItem = index === 0;
          const isLastItem = index === backTestTypes.length - 1;

          const itemStyle = {
            borderTopLeftRadius: isFirstItem && '4px',
            borderTopRightRadius: isFirstItem && '4px',
            borderBottomLeftRadius: isLastItem && '4px',
            borderBottomRightRadius: isLastItem && '4px',
            borderTop: isLastItem ? 'none' : '',
          };
          return (
            <div
              style={itemStyle}
              className={
                'back-test-dropdown__item' +
                (numbersOnly ? ' back-test-dropdown__item__numbersOnly' : '')
              }
              key={index}
              onClick={() => {
                setOpened(false);
                onChange(false);
                dispatch(setBackTest(true));
                setActiveOption(option);
                dispatch(setIsFullBackTest(option.id === 1));
              }}
            >
              <div style={{ fontWeight: nameFontWeight }} className="back-test-dropdown__item_name">
                {option.name}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default BackTestTypeDropDown;
