import type { IStatistics } from 'interfaces/IStatistics';

export const defaultStats: IStatistics = {
  balance: 0,
  initial_balance: 0,
  net_profit: [0, 0], // USD, Percent
  trades_count: [0, 0], // Short, Long
  commission_paid: [0, 0], // Short, Long
  profit_percent: [0, 0, 0], // Short, Long, Both
  pnl_trades: [0, 0],
  liquidation_count: [0, 0, 0],
  max_drawdown: [
    [0, 0], // Short
    [0, 0], // Long
    [0, 0], // Both
  ],
  max_balance_drawdown: [0, 0],
  stoploss_in_row: [0, 0, 0],
  avg_trade_profit: [
    [0, 0], // Short
    [0, 0], // Long
    [0, 0], // Both
  ],
  open_pnl: [null, null],
  recovery_factor: 0,
  sharp_factor: 0,
  cv_factor: 0,
  overall_factor: 0,
};
