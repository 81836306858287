import { type FC, useState } from 'react';
import styles from '../TickerSelector.module.css';
import SavedTickerItem from './SavedTickerItem';
import {
  MODE_CREATING_INDEX_STEP_ONE,
  MODE_SEARCHING_TICKET,
  type TickerSelectorPageType,
} from '../types';
import type { IStrategyTickerData } from 'services/api/Strategy/types/StrategyResponse';
import { TickerSelectorCloseIcon } from 'assets/icons/TickerSelectorCloseIcon';

interface IProps {
  setTickerSelectorState: (page: TickerSelectorPageType) => void;
  onClose: () => void;
  createdTickers: IStrategyTickerData[];
}

export const CustomTickersList: FC<IProps> = (props) => {
  const [customIndexesSearch, setCustomIndexesSearch] = useState('');
  return (
    <>
      {' '}
      <div className={styles.tickerSelectorHeader}>
        <span>Сustom Indexes</span>
        <TickerSelectorCloseIcon
          className={styles.tickerSelectorHeaderClose}
          onClick={props.onClose}
        />
      </div>
      <input
        type="text"
        placeholder="Search"
        onChange={(e) => {
          setCustomIndexesSearch(e.target.value.replace(/\s/g, ''));
        }}
      />
      <div className={styles.savedTickerListContent}>
        {props?.createdTickers
          .filter((item) =>
            customIndexesSearch !== '' ? item.name.includes(customIndexesSearch) : true,
          )
          .map((item, key) => {
            return <SavedTickerItem key={key} item={item} onClose={props.onClose} />;
          })}
      </div>
      <div className={styles.tickerSelectorFooter}>
        <button
          className={styles.tickerSelectorFooterClear}
          onClick={() => {
            props.setTickerSelectorState(MODE_SEARCHING_TICKET);
          }}
        >
          Back
        </button>
        <button
          className={styles.tickerSelectorFooterNext}
          onClick={() => {
            props.setTickerSelectorState(MODE_CREATING_INDEX_STEP_ONE);
          }}
        >
          Create new
        </button>
      </div>
    </>
  );
};
