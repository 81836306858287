import React, { type FC, useState, useEffect, useRef } from 'react';
import { TabSwitcher } from '../../../../components/TabSwitcher';
import { FullScreenIcon } from 'assets/icons/FullScreenIcon';
import { DefaultScreenIcon } from 'assets/icons/DefaultScreenIcon';
import { CollapseIcon } from 'assets/icons/CollapseIcon';
import { ChartTabs, ChartTime, HistorySectionModes } from '../../../../enums/History';
import { StrategyTesterExtraTabs } from './components/StrategyTesterExtraTabs';
import { StrategyTesterContent } from './components/StrategyTesterTable';
import { ExpandIcon } from 'assets/icons/ExpandIcon';
import { NonStrategy } from './components/NonStrategy';
import { selectTheme } from '../../../../redux/theme/reducer';
// import { type IStatistics } from '../../../../interfaces/IStatistics';
import { TradesTable } from '../TradesTable';
import { useSelector } from '../../../../redux/rootReducer';
import './History.module.scss';
import { LoaderOverlay } from 'components/LoaderOverlay';
import fullPageIcon from 'assets/images/fullpage.svg';
import resetIconIcon from 'assets/images/reset-icon.svg';

enum HistoryTabs {
  STRATEGY_TESTER = 'strategyTester',
  POSITION = 'position',
  TRADES = 'trades',
  AUTOMATED_STRATEGIES = 'automatedStrategies',
}

interface IProps {
  containerSize: {
    height: number;
    width: number;
  };
  setHistorySectionMode: (mode: HistorySectionModes | null) => void;
  historyMode: HistorySectionModes | null;
  isConstructorOpen: boolean;
}

export const History: FC<IProps> = ({
  setHistorySectionMode,
  historyMode,
  containerSize,
  isConstructorOpen,
}) => {
  const trades = useSelector((state) => state.chart.allTrades);
  const qtyPrecision = useSelector((state) => state.chart.qtyPrecision);
  const pricePrecision = useSelector((state) => state.chart.pricePrecision);
  const stats = useSelector((state) => state.chart.stats);

  const [selectedMainTab, setSelectedMainTab] = useState(HistoryTabs.STRATEGY_TESTER);
  const [selectedCharTab, setSelectedCharTab] = useState(ChartTabs.LINES);
  const [selectedCharTimeTab, setSelectedCharTimeTab] = useState(ChartTime.DAY);
  const [currChartTime, setCurrChartTime] = useState<number>(Date.now());
  const isMobileVersion = window.innerWidth < 768;
  const strategyOn = trades && trades?.length > 0;
  const theme = useSelector(selectTheme);
  const chartLoading = useSelector((state) => state.chart.isLoading);

  const [isTouching, setIsTouching] = useState(false);
  let touchTimeout: NodeJS.Timeout | null = null;

  const [isFullpage, setIsFullpage] = useState(false);

  const fullpage = (): void => {
    setIsFullpage(!isFullpage);
  };

  const handleTouchStart = (): void => {
    if (touchTimeout) {
      clearTimeout(touchTimeout);
    }
    touchTimeout = setTimeout(() => {
      setIsTouching(true);
    }, 2000);
  };

  const handleTouchEnd = (): void => {
    if (touchTimeout) {
      clearTimeout(touchTimeout);
      touchTimeout = null;
    }
    setIsTouching(false);
  };

  const className = isTouching ? 'touched' : '';

  const content = {
    [HistoryTabs.STRATEGY_TESTER]: (
      <StrategyTesterContent
        {...{
          stats,
          containerSize,
          trades,
          chartTime: selectedCharTimeTab,
          currChartTime,
          selectedCharTab,
          isConstructorOpen,
        }}
      />
    ),
    [HistoryTabs.TRADES]: (
      <TradesTable
        trades={trades.slice().reverse()}
        qtyPrecision={qtyPrecision}
        pricePrecision={pricePrecision}
      />
    ),
    [HistoryTabs.AUTOMATED_STRATEGIES]: <h1>AUTOMATED STRATEGIES</h1>,
  };

  const contentWrapperRef = useRef(null);
  const fakeScrollBarRef = useRef(null);

  const syncFakeScrollBar = (): void => {
    const contentWrapper = contentWrapperRef.current;
    const fakeScrollBar = fakeScrollBarRef.current;

    if (contentWrapper && fakeScrollBar) {
      const contentWidth = contentWrapper.scrollWidth;
      const visibleWidth = contentWrapper.clientWidth;
      const scrollBarWidth = (visibleWidth / contentWidth) * visibleWidth;
      fakeScrollBar.style.width = `${scrollBarWidth}px`;

      const scrollPercentage = contentWrapper.scrollLeft / (contentWidth - visibleWidth);
      const scrollBarLeft = scrollPercentage * (visibleWidth - scrollBarWidth);
      fakeScrollBar.style.left = `${scrollBarLeft}px`;
    }
  };

  useEffect(() => {
    const contentWrapper = contentWrapperRef.current;
    contentWrapper?.addEventListener('scroll', syncFakeScrollBar);
    window.addEventListener('resize', syncFakeScrollBar);

    // Initial sync
    syncFakeScrollBar();

    return () => {
      contentWrapper?.removeEventListener('scroll', syncFakeScrollBar);
      window.removeEventListener('resize', syncFakeScrollBar);
    };
  }, [selectedCharTab]);

  const changeChatTap = (mode: ChartTabs): void => {
    setSelectedCharTab(mode);
  };

  const changeCharTimeTab = (mode: ChartTime): void => {
    setSelectedCharTimeTab(mode);
  };
  const changeCharDateTab = (mode: number): void => {
    setCurrChartTime(mode);
  };

  const showFullScreen = (): void => {
    if (historyMode !== HistorySectionModes.FULL_SCREEN) {
      setHistorySectionMode(HistorySectionModes.FULL_SCREEN);
      return;
    }
    setHistorySectionMode(null);
  };

  const collapseHistorySection = (): void => {
    if (historyMode !== HistorySectionModes.COLLAPSE) {
      setHistorySectionMode(HistorySectionModes.COLLAPSE);
      return;
    }
    setHistorySectionMode(null);
  };

  const isStrategyTester = HistoryTabs.STRATEGY_TESTER === selectedMainTab;

  const getTemplateAdditionalTabs = (): JSX.Element => {
    if (isStrategyTester) {
      return (
        <StrategyTesterExtraTabs
          {...{
            selectedCharTab,
            selectedCharTimeTab,
            changeChatTap,
            changeCharTimeTab,
            setCurrChartTime: changeCharDateTab,
            currChartTime,
          }}
        />
      );
    }
    return null;
  };

  const isCollapseSection = HistorySectionModes.COLLAPSE === historyMode;

  const fullScreenBtnIcon =
    HistorySectionModes.FULL_SCREEN === historyMode ? <DefaultScreenIcon /> : <FullScreenIcon />;
  const collapseBtnTextIcon = isCollapseSection ? <ExpandIcon /> : <CollapseIcon />;

  // const isShowNonStrategy = !strategyActiveTab ? isStrategyTester : !strategyOn && isStrategyTester;
  const isShowNonStrategy = !strategyOn;
  if (isMobileVersion) {
    return (
      <div
        className={`historyWrapper ${isFullpage ? 'fullpage-active' : ''}`}
        id="history_container"
        style={{ maxHeight: '50%', height: '50%', minHeight: '50%' }}
      >
        <button className="chart-fp" onClick={fullpage}>
          <img src={isFullpage ? resetIconIcon : fullPageIcon} alt="" />
        </button>
        <LoaderOverlay isShow={chartLoading} position={'absolute'} imageWidth={0} />
        <div className="historyHeadWrapper">
          {!isCollapseSection && <div className="tabsWrapper">{getTemplateAdditionalTabs()}</div>}
          <div className="additionalButtonWrapper">
            <button className="downloadBtn" onClick={collapseHistorySection}>
              {collapseBtnTextIcon}
            </button>
            <button className="fullScreenBtn" onClick={showFullScreen}>
              {fullScreenBtnIcon}
            </button>
          </div>
        </div>

        {!isCollapseSection && (
          <>
            <div
              style={{
                height: selectedMainTab === HistoryTabs.TRADES ? '80%' : '',
              }}
              className={`mainContentWrapper ${className}`}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
              ref={contentWrapperRef}
            >
              <div
                className="historyContainer"
                style={selectedCharTab === ChartTabs.LINES ? { width: '100%' } : {}}
              >
                {content[selectedMainTab] ?? null}
              </div>
              {selectedCharTab === ChartTabs.BARS && (
                <>
                  <div className="fakeScrollBar" ref={fakeScrollBarRef}></div>
                  <div className="fakeScrollBarContainer"></div>
                </>
              )}
            </div>
            {isShowNonStrategy && <NonStrategy />}
          </>
        )}
      </div>
    );
  }
  if (!isMobileVersion) {
    return (
      <div className="historyWrapper" id="history_container" style={{ background: '#130e2d' }}>
        <LoaderOverlay isShow={chartLoading} position={'absolute'} imageWidth={0} />
        <div className="historyHeadWrapper">
          {!isCollapseSection && (
            <div style={{ backgroundColor: theme.historyBackground }} className="tabsWrapper">
              <TabSwitcher
                className="mainTab"
                defaultTab={selectedMainTab}
                onChange={(tabKey: HistoryTabs) => {
                  setSelectedMainTab(tabKey);
                }}
                options={[
                  { tabId: HistoryTabs.STRATEGY_TESTER, tabLabel: 'Backtest diagrams' },
                  { tabId: HistoryTabs.TRADES, tabLabel: 'Trades list' },
                  // { tabId: HistoryTabs.AUTOMATED_STRATEGIES, tabLabel: 'Automated Strategies' },
                ]}
              />
              {getTemplateAdditionalTabs()}
            </div>
          )}
          <div className="additionalButtonWrapper">
            <button className="downloadBtn" onClick={collapseHistorySection}>
              {collapseBtnTextIcon}
            </button>
            <button className="fullScreenBtn" onClick={showFullScreen}>
              {fullScreenBtnIcon}
            </button>
          </div>
        </div>

        {!isCollapseSection && (
          <>
            <div
              style={{ backgroundColor: theme.historyBackground }}
              className="mainContentWrapper"
            >
              {content[selectedMainTab] ?? null}
            </div>
            {isShowNonStrategy && <NonStrategy />}
          </>
        )}
      </div>
    );
  }
};
