import { type FC, useEffect, useRef } from 'react';
import { useSelector } from 'redux/rootReducer';
import './TradesList.scss';
import { TradesTable } from '../TradesTable';
import { NonStrategy } from '../History/components/NonStrategy';

export const TradesList: FC = () => {
  const { allTrades: trades, qtyPrecision, pricePrecision } = useSelector((state) => state.chart);
  const isMobileVersion = window.innerWidth < 768;
  const strategyOn = trades && trades?.length > 0;
  const contentWrapperRef = useRef(null);
  const fakeScrollBarRef = useRef(null);
  const syncFakeScrollBar = (): void => {
    const contentWrapper = contentWrapperRef.current;
    const fakeScrollBar = fakeScrollBarRef.current;

    if (contentWrapper && fakeScrollBar) {
      const contentWidth = contentWrapper.scrollWidth;
      const visibleWidth = contentWrapper.clientWidth;
      const scrollBarWidth = (visibleWidth / contentWidth) * visibleWidth;
      fakeScrollBar.style.width = `${scrollBarWidth}px`;

      const scrollPercentage = contentWrapper.scrollLeft / (contentWidth - visibleWidth);
      const scrollBarLeft = scrollPercentage * (visibleWidth - scrollBarWidth);
      fakeScrollBar.style.left = `${scrollBarLeft}px`;
    }
  };

  useEffect(() => {
    const contentWrapper = contentWrapperRef.current;
    contentWrapper?.addEventListener('scroll', syncFakeScrollBar);
    window.addEventListener('resize', syncFakeScrollBar);

    // Initial sync
    syncFakeScrollBar();

    return () => {
      contentWrapper?.removeEventListener('scroll', syncFakeScrollBar);
      window.removeEventListener('resize', syncFakeScrollBar);
    };
  }, []);
  const isShowNonStrategy = !strategyOn;
  if (isMobileVersion) {
    return (
      <div className="historyWrapper" id="history_container">
        {
          <>
            <div
              className="mainContentWrapper"
              style={{ height: 'calc(100% - 15px)' }}
              ref={contentWrapperRef}
            >
              <div className="historyContainer">
                {(
                  <TradesTable
                    trades={trades.slice().reverse()}
                    qtyPrecision={qtyPrecision}
                    pricePrecision={pricePrecision}
                  />
                ) ?? null}
              </div>
              <div className="fakeScrollBar" ref={fakeScrollBarRef}></div>
              <div className="fakeScrollBarContainer"></div>
            </div>
            {isShowNonStrategy && <NonStrategy />}
          </>
        }
      </div>
    );
  }
};
