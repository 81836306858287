import { type FC, useEffect } from 'react';
import './BackTestTypeSwitcher.scss';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/rootReducer';
import { setIsShowStatisticsData, setShowStatistics } from 'redux/chart/reducer';
import { setIsFullBackTest } from 'redux/strategy/reducer';

const BackTestTypeSwitcher: FC = () => {
  const { isFullBackTest, selectedStrategyId } = useSelector((state) => state.strategy);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isFullBackTest) {
      selectedStrategyId && dispatch(setIsShowStatisticsData(true));
      selectedStrategyId && dispatch(setShowStatistics(true));
    }
  }, [isFullBackTest]);
  if (window.location.href.includes('strategy')) {
    return null;
  }
  return (
    <div className={'back-test-switcher'}>
      <div
        className={`back-test-switcher_item  ${
          isFullBackTest ? 'back-test-switcher_item_selected' : ''
        }`}
        onClick={() => {
          dispatch(setIsFullBackTest(true));
        }}
      >
        Full live backtest
      </div>
      <div
        className={`back-test-switcher_item  ${
          !isFullBackTest ? 'back-test-switcher_item_selected' : ''
        }`}
        onClick={() => {
          dispatch(setIsFullBackTest(false));
        }}
      >
        Quick backtest
      </div>
    </div>
  );
};

export default BackTestTypeSwitcher;
