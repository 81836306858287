import { type FC } from 'react';
import HomePage from '.';
import { useBackTestActivator } from './customHooks/useBackTestActivator';

const HomePageFuctionalWrapper: FC = () => {
  useBackTestActivator();
  return <HomePage />;
};

export default HomePageFuctionalWrapper;
