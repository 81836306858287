import { useDispatch } from 'react-redux';
import GTumbler from 'components/GTumbler/GTumbler';
import { useSelector } from 'redux/rootReducer';
import {
  refreshCrosshairSettings,
  updateCrosshairProperty,
} from 'redux/chartCrosshairSettings/reducer';
import { CustomColorPicker } from '../../ColorPicker/ColorPicker';
import CustomDropDown from '../../DropDownComponents/CustomMultiLineDropDown/CustomStyleSettingsDropDown';
import {
  fontWeightTypes,
  lineStyleTypes,
  numberSizes,
  textStyleTypes,
} from '../../../Data/dropdownData';

import { type CrosshairStyle } from '@basttyy/klinecharts';

import useWindowWidth from 'hooks/useWindowWidth';
import { apiChartStyles } from 'services/api/ApiChartStyles/ApiChartStyles';

export const CrosshairSettings = (): JSX.Element => {
  const dispatch = useDispatch();
  const settings: CrosshairStyle = useSelector((state) => state.chartCrosshairSettings);
  const isMobileVersion = useWindowWidth() < 768;
  return (
    <div className="propertyMainContainer">
      <div className="container__content_main_content_header">
        CROSSHAIR SETTINGS
        <div className="propertyContainer__candleTypeDropDown"></div>
      </div>
      <div className="propertyContainer">
        Show Crosshair
        <GTumbler
          checked={settings.show}
          size={isMobileVersion ? 'large' : null}
          name="CROSSHAIR"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'crosshair.show',
              value,
            });
            dispatch(
              updateCrosshairProperty({
                propertyNode1: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="container__content_main_content_bigHeader">HORIZONTAL LINE SIZE</div>
      <div className="propertyContainer">
        Show horizontal
        <GTumbler
          checked={settings.horizontal.show}
          size={isMobileVersion ? 'large' : null}
          name="crosshair"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'crosshair.horizontal.show',
              value,
            });
            dispatch(
              updateCrosshairProperty({
                propertyNode1: 'horizontal',
                propertyNode2: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Show horizontal line
        <GTumbler
          checked={settings.horizontal.line.show}
          size={isMobileVersion ? 'large' : null}
          name="crosshair"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'crosshair.horizontal.line.show',
              value,
            });
            dispatch(
              updateCrosshairProperty({
                propertyNode1: 'horizontal',
                propertyNode2: 'line',
                propertyNode3: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Horizontal line style
        <div className="propertyContainer__dropDown">
          <CustomDropDown
            largeList
            options={lineStyleTypes}
            activeOption={{
              id: Number(
                lineStyleTypes.findIndex((item) => item.name === settings.horizontal.line.style),
              ),
              name: settings.horizontal.line.style.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'crosshair.horizontal.line.style',
                value: lineStyleTypes[value - 1].name,
              });
              dispatch(
                updateCrosshairProperty({
                  propertyNode1: 'horizontal',
                  propertyNode2: 'line',
                  propertyNode3: 'style',
                  value: lineStyleTypes[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Horizontal line size
        <div className="propertyContainer__numberDropdown">
          <CustomDropDown
            largeList
            options={numberSizes}
            activeOption={{
              id: Number(settings.horizontal.line.size),
              name: settings.horizontal.line.size.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'crosshair.horizontal.line.size',
                value,
              });
              dispatch(
                updateCrosshairProperty({
                  propertyNode1: 'horizontal',
                  propertyNode2: 'line',
                  propertyNode3: 'size',
                  value,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Horizontal line color
        <CustomColorPicker
          defaultColor={settings.horizontal.line.color}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'crosshair.horizontal.line.color',
              value: color,
            });
            dispatch(
              updateCrosshairProperty({
                propertyNode1: 'horizontal',
                propertyNode2: 'line',
                propertyNode3: 'color',
                value: color,
              }),
            );
          }}
        />
      </div>

      <div className="container__content_main_content_header">
        HORIZONTAL TEXT SETTINGS
        <div className="propertyContainer__candleTypeDropDown"></div>
      </div>
      <div className="propertyContainer">
        Show horizontal text
        <GTumbler
          checked={settings.horizontal.text.show}
          size={isMobileVersion ? 'large' : null}
          name="crosshair"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'crosshair.horizontal.text.show',
              value,
            });
            dispatch(
              updateCrosshairProperty({
                propertyNode1: 'horizontal',
                propertyNode2: 'text',
                propertyNode3: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Horizontal text style
        <div className="propertyContainer__dropDown">
          <CustomDropDown
            largeList
            options={textStyleTypes}
            activeOption={{
              id: Number(
                textStyleTypes.findIndex((item) => item.name === settings.horizontal.text.style),
              ),
              name: settings.horizontal.text.style.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'crosshair.horizontal.text.style',
                value: textStyleTypes[value - 1].name,
              });
              dispatch(
                updateCrosshairProperty({
                  propertyNode1: 'horizontal',
                  propertyNode2: 'text',
                  propertyNode3: 'style',
                  value: textStyleTypes[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Horizontal text size
        <div className="propertyContainer__numberDropdown">
          <CustomDropDown
            largeList
            options={numberSizes}
            activeOption={{
              id: Number(settings.horizontal.text.size),
              name: settings.horizontal.text.size.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'crosshair.horizontal.text.size',
                value,
              });
              dispatch(
                updateCrosshairProperty({
                  propertyNode1: 'horizontal',
                  propertyNode2: 'text',
                  propertyNode3: 'size',
                  value,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Horizontal text weight
        <div className="propertyContainer__dropDown">
          <CustomDropDown
            largeList
            options={fontWeightTypes}
            activeOption={{
              id: Number(
                fontWeightTypes.findIndex((item) => item.name === settings.horizontal.text.weight),
              ),
              name: settings.horizontal.text.weight.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'crosshair.horizontal.text.weight',
                value: fontWeightTypes[value - 1].name,
              });
              dispatch(
                updateCrosshairProperty({
                  propertyNode1: 'horizontal',
                  propertyNode2: 'text',
                  propertyNode3: 'weight',
                  value: fontWeightTypes[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Horizontal text color
        <CustomColorPicker
          defaultColor={settings.horizontal.text.color}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'crosshair.horizontal.text.color',
              value: color,
            });
            dispatch(
              updateCrosshairProperty({
                propertyNode1: 'horizontal',
                propertyNode2: 'text',
                propertyNode3: 'color',
                value: color,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Horizontal text background color
        <CustomColorPicker
          defaultColor={String(settings.horizontal.text.backgroundColor)}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'crosshair.horizontal.text.backgroundColor',
              value: color,
            });
            dispatch(
              updateCrosshairProperty({
                propertyNode1: 'horizontal',
                propertyNode2: 'text',
                propertyNode3: 'backgroundColor',
                value: color,
              }),
            );
          }}
        />
      </div>
      <div className="container__content_main_content_header">
        VERTICAL LINE SETTINGS
        <div className="propertyContainer__candleTypeDropDown"></div>
      </div>
      <div className="propertyContainer">
        Show vertical
        <GTumbler
          checked={settings.vertical.show}
          size={isMobileVersion ? 'large' : null}
          name="crosshair"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'crosshair.vertical.show',
              value,
            });
            dispatch(
              updateCrosshairProperty({
                propertyNode1: 'vertical',
                propertyNode2: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Show vertical line
        <GTumbler
          checked={settings.vertical.line.show}
          size={isMobileVersion ? 'large' : null}
          name="crosshair"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'crosshair.vertical.line.show',
              value,
            });
            dispatch(
              updateCrosshairProperty({
                propertyNode1: 'vertical',
                propertyNode2: 'line',
                propertyNode3: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Vertical line style
        <div className="propertyContainer__dropDown">
          <CustomDropDown
            largeList
            options={lineStyleTypes}
            activeOption={{
              id: Number(
                lineStyleTypes.findIndex((item) => item.name === settings.vertical.line.style),
              ),
              name: settings.vertical.line.style.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'crosshair.vertical.line.style',
                value: lineStyleTypes[value - 1].name,
              });
              dispatch(
                updateCrosshairProperty({
                  propertyNode1: 'vertical',
                  propertyNode2: 'line',
                  propertyNode3: 'style',
                  value: lineStyleTypes[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Vertical line size
        <div className="propertyContainer__numberDropdown">
          <CustomDropDown
            largeList
            options={numberSizes}
            activeOption={{
              id: Number(settings.vertical.line.size),
              name: settings.vertical.line.size.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'crosshair.vertical.line.size',
                value: value.toString(),
              });
              dispatch(
                updateCrosshairProperty({
                  propertyNode1: 'vertical',
                  propertyNode2: 'line',
                  propertyNode3: 'size',
                  value,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Vertical line color
        <CustomColorPicker
          defaultColor={settings.vertical.line.color}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'crosshair.vertical.line.color',
              value: color,
            });
            dispatch(
              updateCrosshairProperty({
                propertyNode1: 'vertical',
                propertyNode2: 'line',
                propertyNode3: 'color',
                value: color,
              }),
            );
          }}
        />
      </div>
      <div className="container__content_main_content_header">
        VERTICAL TEXT SETTINGS
        <div className="propertyContainer__candleTypeDropDown"></div>
      </div>
      <div className="propertyContainer">
        Show vertical text
        <GTumbler
          checked={settings.vertical.text.show}
          size={isMobileVersion ? 'large' : null}
          name="crosshair"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'crosshair.vertical.text.show',
              value,
            });
            dispatch(
              updateCrosshairProperty({
                propertyNode1: 'vertical',
                propertyNode2: 'text',
                propertyNode3: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Vertical text style
        <div className="propertyContainer__dropDown">
          <CustomDropDown
            largeList
            options={textStyleTypes}
            activeOption={{
              id: Number(
                textStyleTypes.findIndex((item) => item.name === settings.vertical.text.style),
              ),
              name: settings.vertical.text.style.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'crosshair.vertical.text.style',
                value: textStyleTypes[value - 1].name,
              });
              dispatch(
                updateCrosshairProperty({
                  propertyNode1: 'vertical',
                  propertyNode2: 'text',
                  propertyNode3: 'style',
                  value: textStyleTypes[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Vertical text size
        <div className="propertyContainer__numberDropdown">
          <CustomDropDown
            largeList
            options={numberSizes}
            activeOption={{
              id: Number(settings.vertical.text.size),
              name: settings.vertical.text.size.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'crosshair.vertical.text.size',
                value: value.toString(),
              });
              dispatch(
                updateCrosshairProperty({
                  propertyNode1: 'vertical',
                  propertyNode2: 'text',
                  propertyNode3: 'size',
                  value,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Vertical text weight
        <div className="propertyContainer__dropDown">
          <CustomDropDown
            largeList
            options={fontWeightTypes}
            activeOption={{
              id: Number(
                fontWeightTypes.findIndex((item) => item.name === settings.vertical.text.weight),
              ),
              name: settings.vertical.text.weight.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'crosshair.vertical.text.weight',
                value: fontWeightTypes[value - 1].name,
              });
              dispatch(
                updateCrosshairProperty({
                  propertyNode1: 'vertical',
                  propertyNode2: 'text',
                  propertyNode3: 'weight',
                  value: fontWeightTypes[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Vertical text color
        <CustomColorPicker
          defaultColor={settings.vertical.text.color}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'crosshair.vertical.text.color',
              value: color,
            });
            dispatch(
              updateCrosshairProperty({
                propertyNode1: 'vertical',
                propertyNode2: 'text',
                propertyNode3: 'color',
                value: color,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Vertical text background color
        <CustomColorPicker
          defaultColor={String(settings.vertical.text.backgroundColor)}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'crosshair.vertical.text.backgroundColor',
              value: color,
            });
            dispatch(
              updateCrosshairProperty({
                propertyNode1: 'vertical',
                propertyNode2: 'text',
                propertyNode3: 'backgroundColor',
                value: color,
              }),
            );
          }}
        />
      </div>
      <button
        className="container__content_main_content_refresh"
        onClick={() => {
          apiChartStyles.refreshChartStylingSettings('crosshair');
          dispatch(refreshCrosshairSettings());
        }}
      >
        Refresh settings
      </button>
    </div>
  );
};
