import styles from './ChartInfoSelector.module.css';
import { type FC, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/rootReducer';
import { setChartTimeframe, useChartSelector } from 'redux/chart/reducer';
import { TickerSelector } from '../TickerSelector';
import PositionSwitcher from '../../pages/HomePage/components/Chart/components/PositionSwitcher';
import { parseTicker } from 'utils/parseTicker';
import { COLORS, GET_TIMEFRAME_NAME } from 'configs/chart';
import { TimeframeSelector } from '../TimeframeSelector';
import CustomIndex from 'assets/images/CustomIndexIcon.svg';

export const ChartInfoSelector: FC = () => {
  const { selectedChart: id, charts, isPreview } = useSelector((state) => state.chart);
  const { selectedMergedStrategy, selectedStrategy, isFullBackTest } = useSelector(
    (state) => state.strategy,
  );
  const { ticker, exchange, chartType, timeframe } = useChartSelector(id);
  const [isVisibleTickerSelector, setVisibleTickerSelector] = useState(false);
  const isCustomTicker = ticker.type === 'custom';
  const dispatch = useDispatch();
  const onCloseHandler = useCallback(() => {
    setVisibleTickerSelector(false);
  }, [setVisibleTickerSelector]);
  const disabledSelection = (): boolean =>
    isPreview || (selectedMergedStrategy && !selectedStrategy);

  return (
    <div className={styles.chartInfoSelector}>
      <div
        className={styles.chartInfoSelectorTicker}
        style={{ cursor: disabledSelection() ? 'not-allowed' : 'pointer' }}
        onClick={() => {
          !disabledSelection() && setVisibleTickerSelector(true);
        }}
      >
        {isCustomTicker && <img src={CustomIndex} alt="" />}
        {!isCustomTicker && (
          <img src={`${process.env.PUBLIC_URL}/coins/${ticker.baseAsset}.png`} alt="" />
        )}
        {isCustomTicker && <span>{ticker.name}</span>}
        {!isCustomTicker && (
          <span>{parseTicker(exchange, ticker.baseAsset + ticker.quoteAsset)}</span>
        )}
      </div>
      {chartType && (
        <div
          className={styles.chartType}
          style={{ color: chartType === 'LONG' ? COLORS.GREEN : COLORS.RED }}
        >
          {' '}
          {chartType} :
        </div>
      )}
      <TimeframeSelector
        selectedTimeframe={GET_TIMEFRAME_NAME(timeframe)}
        onChange={(data) => {
          dispatch(setChartTimeframe({ id, data }));
        }}
      />

      {isFullBackTest && charts.length > 1 && <PositionSwitcher />}

      {isVisibleTickerSelector && <TickerSelector onClose={onCloseHandler} />}
    </div>
  );
};
