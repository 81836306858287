import { type FC } from 'react';

import './NonStrategy.scss';
export const NonStrategy: FC = () => {
  return (
    <div className="non-strategy" onClick={() => {}}>
      <h2 className="non-strategy__title">No data</h2>
    </div>
  );
};
