import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type IStrategyTickerData } from 'services/api/Strategy/types/StrategyResponse';
import { apiTicker } from 'services/api/Ticker/ApiTicker';
import { type IRootState } from '../rootReducer';

interface InitialState {
  createdTickers: IStrategyTickerData[];
  tickerIcons: { baseAsset: string; exchange: number; quoteAsset: string; since: number }[];
}

const initialState: InitialState = {
  createdTickers: [],
  tickerIcons: [],
};

export const fetchCustomTickers = createAsyncThunk<
  IStrategyTickerData[],
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void, // Define the type of the payload
  { rejectValue: string } // Define the type of the thunkApiConfig
>('customTickers/fetchCustomTickers', () => {
  // No arguments, so use () => ReturnType
  return apiTicker.getCustomTickers();
});

const customTickersReducer = createSlice({
  name: 'chartTickers',
  initialState,
  reducers: {
    addCreatedTicker: (state, action: PayloadAction<IStrategyTickerData>) => {
      state.createdTickers.push(action.payload);
    },
    updateTicker: (state, action: PayloadAction<{ _id: string; ticker: IStrategyTickerData }>) => {
      const { _id, ticker } = action.payload;
      const tickerIndex = state.createdTickers.findIndex((ticker) => ticker._id === _id);
      if (tickerIndex >= 0) {
        state.createdTickers[tickerIndex] = { ...state.createdTickers[tickerIndex], ...ticker };
      }
    },
    setQualPercentsTicker: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      const tickerIndex = state.createdTickers.findIndex((ticker) => ticker._id === id);

      if (tickerIndex >= 0) {
        const tickers = state.createdTickers[tickerIndex].tickers;
        const tickerPercent = 100 / tickers.length;

        state.createdTickers[tickerIndex] = {
          ...state.createdTickers[tickerIndex],
          tickers: tickers.map((ticker) => ({
            ...ticker,
            percent: tickerPercent,
          })),
        };
      }
    },
    deleteTicker: (state, action: PayloadAction<string>) => {
      const tickerIndex = state.createdTickers.findIndex((ticker) => ticker._id === action.payload);
      if (tickerIndex >= 0) {
        state.createdTickers.splice(tickerIndex, 1);
      }
    },
    setTickerIcons: (
      state,
      action: PayloadAction<
        { baseAsset: string; exchange: number; quoteAsset: string; since: number }[]
      >,
    ) => {
      state.tickerIcons = action.payload;
    },
  },
  extraReducers: {
    [fetchCustomTickers.fulfilled.toString()]: (state, action) => {
      state.createdTickers = action.payload;
    },
  },
});

export const {
  addCreatedTicker,
  updateTicker,
  setQualPercentsTicker,
  deleteTicker,
  setTickerIcons,
} = customTickersReducer.actions;

export const selectCustomTickers = (state: IRootState): IStrategyTickerData[] => {
  return state.customTickers.createdTickers;
};

export default customTickersReducer.reducer;
