import { type FC, useEffect, useState } from 'react';
import { parseTicker } from 'utils/parseTicker';
import {
  type ICustomTickerCreationProps,
  MODE_SEARCHING_TICKET,
  MODE_SETTING_INDEX_STEP_TWO,
} from '../types';
import { type IDefaultTicker } from 'services/api/Candles/types';
import styles from '../TickerSelector.module.css';
import { TickerSelectorCloseIcon } from 'assets/icons/TickerSelectorCloseIcon';
import NavigationArrow from 'assets/images/NavigationArrow.svg';
import SelectedCircle from 'assets/images/SelectedCircle.svg';
import NotSelectedCircle from 'assets/images/NotSelectedCircle.svg';

export const CustomTickerCreationOne: FC<ICustomTickerCreationProps> = ({
  onClose,
  setTickerSelectorState,
  tickers,
  setSelectedIndexTicker,
  setSelectedTickersPercentsArray,
  selectedIndexTicker,
  setCreatedTicker,
  createdTicker,
  setHeaderName,
  headerName,
}) => {
  const [search, setSearch] = useState('');
  const isNextAllowed = selectedIndexTicker.length > 1 && createdTicker.name.trim() !== '';

  const selectTicker = ({ baseAsset, quoteAsset, since, exchange }: IDefaultTicker): void => {
    const exists = selectedIndexTicker.some(
      (item) =>
        item.since === since &&
        item.baseAsset === baseAsset &&
        item.quoteAsset === quoteAsset &&
        item.exchange === exchange,
    );

    if (!exists) {
      if (selectedIndexTicker.length < 10) {
        setSelectedIndexTicker([
          ...selectedIndexTicker,
          { baseAsset, quoteAsset, exchange, since },
        ]);
      }
    } else {
      setSelectedIndexTicker((prevState) =>
        prevState.filter(
          (item) =>
            !(
              item.since === since &&
              item.baseAsset === baseAsset &&
              item.quoteAsset === quoteAsset &&
              item.exchange === exchange
            ),
        ),
      );
    }
  };

  useEffect(() => {
    const selectedTickerPercent = Number(100 / selectedIndexTicker.length).toFixed(2);
    const array = [];
    for (let i = 0; i < selectedIndexTicker.length; i++) {
      array.push([Number(selectedTickerPercent)]);
    }
    setSelectedTickersPercentsArray(array);
    let newIndex = {
      name: headerName,
      tickers: [],
    };
    selectedIndexTicker.forEach((e) => {
      newIndex = {
        ...newIndex,
        tickers: [
          ...newIndex.tickers,
          {
            exchange: e.exchange,
            baseAsset: e.baseAsset,
            quoteAsset: e.quoteAsset,
            percent: Number(selectedTickerPercent),
            since: e.since,
          },
        ],
      };
    });
    setCreatedTicker(newIndex);
  }, [selectedIndexTicker, headerName]);

  return (
    <>
      <div className={styles.tickerSelectorHeader}>
        <span>Create a new custom index</span>
        <TickerSelectorCloseIcon className={styles.tickerSelectorHeaderClose} onClick={onClose} />
      </div>

      <div
        onClick={() => {
          setTickerSelectorState(MODE_SEARCHING_TICKET);
          setSelectedIndexTicker([]);
        }}
        className={styles.tickerSelectorCreatingHeader}
      >
        <img src={NavigationArrow} alt="navigationArrow" />
        <div>Back</div>
      </div>
      <input
        type="text"
        placeholder="Name of the custom index"
        style={{
          border: createdTicker.name.trim() === '' ? '1px solid red' : 'none',
        }}
        onChange={(e) => {
          setHeaderName(e.target.value);
        }}
        defaultValue={createdTicker.name}
      />
      <div className={styles.tickerSelectorCreatingSubHeader}>Selected tickers:</div>
      <input
        type="text"
        placeholder="Search"
        onChange={(e) => {
          setSearch(e.target.value.replace(/\s/g, ''));
        }}
      />
      {selectedIndexTicker.length !== 0 && (
        <>
          <div
            style={{
              minHeight: `${
                selectedIndexTicker.length > 3 ? 17 : selectedIndexTicker.length * 6 + 0.5
              }vh`,
            }}
            className={styles.tickerSelectedTickersContent}
          >
            {selectedIndexTicker.map(({ exchange, baseAsset, quoteAsset, since }, key) => {
              const name = parseTicker(exchange, `${baseAsset}${quoteAsset}`);
              return (
                <div
                  key={key}
                  onClick={() => {
                    // Check if the element already exists in selectedIndexTicker
                    selectTicker({ exchange, baseAsset, quoteAsset, since });
                  }}
                >
                  <span>
                    <img
                      className={styles.tickerSelectorContentImage}
                      src={`${process.env.PUBLIC_URL}/coins/${baseAsset}.png`}
                      alt=""
                    />
                    <span>
                      {name} | From {new Date(since).toLocaleDateString()}
                    </span>
                  </span>
                  {selectedIndexTicker.some(
                    (item) =>
                      item.since === since &&
                      item.baseAsset === baseAsset &&
                      item.quoteAsset === quoteAsset &&
                      item.exchange === exchange,
                  ) ? (
                    <span>
                      {' '}
                      {key + 1}{' '}
                      <img
                        className={styles.tickerSelectionIndicatorImage}
                        style={{ width: '28px', height: '28px' }}
                        src={SelectedCircle}
                        alt="notSelected"
                      />
                    </span>
                  ) : (
                    <img style={{ margin: '4.5px' }} src={NotSelectedCircle} alt="notSelected" />
                  )}
                </div>
              );
            })}
          </div>
          <div className={styles.tickerSelectedTickersSubHeader}>All</div>
        </>
      )}
      <div className={`${styles.tickerSelectorContent} `}>
        {tickers.map(({ exchange, baseAsset, quoteAsset, since }, key) => {
          const name = parseTicker(exchange, `${baseAsset}${quoteAsset}`);
          const isSelected = selectedIndexTicker.some(
            (item) =>
              item.since === since &&
              item.baseAsset === baseAsset &&
              item.quoteAsset === quoteAsset &&
              item.exchange === exchange,
          );

          return search === '' || name.includes(search.toUpperCase()) ? (
            <div
              key={key}
              style={
                isSelected
                  ? { backgroundColor: '#382b5e', borderLeft: '2px solid rgba(151, 71, 255, 1)  ' }
                  : {}
              }
              onClick={() => {
                // Check if the element already exists in selectedIndexTicker
                selectTicker({ exchange, baseAsset, quoteAsset, since });
              }}
            >
              <span>
                <img
                  className={styles.tickerSelectorContentImage}
                  src={`${process.env.PUBLIC_URL}/coins/${baseAsset}.png`}
                  alt=""
                />
                <span>
                  {name} | From {new Date(since).toLocaleDateString()}
                </span>
              </span>
              {isSelected ? (
                <span>
                  {selectedIndexTicker.findIndex(
                    (item) =>
                      item.since === since &&
                      item.baseAsset === baseAsset &&
                      item.quoteAsset === quoteAsset &&
                      item.exchange === exchange,
                  ) + 1}
                  <img
                    className={styles.tickerSelectionIndicatorImage}
                    style={{ width: '28px', height: '28px' }}
                    src={SelectedCircle}
                    alt="notSelected"
                  />
                </span>
              ) : (
                <img style={{ margin: '4.5px' }} src={NotSelectedCircle} alt="notSelected" />
              )}
            </div>
          ) : null;
        })}
      </div>

      <div className={styles.tickerSelectorFooter}>
        <button
          className={styles.tickerSelectorFooterClear}
          onClick={() => {
            setSelectedIndexTicker([]);
          }}
        >
          Clear
        </button>
        <button
          className={styles.tickerSelectorFooterNext}
          style={!isNextAllowed ? { cursor: 'not-allowed', opacity: '0.5' } : { cursor: 'pointer' }}
          onClick={() => {
            if (isNextAllowed) {
              setTickerSelectorState(MODE_SETTING_INDEX_STEP_TWO);
            }
          }}
        >
          Next
        </button>
      </div>
    </>
  );
};
