import type { ITradeData } from 'interfaces/ITrade';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getNewChartData,
  setAllTrades,
  setChartLoading,
  setCharts,
  setPricePrecision,
  setQtyPrecision,
  setStats,
  updateChart,
  useChartSelector,
} from 'redux/chart/reducer';
import { fetchCustomTickers } from 'redux/customTicker/reducer';
import { useSelector } from 'redux/rootReducer';
import {
  saveSelectedStrategy,
  setBackTest,
  setMergedStrategyData,
  setStrategies,
} from 'redux/strategy/reducer';
import {
  apiBacktest,
  //   type IHedgeBackTestResponse,
  type IMergedBackTestResponse,
  type IOneWayBackTestResponse,
} from 'services/api/Backtest/ApiBacktest';
import { apiStrategy } from 'services/api/Strategy/ApiStrategy';
import { type IStrategyAllResponse } from 'services/api/Strategy/types/StrategyResponse';
import { getStrategy } from 'services/utils/getStrategy';
import { webSocketService } from 'services/WebSocketService';
import {
  areStrategiesArraysEqual,
  compareStrategyHeaderData,
  isOneWayModeStrategy,
  useIsSavedEqualToSelected,
} from 'utils/strategy';
import { defaultStats } from '../constants/statistics';

export const useBackTestActivator = (): void => {
  const { isBackTest } = useSelector((state) => state.strategy);
  const {
    charts,
    dateConfig: reduxDateConfig,
    selectedChart: reduxSelectedChart,
  } = useSelector((state) => state.chart);
  const { selectedChart: id } = useSelector((state) => state.chart);
  const reducerStrategy = useSelector((state) => state.strategy);
  const selectedChart = useChartSelector(reduxSelectedChart);
  const strategy = getStrategy(reducerStrategy.selectedStrategy);
  const dispatch = useDispatch();
  const backTest = (): void => {
    const mergedStrategy = reducerStrategy.selectedMergedStrategy;
    const backTestId = !strategy && mergedStrategy ? mergedStrategy._id : strategy?._id ?? null;
    const dateConfig = { ...reduxDateConfig };
    const sameListOfStrategies = areStrategiesArraysEqual(
      mergedStrategy?.strategies,
      reducerStrategy?.mergedStrategyData?.strategies,
    );
    if (!strategy && !mergedStrategy) {
      dispatch(setBackTest(false));
      dispatch(setStats(defaultStats));
      return;
    }
    if (
      mergedStrategy?._id &&
      reducerStrategy?.mergedStrategyData?._id &&
      mergedStrategy?._id === reducerStrategy?.mergedStrategyData?._id &&
      sameListOfStrategies &&
      !strategy
    ) {
      dispatch(setStats(reducerStrategy?.mergedStrategyData?.stats));
      dispatch(setBackTest(false));
      const trades = reducerStrategy?.mergedStrategyData.backtestData
        .reduce<ITradeData[]>(
          (a, b) => [
            ...a,
            ...(Array.isArray(b.trades[0])
              ? [...b.trades[0], ...(b.trades[1] as ITradeData[])]
              : (b.trades as ITradeData[])
            ).map((e) => ({ ...e, ticker: b.ticker })),
          ],
          [],
        )
        .sort((a, b) => a.id - b.id);
      dispatch(setAllTrades(trades));
      return;
    }
    const savedStrategy = reducerStrategy.savedStrategies?.find(
      (strategy) => strategy._id === reducerStrategy?.selectedStrategy?._id,
    );
    const isSavedEqualToSelected = useIsSavedEqualToSelected(
      reducerStrategy.selectedStrategy,
      savedStrategy,
    );
    if (
      strategy &&
      reducerStrategy.savedStrategies.some(
        (strategy) => strategy._id === reducerStrategy?.selectedStrategy?._id,
      ) &&
      !compareStrategyHeaderData(savedStrategy, selectedChart, reduxSelectedChart) &&
      isSavedEqualToSelected
    ) {
      dispatch(setStats(savedStrategy.stats));
      if (isOneWayModeStrategy(savedStrategy)) {
        dispatch(
          updateChart({
            id: '0',
            data: {
              trades: savedStrategy.trades || [],
            },
          }),
        );
      } else {
        dispatch(
          updateChart({
            id: '0',
            data: {
              trades: savedStrategy?.shortTrades || [],
            },
          }),
        );
        dispatch(
          updateChart({
            id: '1',
            data: {
              trades: savedStrategy?.longTrades || [],
            },
          }),
        );
      }
      dispatch(setBackTest(false));
      dispatch(setChartLoading(false));
      return;
    }
    dispatch(setChartLoading(true));
    if (!strategy && mergedStrategy) {
      const updatedMergeStrategy = { ...mergedStrategy, strategies: [] };
      updatedMergeStrategy.strategies = [];
      delete updatedMergeStrategy.__v;
      delete updatedMergeStrategy.is_favourite;
      delete updatedMergeStrategy.user_id;
      const promises = mergedStrategy.strategies.map((strategyId) => {
        return apiStrategy.getStrategy(strategyId);
      });
      Promise.all(promises)
        .then((strategies) => {
          strategies.forEach((strategy) => {
            updatedMergeStrategy.strategies.push(getStrategy(strategy));
          });
          if (window.location.href.includes('strategy')) {
            const allStrategies: IStrategyAllResponse = [...updatedMergeStrategy.strategies].map(
              (e) => {
                return {
                  name: e.name,
                  _id: e._id,
                  type: e.type,
                  ticker: e.ticker ? e.ticker : null,
                };
              },
            );
            dispatch(setStrategies(allStrategies));
          }
        })
        .then(() => {
          apiBacktest
            .backtest({
              chart: selectedChart.isDynamicBackTest
                ? {
                    date_from: selectedChart.candles[reducerStrategy.visibleRange.from]?.time,
                    date_to: selectedChart.candles[reducerStrategy.visibleRange.to - 1]?.time,
                  }
                : {
                    date_from: dateConfig.dateFrom,
                    date_to: dateConfig.dateTo,
                  },
              strategy: updatedMergeStrategy,
            })
            .then((_result) => {
              const result = _result as IMergedBackTestResponse;
              const trades = result.strategies
                .reduce<ITradeData[]>(
                  (a, b) => [
                    ...a,
                    ...(Array.isArray(b.trades[0])
                      ? [...b.trades[0], ...(b.trades[1] as ITradeData[])]
                      : (b.trades as ITradeData[])
                    ).map((e) => ({ ...e, ticker: b.ticker })),
                  ],
                  [],
                )
                .sort((a, b) => a.id - b.id);
              console.log('trade222s', trades);
              dispatch(setAllTrades(trades));
              dispatch(setBackTest(false));
              dispatch(setChartLoading(false));
              if (!result) {
                dispatch(setStats(defaultStats));
                // heres
                charts.forEach((e) => {
                  dispatch(updateChart({ id: e.id, data: { trades: [] } }));
                });
                return;
              }
              charts.forEach((e) => {
                dispatch(updateChart({ id: e.id, data: { trades: [] } }));
              });
              result.stats.strategy_id = mergedStrategy._id;
              if (backTestId === mergedStrategy._id) {
                dispatch(
                  setMergedStrategyData({
                    ...mergedStrategy,
                    backtestData: result.strategies,
                    stats: result.stats,
                    chartConfig: dateConfig,
                  }),
                );
                dispatch(setStats(result.stats));
              }
              const isMergedStrategy = trades ? trades[0]?.ticker !== undefined : false;
              let pricePrecision: Record<string, number> | number = isMergedStrategy ? {} : 1;
              let qtyPrecision: Record<string, number> | number = isMergedStrategy ? {} : 1;
              const getPrecisions = (trade: ITradeData): [price: number, qty: number] => {
                return [
                  (`${trade.average_enter}`.split('.')[1] ?? '').length,
                  (`${trade.qty}`.split('.')[1] ?? '').length,
                ];
              };

              for (let i = 0; i < trades.length; i++) {
                const trade = trades[i];
                const [price, qty] = getPrecisions(trade);

                if (isMergedStrategy) {
                  if (price > (pricePrecision[trade.ticker] ?? 0))
                    pricePrecision[trade.ticker] = price;
                  if (qty > (qtyPrecision[trade.ticker] ?? 0)) qtyPrecision[trade.ticker] = qty;
                } else {
                  const [price, qty] = getPrecisions(trade);
                  if (price > (pricePrecision as number)) pricePrecision = price;
                  if (qty > (qtyPrecision as number)) qtyPrecision = qty;
                }
              }
              dispatch(setPricePrecision(pricePrecision));
              dispatch(setQtyPrecision(qtyPrecision));
              dispatch(setAllTrades(trades));
            });
        })
        .catch((error) => {
          dispatch(setChartLoading(false));
          console.error('error', error);
        });
    } else {
      apiBacktest
        .backtest({
          chart: selectedChart.isDynamicBackTest
            ? {
                date_from: selectedChart.candles[reducerStrategy.visibleRange.from]?.time,
                date_to: selectedChart.candles[reducerStrategy.visibleRange.to - 1]?.time,
              }
            : {
                date_from: dateConfig.dateFrom,
                date_to: dateConfig.dateTo,
              },
          strategy,
        })
        .then((_result) => {
          const result = _result as IOneWayBackTestResponse;
          dispatch(setBackTest(false));
          dispatch(setChartLoading(false));
          if (!result) {
            dispatch(setChartLoading(false));
            dispatch(setStats(defaultStats));
            charts.forEach((e) => {
              dispatch(updateChart({ id: e.id, data: { trades: [] } }));
            });
            return;
          }

          const isHedge = Array.isArray(result.trades[0]);
          const hedgeTrades: ITradeData[] = isHedge
            ? [
                ...((result?.trades[0] || []) as ITradeData[]),
                ...((result?.trades[1] as ITradeData[]) || []),
              ].sort((a, b) => a.id - b.id)
            : [];

          if (backTestId === strategy._id) {
            result.stats.strategy_id = strategy._id;
            // charts update
            if (isHedge) {
              dispatch(
                updateChart({
                  id: charts[0].id,
                  data: {
                    trades: result.trades[0] as ITradeData[],
                  },
                }),
              );
              dispatch(
                updateChart({
                  id: charts[1].id,
                  data: {
                    trades: result.trades[1] as ITradeData[],
                  },
                }),
              );
            } else {
              dispatch(
                updateChart({
                  id: charts[0].id,

                  data: {
                    trades: result.trades as ITradeData[],
                  },
                }),
              );
            }
            // saved strategy update
            if (isHedge) {
              dispatch(
                saveSelectedStrategy({
                  stats: result.stats,
                  trades0: result.trades[0] as ITradeData[],
                  trades1: result.trades[1] as ITradeData[],
                  chartConfig: dateConfig,
                }),
              );
            } else {
              dispatch(
                saveSelectedStrategy({
                  stats: result.stats,
                  trades: result.trades as ITradeData[],
                  chartConfig: dateConfig,
                }),
              );
            }
            // all trades / PricePrecision / QtyPrecision / stats update
            // dispatch(setAllTrades(trades));s
            if (isHedge) {
              dispatch(setAllTrades(hedgeTrades));
            } else {
              dispatch(setAllTrades(result.trades as ITradeData[]));
            }
            const trades: ITradeData[] = isHedge ? hedgeTrades : (result.trades as ITradeData[]);
            const isMergedStrategy = trades ? trades[0]?.ticker !== undefined : false;
            let pricePrecision: Record<string, number> | number = isMergedStrategy ? {} : 1;
            let qtyPrecision: Record<string, number> | number = isMergedStrategy ? {} : 1;
            const getPrecisions = (trade: ITradeData): [price: number, qty: number] => {
              return [
                (`${trade.average_enter}`.split('.')[1] ?? '').length,
                (`${trade.qty}`.split('.')[1] ?? '').length,
              ];
            };

            for (let i = 0; i < trades.length; i++) {
              const trade = trades[i];
              const [price, qty] = getPrecisions(trade);

              if (isMergedStrategy) {
                if (price > (pricePrecision[trade.ticker] ?? 0))
                  pricePrecision[trade.ticker] = price;
                if (qty > (qtyPrecision[trade.ticker] ?? 0)) qtyPrecision[trade.ticker] = qty;
              } else {
                const [price, qty] = getPrecisions(trade);
                if (price > (pricePrecision as number)) pricePrecision = price;
                if (qty > (qtyPrecision as number)) qtyPrecision = qty;
              }
            }
            dispatch(setPricePrecision(pricePrecision));
            dispatch(setQtyPrecision(qtyPrecision));
            dispatch(setStats(result.stats));
            dispatch(setChartLoading(false));
          }
        })
        .catch(() => {
          dispatch(setChartLoading(false));
        });
    }
  };
  useEffect(() => {
    if (
      !reducerStrategy.selectedMergedStrategy &&
      !reducerStrategy.selectedStrategy &&
      !reducerStrategy.selectedMergedStrategy
    ) {
      console.log('треба видалити', reducerStrategy.selectedMergedStrategy);
      console.log('set7');
      dispatch(setAllTrades([]));
    }
  }, [reducerStrategy.selectedMergedStrategy, reducerStrategy.selectedStrategy]);
  useEffect(() => {
    if (reducerStrategy.selectedStrategyId === null) {
      dispatch(
        setCharts([
          getNewChartData({
            id: '0',
            ticker: {
              type: 'default',
              baseAsset: 'BTC',
              quoteAsset: 'USDT',
            },
          }),
        ]),
      );
    }
  }, [reducerStrategy.selectedStrategyId]);
  useEffect(() => {
    if (
      reducerStrategy?.selectedMergedStrategy?._id &&
      reducerStrategy?.mergedStrategyData?._id &&
      reducerStrategy.selectedStrategyId === reducerStrategy?.selectedMergedStrategy?._id &&
      reducerStrategy.selectedStrategyId === reducerStrategy?.mergedStrategyData?._id
    ) {
      dispatch(
        setAllTrades(
          reducerStrategy.mergedStrategyData.backtestData
            .reduce<ITradeData[]>(
              (a, b) => [
                ...a,
                ...(Array.isArray(b.trades[0])
                  ? [...b.trades[0], ...(b.trades[1] as ITradeData[])]
                  : (b.trades as ITradeData[])
                ).map((e) => ({ ...e, ticker: b.ticker })),
              ],
              [],
            )
            .sort((a, b) => a.id - b.id),
        ),
      );
    }
  }, [reducerStrategy?.selectedMergedStrategy?._id, reducerStrategy?.mergedStrategyData?._id]);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    dispatch(fetchCustomTickers() as any); // Dispatch the fetchCustomTickers action
  }, []);
  useEffect(() => {
    const offArray = [];
    if (reducerStrategy.isFullBackTest) {
      reducerStrategy.savedStrategies?.forEach((strategy) => {
        const off = webSocketService.createStrategyInterval(
          strategy._id,
          reducerStrategy.isFullBackTest,
        );
        offArray.push(off);
      });

      // TODO
      if (reducerStrategy.selectedMergedStrategy) {
        offArray.push(
          webSocketService.createStrategyInterval(
            reducerStrategy.selectedMergedStrategy._id,
            reducerStrategy.isFullBackTest,
          ),
        );
      }
    } else {
      offArray.forEach((off) => off());
    }
    return () => {
      offArray.forEach((off) => off());
    };
  }, [reducerStrategy.savedStrategies, reducerStrategy.selectedMergedStrategy]);
  useEffect(() => {
    // place to check when adding chart updates
    const selectedStrategyID =
      reducerStrategy.selectedMergedStrategy?.strategies[
        reducerStrategy.selectedMergedStrategyIndex
      ];
    const data = reducerStrategy.mergedStrategyData?.backtestData.find(
      (e) => e._id === selectedStrategyID,
    );
    if (data && !reducerStrategy.selectedStrategy) {
      // place to check when adding chart updates merged');
      if (Array.isArray(data.trades[0])) {
        if (
          charts.length < 2 ||
          (charts[0].trades.length === data.trades[0].length &&
            charts[0].trades[charts[0].trades.length - 1]?.pnl ===
              (data.trades[0][data.trades[0].length - 1] as ITradeData)?.pnl &&
            charts[1].trades.length === (data.trades[1] as ITradeData[]).length &&
            charts[1].trades[charts[1].trades.length - 1]?.pnl ===
              (data.trades[1][(data.trades[1] as ITradeData[]).length - 1] as ITradeData)?.pnl)
        ) {
          return;
        }
        dispatch(
          updateChart({
            id: charts[0].id,
            data: {
              trades: data.trades[0] as ITradeData[],
            },
          }),
        );
        dispatch(
          updateChart({
            id: charts[1].id,
            data: {
              trades: data.trades[1] as ITradeData[],
            },
          }),
        );
        return;
      }
      if (
        (charts[0].trades.length !== data.trades.length ||
          charts[0].trades[charts[0].trades.length - 1]?.pnl !==
            (data.trades[data.trades.length - 1] as ITradeData)?.pnl) &&
        reducerStrategy.selectedStrategyId === reducerStrategy.mergedStrategyData._id
      ) {
        dispatch(
          updateChart({
            id,
            data: {
              trades: data.trades as ITradeData[],
            },
          }),
        );
      }
    }
  }, [charts, reducerStrategy.mergedStrategyData]);
  useEffect(() => {
    if (isBackTest) {
      backTest();
    }
  }, [isBackTest]);
};

// useEffect(() => {
//     let trades: ITradeData[] = [];
//     const savedStrategy = reducerStrategy.savedStrategies.find(
//       (e) => e._id === reducerStrategy?.selectedStrategy?._id,
//     );

//     if (reducerStrategy.selectedStrategy) {
//       switch (reducerStrategy.selectedStrategy.mode) {
//         case StrategyModes.ONE_SIDE: {
//           if (
//             reducerStrategy.savedStrategies.findIndex(
//               (e) => e._id === reducerStrategy?.selectedStrategy?._id,
//             ) !== -1 &&
//             charts[0]?.trades.length === 0
//           ) {
//             if (isOneWayModeStrategy(savedStrategy)) {
//               console.log(_.isEqual(charts[0]?.trades, savedStrategy?.trades));

//               trades = isOneWayModeStrategy(savedStrategy) ? savedStrategy?.trades : [];
//             }
//           } else {
//             trades = charts[0]?.trades;
//           }
//           break;
//         }
//         case StrategyModes.HEDGE: {
//           if (
//             reducerStrategy.savedStrategies.findIndex(
//               (e) => e._id === reducerStrategy?.selectedStrategy?._id,
//             ) !== -1
//           ) {
//             trades = isHedgeModeStrategy(savedStrategy)
//               ? savedStrategy.shortTrades
//                   .slice()
//                   .concat(savedStrategy.longTrades || [])
//                   .sort((a, b) => a.id - b.id)
//               : [];
//           } else {
//             trades = charts[0].trades
//               .slice()
//               .concat(charts[1]?.trades ?? [])
//               .sort((a, b) => a.id - b.id);
//           }
//           break;
//         }
//       }
//       dispatch(setChartDateConfig(savedStrategy?.chartConfig ?? { dateFrom: null, dateTo: null }));
//     } else if (reducerStrategy.mergedStrategyData) {
//       trades = reducerStrategy.mergedStrategyData.backtestData
//         .reduce<ITradeData[]>(
//           (a, b) => [
//             ...a,
//             ...(Array.isArray(b.trades[0])
//               ? [...b.trades[0], ...(b.trades[1] as ITradeData[])]
//               : (b.trades as ITradeData[])
//             ).map((e) => ({ ...e, ticker: b.ticker })),
//           ],
//           [],
//         )
//         .sort((a, b) => a.id - b.id);

//       dispatch(
//         setChartDateConfig(
//           reducerStrategy.mergedStrategyData.chartConfig ?? { dateFrom: null, dateTo: null },
//         ),
//       );
//     }
//     if (
//       reducerStrategy.mergedStrategyData &&
//       !reducerStrategy.selectedMergedStrategy &&
//       !reducerStrategy.selectedStrategy
//     ) {
//       trades = [];
//     }

//     const isMergedStrategy = trades ? trades[0]?.ticker !== undefined : false;
//     let pricePrecision: Record<string, number> | number = isMergedStrategy ? {} : 1;
//     let qtyPrecision: Record<string, number> | number = isMergedStrategy ? {} : 1;

//     for (let i = 0; i < trades.length; i++) {
//       const trade = trades[i];
//       const [price, qty] = getPrecisions(trade);

//       if (isMergedStrategy) {
//         if (price > (pricePrecision[trade.ticker] ?? 0)) pricePrecision[trade.ticker] = price;
//         if (qty > (qtyPrecision[trade.ticker] ?? 0)) qtyPrecision[trade.ticker] = qty;
//       } else {
//         const [price, qty] = getPrecisions(trade);
//         if (price > (pricePrecision as number)) pricePrecision = price;
//         if (qty > (qtyPrecision as number)) qtyPrecision = qty;
//       }
//     }
//     dispatch(setPricePrecision(pricePrecision));
//     dispatch(setQtyPrecision(qtyPrecision));
//     dispatch(setAllTrades(trades));
//   }, [
//     reducerStrategy.selectedStrategy,
//     reducerStrategy.selectedMergedStrategy,
//     reducerStrategy.mergedStrategyData,
//     charts[0].trades,
//     charts[1]?.trades,
//   ]);
