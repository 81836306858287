import React, { type Dispatch, type FC, useEffect } from 'react';
import { type IRootState, useSelector } from 'redux/rootReducer';
import { type AnyAction } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { type IChartDataState, type IChartState } from 'redux/chart/types';
import { type IStrategyState } from '../../../../redux/strategy/types';
import {
  getChartById,
  setAllTrades,
  setChartLoading,
  setFullScreenChart,
  setSelectedChart,
  updateAllTrades,
  updateChart,
} from 'redux/chart/reducer';
import { setVisibleRange, updateSavedStrategy } from 'redux/strategy/reducer';
import { apiCandles } from '../../../../services/api/Candles/ApiCandles';
import { webSocketService } from 'services/WebSocketService';

import {
  ActionType,
  type Axis,
  type Chart as KlineChart,
  type IndicatorDrawParams,
  type IndicatorTemplate,
  init,
  type KLineData,
  OverlayMode,
  registerIndicator,
} from '@basttyy/klinecharts';
import {
  ExchangesId,
  type SavedIHedgeStrategy,
  type SavedIStrategy,
  StrategyModes,
  TradesDirections,
} from 'interfaces/IStrategy';
import {
  type ICandleData,
  type IEventData,
  type IHedgeLiveData,
  type IOneWayLiveData,
  type IPrivateEventData,
  type ITradeCache,
  type IVector2,
} from 'interfaces/IChart';
import { type TradesSettingsState } from '../ChartStyleSettings/Interfaces';
import { type ITradeData as TradesIndicatorValue, type ITradeData } from 'interfaces/ITrade';
import { drawBackground, drawDynamicLine, drawPriceLine, drawText } from './utils';

import { ChartEditorFigure } from 'enums/ChartEditorFigure';
import styles from './ChartWrapper.module.css';
import ChartArrow from 'assets/images/chart-arrow.svg';
import OpenChartIcon from 'assets/images/Strategy/OpenChartIcon.svg';
import CloseChartIcon from 'assets/images/Strategy/CloseChartIcon.svg';

interface IProps {
  id: string;
  // === connect ===
  setSelectedChart?: (id: string) => void;
  setVisibleRange?: (data: { from: number; to: number }) => void;
  updateChart?: (id: string, data: Partial<IChartDataState>) => void;
  chart?: IChartState;
  strategy?: IStrategyState;
  tradesSettings?: TradesSettingsState;
  background?: {
    color: string;
    gradientData: {
      isGradient: boolean;
      gradientType: string | undefined;
      degrees: string;
      colors: { value: string; left?: number }[];
    };
  };
  setChartLoading?: (value: boolean) => void;
  setFullScreenChart?: (value: string | null) => void;
  updateSavedStrategy?: (id: string, data: Partial<SavedIHedgeStrategy | SavedIStrategy>) => void;
  setAllTrades?: (trades: ITradeData[]) => void;
  updateAllTrades?: (trades: ITradeData) => void;
}

interface IState {
  selectedEditor: ChartEditorFigure | null;
  isSelected: boolean;
}
class Chart extends React.Component<IProps, IState> {
  public state: IState = {
    selectedEditor: null,
    isSelected: false,
  };

  private chart: KlineChart | null = null;
  private yAxis: Axis | null = null;
  private cursorPos: { x: number; y: number } = { x: 0, y: 0 };
  private visibleRange: { from: number; to: number } = { from: 0, to: 0 };
  private tradesCache: Record<number, ITradeCache> = {};
  private tradesCandles: Record<number, number> = {};
  private candleIndexCache: Record<number, number> = {};
  private overlays = new Set<string>();
  private chartPrecision = 0;
  private scrollTo: number | null = null;
  private loadMoreResolver: ((_?: never) => void) | null = null;
  private isCandlesLoading = false;
  private isCandlesLoadingStopped = false;
  private isBlockUpdate = false;
  private tempCustomCandle = new Map<string, ICandleData>();

  private get data(): IChartDataState {
    return getChartById(this.props.chart, this.props.id);
  }

  public get chartWidth(): number {
    return this.data.width - (!this.props.chart.isSideMenu ? 52 : 0);
  }

  //
  // Render
  //

  public updateData = (data: Partial<IChartDataState>): void => {
    this.props.updateChart(this.props.id, data);
  };

  public Hook: FC = () => {
    const reducer = useSelector((state) => state.chart);
    const defaultStyles = useSelector((state) => state.chartSettings);

    useEffect(() => {
      this.chart.setStyles(defaultStyles);
    }, [defaultStyles]);
    const isBackTest = useSelector((state) => state.strategy).isBackTest;

    useEffect(this.onChangeChartSize.bind(this), [
      this.data.width,
      this.data.height,
      this.props.chart.isSideMenu,
      reducer.positionCharts,
    ]);

    const chartData = getChartById(reducer, this.props.id);

    useEffect(() => {
      if (this.isBlockUpdate) {
        this.isBlockUpdate = false;
        return;
      }
      this.isCandlesLoading = false;
      this.candleIndexCache = {};
      this.chart.applyNewData([]);
      this.props.updateChart(this.props.id, { candles: [] });
    }, [chartData.timeframe, chartData.exchange, chartData.ticker]);
    useEffect(() => {
      this.setState({
        isSelected: reducer.charts.length > 1 && reducer.selectedChart === this.props.id,
      });
    }, [reducer.selectedChart]);
    useEffect(() => {
      if (chartData.candles.length === 0) {
        this.isCandlesLoadingStopped = false;
        this.isCandlesLoading = false;
        this.loadCandles(chartData);
      }
    }, [chartData.candles, chartData.ticker, this.props.strategy.selectedStrategyId]);
    // useEffect(() => {
    //   this.loadCandles(chartData);
    // }, [this.props.strategy.selectedStrategyId]);
    useEffect(() => {
      if (isBackTest) return;
      this.onUpdateTrades(chartData.trades);
    }, [chartData.trades, isBackTest]);
    useEffect(this.onChangeDrawInstrument.bind(this, reducer), [chartData.selectedEditor]);
    useEffect(this.onChangeDrawInstrumentsVisible.bind(this, reducer), [chartData.isFigureVisible]);
    useEffect(this.onChangeDrawInstrumentsLock.bind(this, reducer), [chartData.isFigureVisible]);
    useEffect(this.onDestroyDrawInstruments.bind(this, reducer), [chartData.isFigureDestroy]);

    useEffect(() => {
      this.tempCustomCandle = new Map();
      let events: string[] = [];
      if (chartData.ticker.type === 'default') {
        events = [
          `${
            ExchangesId[chartData.exchange]
          }_${chartData.ticker.baseAsset.toLowerCase()}${chartData.ticker.quoteAsset.toLowerCase()}`,
        ];
      } else {
        events = chartData.ticker.tickers.map(
          (e) =>
            `${ExchangesId[e.exchange]}_${e.baseAsset.toLowerCase()}${e.quoteAsset.toLowerCase()}`,
        );
      }
      webSocketService.subscribe(events);
      return () => {
        webSocketService.unsubscribe(events);
      };
    }, [chartData.ticker]);

    useEffect(() => {
      document.addEventListener('click', this.onBarChartClick.bind(this));
      const offWs = webSocketService.on(this.onWebSocketData.bind(this));
      return () => {
        document.removeEventListener('click', this.onBarChartClick.bind(this));
        offWs();
      };
    }, []);
    return <></>;
  };

  public render(): React.ReactNode {
    return (
      <>
        <this.Hook />
        <div
          className="chart"
          id={this.props.id}
          onClick={() => {
            this.props.setSelectedChart(this.props.id);
          }}
          onTouchEnd={() => {
            this.props.setSelectedChart(this.props.id);
          }}
          style={{
            position: 'relative',
            border: this.state.isSelected ? '1px solid #694EF0' : '1px solid transparent',
            backgroundColor:
              !this.props?.background?.gradientData.isGradient && this.props?.background?.color,
            backgroundImage:
              this.props?.background?.gradientData.isGradient && this.props?.background?.color,
            height: '100%',
            maxHeight: `${
              window.innerWidth && window.innerWidth < 768 ? `${this.data.height + 30}px` : '100%'
            }`,
            width: `100%`, // TODO: Chart Width
          }}
        />
        {this?.data?.enableScrollToReal && (
          <div
            className={styles.chartRollRealtime}
            onClick={() => {
              this.chart?.scrollToRealTime();
            }}
          >
            <img src={ChartArrow} alt="<-" />
          </div>
        )}
        {this.props.chart.charts.length > 1 && (
          <div
            style={{
              transform: this.props.chart.positionCharts === 'horizontal' ? 'rotate(90deg)' : '',
            }}
            onClick={() => {
              if (this.props.chart.fullScreenChart === this.props.id) {
                this.props.setFullScreenChart(null);
              } else {
                this.props.setFullScreenChart(this.props.id);
              }
            }}
            className={
              this.props.chart.fullScreenChart === this.props.id
                ? styles.closeChart
                : styles.openChart
            }
          >
            <img
              src={
                this.props.chart.fullScreenChart === this.props.id ? CloseChartIcon : OpenChartIcon
              }
              alt="<-"
            />
          </div>
        )}
      </>
    );
  }

  //
  // Update Events
  //

  private onWebSocketData(data: IEventData | IPrivateEventData): void {
    if (data.event_id.startsWith('strategy_')) {
      const strategyData = data as IPrivateEventData;
      const strategyId = strategyData.event_id.replace('strategy_', '');

      if (this.data.strategyId !== strategyId) return;

      const eventData: IOneWayLiveData | IHedgeLiveData = JSON.parse(data.data);
      const trades = this.data.trades.slice();
      const lastTrade = trades[trades.length - 1];

      if ('last_trade' in eventData) {
        if (!eventData.last_trade) return;

        if (lastTrade.time === eventData.last_trade.time) {
          trades[trades.length - 1] = eventData.last_trade;
        } else {
          trades.push(eventData.last_trade);
        }
      } else {
        const receivedTrade =
          trades[trades.length - 1].side === TradesDirections.Long
            ? eventData.long_last_trade
            : eventData.short_last_trade;

        if (!receivedTrade) return;

        if (lastTrade.time === receivedTrade.time) {
          trades[trades.length - 1] = receivedTrade;
        } else {
          trades.push(receivedTrade);
        }
      }
      // this.props.updateSavedStrategy(strategyId, { trades: trades });
      this.props.updateChart(this.props.id, { trades });

      if (this.props.strategy.selectedStrategy.mode === StrategyModes.HEDGE) {
        this.props.updateAllTrades(trades[trades.length - 1]);
      } else {
        this.props.setAllTrades(trades);
      }

      return;
    }
    const candles = this.data.candles;
    if (candles.length === 0) return;
    const lastCandle = { ...candles[candles.length - 1] };
    let newCandle: ICandleData = JSON.parse(data.data);
    const ticker = data.event_id.replace(ExchangesId[this.data.exchange] + '_', '').toUpperCase();

    newCandle.open = parseFloat(newCandle.open as unknown as string);
    newCandle.high = parseFloat(newCandle.high as unknown as string);
    newCandle.low = parseFloat(newCandle.low as unknown as string);
    newCandle.close = parseFloat(newCandle.close as unknown as string);
    newCandle.volume = parseFloat(newCandle.volume as unknown as string);

    if (newCandle.time >= lastCandle.time + this.data.timeframe * 2) return;

    if (this.data.ticker.type === 'custom') {
      if (!this.data.ticker.tickers.find((e) => e.baseAsset + e.quoteAsset === ticker)) return;
      this.tempCustomCandle.set(ticker, newCandle);

      const tempCandle: ICandleData = {
        time: newCandle.time,
        open: 0,
        high: 0,
        low: 0,
        close: 0,
        volume: 0,
        trades: 0,
      };
      let success = true;
      for (const el of this.data.ticker.tickers) {
        const val = this.tempCustomCandle.get(el.baseAsset + el.quoteAsset);
        if (tempCandle.time !== val?.time) {
          success = false;
          break;
        }
        const percent = el.percent / 100;
        tempCandle.open += val.open * percent;
        tempCandle.high += val.high * percent;
        tempCandle.low += val.low * percent;
        tempCandle.close += val.close * percent;
        tempCandle.volume += val.volume * percent;
        tempCandle.trades += val.trades * percent;
      }
      if (!success) return;
      newCandle = {
        time: tempCandle.time,
        open: parseFloat(tempCandle.open.toFixed(this.chartPrecision)),
        high: parseFloat(tempCandle.high.toFixed(this.chartPrecision)),
        low: parseFloat(tempCandle.low.toFixed(this.chartPrecision)),
        close: parseFloat(tempCandle.close.toFixed(this.chartPrecision)),
        volume: parseFloat(tempCandle.volume.toFixed(this.chartPrecision)),
        trades: parseFloat(tempCandle.trades.toFixed(this.chartPrecision)),
      };
    } else {
      if (this.data.ticker.baseAsset + this.data.ticker.quoteAsset !== ticker) return;
    }

    if (newCandle.time >= lastCandle.time + this.data.timeframe) {
      newCandle.time -= newCandle.time % this.data.timeframe;
      this.props.updateChart(this.props.id, { candles: [...candles, newCandle] });
      this.chart.updateData({
        timestamp: newCandle.time,
        open: newCandle.open,
        high: newCandle.high,
        low: newCandle.low,
        close: newCandle.close,
        volume: newCandle.volume,
      });
    } else {
      if (newCandle.high > lastCandle.high) lastCandle.high = newCandle.high;
      if (newCandle.low < lastCandle.low) lastCandle.low = newCandle.low;
      lastCandle.close = newCandle.close;
      this.props.updateChart(this.props.id, { candles: [...candles.slice(0, -1), lastCandle] });
      this.chart.updateData({
        timestamp: lastCandle.time,
        open: lastCandle.open,
        high: lastCandle.high,
        low: lastCandle.low,
        close: lastCandle.close,
        volume: lastCandle.volume,
      });
    }
  }

  private async onBarChartClick(e: MouseEvent): Promise<void> {
    if (this.scrollTo !== null) return;
    // @ts-expect-error
    if (e.target.classList[0] !== 'bar-chart') return;
    // @ts-expect-error
    const date: number = e.target.__data__.time;

    this.scrollTo = date;

    this.props.setChartLoading(true);
    this.chart?.scrollToTimestamp(date);

    while (true) {
      let timeout: NodeJS.Timeout;
      const createTimeout = (): NodeJS.Timeout => {
        return setTimeout(() => {
          this.loadCandles();
          timeout = createTimeout();
        }, 3000);
      };
      timeout = createTimeout();
      await new Promise((resolve) => (this.loadMoreResolver = resolve));
      clearTimeout(timeout);
      const startTimestamp = this.data.candles[0]?.time;

      if (this.scrollTo >= startTimestamp) {
        this.chart?.scrollToTimestamp(this.scrollTo);
        this.props.setChartLoading(false);
        this.scrollTo = null;
        this.resize();
        break;
      } else {
        this.chart?.scrollToTimestamp(date);
      }
    }
  }

  private onChangeDrawInstrument(reducer: IChartState): void {
    const chart = getChartById(reducer, this.props.id);
    this.setState({ selectedEditor: chart.selectedEditor });

    switch (chart.selectedEditor) {
      case ChartEditorFigure.STRONG_MAGNETIC:
      case ChartEditorFigure.MAGNET: {
        this.overlays.forEach((id) => {
          const data = this.chart.getOverlayById(id);
          if (!data) return;
          this.chart.removeOverlay(id);
          this.chart.createOverlay({
            ...data,
            mode:
              chart.selectedEditor === 'magnet' ? OverlayMode.WeakMagnet : OverlayMode.StrongMagnet,
          });
        });
        return;
      }
    }

    if (chart.selectedEditor) {
      // eslint-disable-next-line
      const id = this.chart.createOverlay(chart.selectedEditor) as string;
      if (id && !this.overlays.has(id)) this.overlays.add(id);
    }
  }

  private onChangeDrawInstrumentsLock(reducer: IChartState): void {
    const chart = getChartById(reducer, this.props.id);
    this.overlays.forEach((i) => {
      const data = this.chart.getOverlayById(i);

      if (!data) return;
      this.chart.removeOverlay(i);
      this.chart.createOverlay({
        ...data,
        lock: chart.isFigureLock,
      });
    });
  }

  private onChangeDrawInstrumentsVisible(reducer: IChartState): void {
    const chart = getChartById(reducer, this.props.id);
    this.overlays.forEach((i) => {
      const data = this.chart.getOverlayById(i);
      if (!data) return;
      this.chart.removeOverlay(i);
      this.chart.createOverlay({
        ...data,
        visible: chart.isFigureVisible,
      });
    });
  }

  private onDestroyDrawInstruments(reducer: IChartState): void {
    const chart = getChartById(reducer, this.props.id);
    if (!chart.isFigureDestroy) return;
    this.overlays.forEach((i) => {
      this.chart.removeOverlay(i);
      this.overlays.delete(i);
    });
  }

  private onChangeChartSize(): void {
    this.chart?.setStyles(`width: ${this.chartWidth}px; height: ${this.data.height}px;`);
  }

  private onUpdateTrades(trades: ITradeData[]): void {
    if (trades.length === 0) return;
    this.tradesCache = {};
    this.tradesCandles = {};

    const candles = this.chart.getDataList();
    let candleIndex = candles.length - 1;
    for (let tradeIndex = trades.length - 1; tradeIndex >= 0; tradeIndex--) {
      const trade = trades[tradeIndex];
      const cache: ITradeCache = {
        entryCandle: null,
        exitCandle: null,
        exitPrice: null,
        y: {
          max: 0,
          min: null,
        },
        hitCandles: {},
      };

      cache.y.min = trade.stop_loss?.price?.[0]?.[1] ?? null;

      for (candleIndex; candleIndex >= 0; candleIndex--) {
        const candle = candles[candleIndex];
        if (trade.time > candle.timestamp) break;
        if (trade.exit_time === null || candle.timestamp <= trade.exit_time) {
          this.tradesCandles[candleIndex] = tradeIndex;

          if (trade.exit_time === candle.timestamp) {
            cache.exitCandle = candleIndex;
          }
        }

        trade.take_profits?.forEach((e, index) => {
          if (e.time === candle.timestamp) {
            if (!cache.hitCandles.takeProfits) cache.hitCandles.takeProfits = {};
            cache.hitCandles.takeProfits[e.reach_price] = candleIndex;

            if (index === trade.take_profits.length - 1) {
              cache.exitCandle = candleIndex;
              cache.exitPrice = e.reach_price;
            }
          }
          cache.y.max = e.price[0][1];
        });

        trade.averages?.forEach((e) => {
          if (e.time === candle.timestamp) {
            if (!cache.hitCandles.averages) cache.hitCandles.averages = {};
            cache.hitCandles.averages[e.price] = candleIndex;
          }

          if (
            cache.y.min === null ||
            (trade.side === TradesDirections.Long ? e.price < cache.y.min : e.price > cache.y.min)
          ) {
            cache.y.min = e.price;
          }
        });

        if (trade?.liquidation?.time === candle.timestamp) {
          cache.hitCandles.liquidation = candleIndex;
          cache.exitCandle = candleIndex;
          cache.exitPrice = trade.liquidation.price[trade.liquidation.price.length - 1][1];
        }

        if (trade?.stop_loss?.time === candle.timestamp) {
          cache.hitCandles.stopLoss = candleIndex;
          cache.exitCandle = candleIndex;
          cache.exitPrice = trade.stop_loss.price[trade.stop_loss.price.length - 1][1];
        }

        if (candle.timestamp <= trade.time) {
          cache.entryCandle = candleIndex;
          break;
        }
      }

      this.tradesCache[trade.time] = cache;

      if (candleIndex <= 0) {
        break;
      }
    }
    // if (this.data.candles.length === this.chart.getDataList().length) {
    //   this.chart.scrollToRealTime();
    // }
  }

  private onChangeVisibleRange(data: { realFrom: number; realTo: number }): void {
    this.visibleRange = { from: data.realFrom, to: data.realTo };
    this.props.setVisibleRange(this.visibleRange);

    if (data.realTo === this.data.candles.length) {
      if (this.data.enableScrollToReal) this.updateData({ enableScrollToReal: false });
    } else {
      if (!this.data.enableScrollToReal) this.updateData({ enableScrollToReal: true });
    }

    if (data.realFrom === 0) {
      this.loadCandles()
        .then(() => {})
        .catch(() => {});
    }
  }

  public async loadCandles(chartData?: IChartDataState): Promise<void> {
    if (this.isCandlesLoading || this.isCandlesLoadingStopped) {
      return;
    }
    const [oldExchange, oldTicker, oldTf] = [
      chartData ? chartData.exchange : this.data.exchange,
      chartData ? chartData.ticker : this.data.ticker,
      chartData ? chartData.timeframe : this.data.timeframe,
    ];
    this.isCandlesLoading = true;
    try {
      let chartCandles = this.chart.getDataList();
      const candlesResponse = await apiCandles.getCandles(
        chartData ? chartData.exchange : this.data.exchange,
        chartData ? chartData.ticker : this.data.ticker,
        chartData ? chartData.timeframe : this.data.timeframe,
        chartCandles[0]?.timestamp,
      );
      const [currExchange, currTicker, currTf] = [
        this.data.exchange,
        this.data.ticker,
        this.data.timeframe,
      ];

      if (currExchange !== oldExchange || currTf !== oldTf || currTicker.type !== oldTicker.type) {
        return;
      }

      if (currTicker.type === 'default' && oldTicker.type === 'default') {
        if (
          currTicker.baseAsset !== oldTicker.baseAsset ||
          currTicker.quoteAsset !== oldTicker.quoteAsset
        ) {
          return;
        }
      } else if (currTicker.type === 'custom' && oldTicker.type === 'custom') {
        if (currTicker._id !== oldTicker._id) {
          return;
        }
      }
      if (candlesResponse.length === 0) {
        this.isCandlesLoadingStopped = true;
        return;
      }
      this.isCandlesLoading = false;

      const firstTime = chartCandles[0]?.timestamp;
      let candles: ICandleData[];

      if (firstTime) {
        const lastTime = candlesResponse[candlesResponse.length - 1].time;

        if (lastTime >= firstTime) {
          candles = [...candlesResponse.slice(0, -((lastTime - firstTime) / 100 + 1))];
        } else {
          candles = [
            ...candlesResponse,
            ...chartCandles.map((e) => ({
              time: e.timestamp,
              open: e.open,
              high: e.high,
              low: e.low,
              close: e.close,
              volume: e.volume,
              trades: e.turnover,
            })),
          ];
        }
      } else {
        candles = candlesResponse;
      }
      chartCandles = this.chart.getDataList();
      const parsedCandles: ICandleData[] = [];
      for (const candle of candles) {
        if (
          candle.time < (firstTime ?? Infinity) &&
          !chartCandles.find((e) => e.timestamp === candle.time)
        ) {
          parsedCandles.push(candle);
        }
      }
      this.props.updateChart(this.props.id, { candles: candles });
      this.addNewCandles(parsedCandles);
    } catch (_) {
      this.isCandlesLoading = false;
    }
    this.loadMoreResolver?.();
  }

  //
  // Process Trades
  //

  private drawTrades({
    ctx,
    barSpace,
    xAxis,
    yAxis,
  }: IndicatorDrawParams<TradesIndicatorValue>): boolean {
    const candles = this.chart.getDataList();
    if (
      candles.length === 0 ||
      (!this.props.strategy.selectedStrategy && !this.props.strategy.selectedMergedStrategy)
    )
      return;

    this.yAxis = yAxis;

    const tradesRender = new Set<number>();

    for (let i = this.visibleRange.from; i < this.visibleRange.to; i++) {
      const tradeId = this.tradesCandles[i] ?? null;

      if (tradeId === null) continue;

      const trade = this.data.trades[tradeId];
      const tradeCache = this.tradesCache[trade?.time];

      if (!trade || !tradeCache || tradesRender.has(trade.time)) continue;

      tradesRender.add(trade.time);

      const isLong = trade.side === TradesDirections.Long;
      const entryPos: IVector2 = {
        x: xAxis.convertToPixel(tradeCache.entryCandle ?? 0) - barSpace.halfBar,
        y: yAxis.convertToPixel(trade.enter_price),
      };

      const exitPos: IVector2 = {
        x: xAxis.convertToPixel(tradeCache.exitCandle ?? candles.length - 1) + barSpace.halfBar,
        y: yAxis.convertToPixel(tradeCache.exitPrice ?? candles[candles.length - 1].close),
      };

      const yMin = yAxis.convertToPixel(tradeCache.y.min);
      const yMax = yAxis.convertToPixel(tradeCache.y.max);
      const isHovered = this.cursorPos.x > entryPos.x && this.cursorPos.x < exitPos.x;

      if (isLong) {
        if (trade.averages) {
          drawBackground(
            ctx,
            entryPos.x,
            exitPos.x,
            this.props.tradesSettings.trades_background.dca_area.long,
            yMin,
            entryPos.y - yMin,
          );
        } else {
          drawBackground(
            ctx,
            entryPos.x,
            exitPos.x,
            this.props.tradesSettings.trades_background.loss_area.long,
            yMin,
            entryPos.y - yMin,
          );
        }
        drawBackground(
          ctx,
          entryPos.x,
          exitPos.x,
          this.props.tradesSettings.trades_background.profit_area.long,
          entryPos.y,
          yMax - entryPos.y,
        );
      } else {
        if (trade.averages) {
          drawBackground(
            ctx,
            entryPos.x,
            exitPos.x,
            this.props.tradesSettings.trades_background.dca_area.short,
            yMin,
            entryPos.y - yMin,
          );
        } else {
          drawBackground(
            ctx,
            entryPos.x,
            exitPos.x,
            this.props.tradesSettings.trades_background.loss_area.short,
            yMin,
            entryPos.y - yMin,
          );
        }

        drawBackground(
          ctx,
          entryPos.x,
          exitPos.x,
          this.props.tradesSettings.trades_background.profit_area.short,
          entryPos.y,
          yMax - entryPos.y,
        );
      }

      // old logic
      // drawBackground(
      //   ctx,
      //   entryPos.x,
      //   exitPos.x,
      //   TRADES_COLORS.BACKGROUND[TradesDirections.Short],
      //   yMin,
      //   entryPos.y - yMin,
      // );

      // drawBackground(
      //   ctx,
      //   entryPos.x,
      //   exitPos.x,
      //   TRADES_COLORS.BACKGROUND[TradesDirections.Long],
      //   entryPos.y,
      //   yMax - entryPos.y,
      //   true,
      // );

      drawPriceLine(
        ctx,
        yAxis,
        trade.enter_price,
        this.props.tradesSettings.entry_levels.color,
        entryPos.x,
        exitPos.x,
        ``,
        this.chartPrecision,
        false,
        this.props.tradesSettings.entry_levels.thickness,
        1,
      );

      // TakeProfit

      trade.take_profits.forEach((takeProfit, tpIndex) => {
        drawDynamicLine(
          ctx,
          xAxis,
          yAxis,
          takeProfit.price.map(([time, price]) => [this.candleIndexCache[time], price]),
          this.props.tradesSettings.tp_levels.color,
          `TP${tpIndex + 1}`,
          entryPos.x,
          exitPos.x,
          this.chartPrecision,
          isHovered,
          this.props.tradesSettings.tp_levels.thickness,
          this.props.tradesSettings.level_lines_caption.tp.color,
          this.props.tradesSettings.level_lines_caption.tp.size.id,
        );

        const reachPrice = takeProfit.reach_price;
        const candleIdx = tradeCache.hitCandles.takeProfits?.[reachPrice];

        if (candleIdx) {
          const takeProfitX = xAxis.convertToPixel(candleIdx);

          this.drawCircle(ctx, takeProfitX, yAxis.convertToPixel(reachPrice), '#00FF1A', {
            bgColor: '#0D930A66',
            amount: takeProfit.qty,
            text: `TP${tpIndex + 1}`,
            textColor: this.props.tradesSettings.caption_text.tp_levels.color,
            textSize: this.props.tradesSettings.caption_text.tp_levels.size.id,
            time: new Date(takeProfit.time).toLocaleTimeString(),
            profit: `${takeProfit.profit > 0 ? '+' : ''}${takeProfit.profit.toFixed(2)}$`,
          });
        }
      });

      // Average

      trade.averages?.forEach((e, i) => {
        drawPriceLine(
          ctx,
          yAxis,
          e.price,
          this.props.tradesSettings.dca_levels.color,
          entryPos.x,
          exitPos.x,
          `DCA${i + 1}`,
          this.chartPrecision,
          isHovered,
          this.props.tradesSettings.dca_levels.thickness,
          1,
          this.props.tradesSettings.level_lines_caption.averages.color,
          this.props.tradesSettings.level_lines_caption.averages.size.id,
        );

        const candleIdx = tradeCache.hitCandles.averages?.[e.price];

        if (candleIdx) {
          const x = xAxis.convertToPixel(candleIdx);
          const y = yAxis.convertToPixel(e.price);

          this.drawCircle(ctx, x, y, '#FF8A00', {
            bgColor: '#281D4780',
            amount: e.qty,
            text: `DCA${i + 1}`,
            textColor: this.props.tradesSettings.caption_text.dca_levels.color,
            textSize: this.props.tradesSettings.caption_text.dca_levels.size.id,
            time: new Date(e.time).toLocaleTimeString(),
          });
        }
      });

      if (trade.liquidation) {
        drawDynamicLine(
          ctx,
          xAxis,
          yAxis,
          trade.liquidation.price.map(([time, price]) => [this.candleIndexCache[time], price]),
          this.props.tradesSettings.liquidation_levels.color,
          'LIQUIDATION',
          entryPos.x,
          exitPos.x,
          this.chartPrecision,
          isHovered,
          this.props.tradesSettings.liquidation_levels.thickness,
          this.props.tradesSettings.level_lines_caption.liquidation.color,
          this.props.tradesSettings.level_lines_caption.liquidation.size.id,
        );

        const candleHit = candles[tradeCache.hitCandles.liquidation];

        if (candleHit) {
          const liquidationY = yAxis.convertToPixel(
            trade.liquidation.price[trade.liquidation.price.length - 1][1],
          );

          this.drawCircle(ctx, exitPos.x - barSpace.halfBar, liquidationY, '#FF0000', {
            bgColor: '#281D4780',
            amount: trade.qty - trade.take_profit_qty,
            text: 'LIQUIDATION',
            textColor: this.props.tradesSettings.caption_text.liquidation_levels.color,
            textSize: this.props.tradesSettings.caption_text.liquidation_levels.size.id,
            time: new Date(trade.liquidation.time).toLocaleTimeString(),
          });
        }
      }

      // Stoploss

      if (trade.stop_loss) {
        const isTrailing =
          trade.stop_loss.price.length > 1 || trade.time !== trade.stop_loss.price[0][0];
        drawDynamicLine(
          ctx,
          xAxis,
          yAxis,
          trade.stop_loss.price.map(([time, price]) => [this.candleIndexCache[time], price]),
          isTrailing
            ? this.props.tradesSettings.trailing_sl_levels.color
            : this.props.tradesSettings.sl_levels.color,
          isTrailing ? 'Trailing SL' : 'SL',
          trade.time === trade.stop_loss.price[0][0]
            ? entryPos.x
            : xAxis.convertToPixel(this.candleIndexCache[trade.stop_loss.price[0][0]]),
          exitPos.x,
          this.chartPrecision,
          isHovered,
          isTrailing
            ? this.props.tradesSettings.trailing_sl_levels.thickness
            : this.props.tradesSettings.sl_levels.thickness,
          isTrailing
            ? this.props.tradesSettings.level_lines_caption.trailing_sl.color
            : this.props.tradesSettings.level_lines_caption.sl.color,
          isTrailing
            ? this.props.tradesSettings.level_lines_caption.trailing_sl.size.id
            : this.props.tradesSettings.level_lines_caption.sl.size.id,
        );

        const candleHit = candles[tradeCache.hitCandles.stopLoss];

        if (candleHit) {
          const stopLossY = yAxis.convertToPixel(
            trade.stop_loss.price[trade.stop_loss.price.length - 1][1],
          );
          const isTrailing = trade.stop_loss.price.length > 1;
          this.drawCircle(ctx, exitPos.x - barSpace.halfBar, stopLossY, '#FF0000', {
            bgColor: '#5B0930C4',
            text: isTrailing ? 'Trailing SL' : 'SL',
            textColor: isTrailing
              ? this.props.tradesSettings.caption_text.trailing_sl_levels.color
              : this.props.tradesSettings.caption_text.sl_levels.color,
            textSize: isTrailing
              ? this.props.tradesSettings.caption_text.trailing_sl_levels.size.id
              : this.props.tradesSettings.caption_text.sl_levels.size.id,
            amount: trade.qty - trade.take_profit_qty,
            time: new Date(trade.stop_loss.time).toLocaleTimeString(),
          });
        }
      }

      // Merged Exit Candle

      const exitCandle = candles[tradeCache.exitCandle];

      if (
        trade.exit_time &&
        exitCandle &&
        !trade.stop_loss?.time &&
        !trade?.liquidation?.time &&
        !trade.take_profits[trade.take_profits.length - 1]?.time
      ) {
        this.drawCircle(
          ctx,
          exitPos.x - barSpace.halfBar,
          yAxis.convertToPixel(exitCandle.close),
          '#FF0000',
          {
            bgColor: '#5B0930C4',
            text: 'Closed Trade',
            textColor: isLong
              ? this.props.tradesSettings.caption_text.open_long.color
              : this.props.tradesSettings.caption_text.open_short.color,
            textSize: isLong
              ? this.props.tradesSettings.caption_text.open_long.size.id
              : this.props.tradesSettings.caption_text.open_short.size.id,
            amount: trade.qty - trade.take_profit_qty,
            time: new Date(trade.exit_time).toLocaleTimeString(),
            profit: `${trade.pnl > 0 ? '+' : ''}${trade.pnl.toFixed(2)}$`,
          },
        );
      }

      // Open Trade

      const candle = candles[tradeCache.entryCandle];

      if (candle) {
        this.drawCircle(ctx, entryPos.x + barSpace.halfBar, entryPos.y, '#3300FF', {
          bgColor: isLong ? '#0D930A66' : '#5B0930C4',
          amount: trade.qty - (trade.average_qty ?? 0),
          text: isLong ? 'Open Long' : 'Open Short',
          textColor: isLong
            ? this.props.tradesSettings.caption_text.open_long.color
            : this.props.tradesSettings.caption_text.open_short.color,
          textSize: isLong
            ? this.props.tradesSettings.caption_text.open_long.size.id
            : this.props.tradesSettings.caption_text.open_short.size.id,
          time: new Date(trade.time).toLocaleTimeString(),
        });

        drawText(
          ctx,
          isLong ? 'Long' : 'Short',
          entryPos.x + barSpace.halfBar,
          yAxis.convertToPixel(isLong ? candle.low : candle.high) - 15 * (isLong ? -1 : 1),
          'center',
          'white',
          12,
        );
      }
    }

    return false;
  }

  //
  // Init Chart
  //

  public componentDidMount(): void {
    this.chart = init(this.props.id);
    this.chart.subscribeAction(ActionType.OnCrosshairChange, (data) => {
      this.cursorPos = { x: data.x, y: data.y };
      const selectedTradeIndex = this.tradesCandles[data.realDataIndex] ?? null;
      const selectedTrade = selectedTradeIndex ? this.data.trades[selectedTradeIndex] : null;
      const selectedTradeId = selectedTrade?.id;
      if (selectedTradeId !== this.data.selectedTradeId) {
        this.props.updateChart(this.props.id, {
          selectedTradeId,
        });
      }
    });
    this.chart.subscribeAction(
      ActionType.OnVisibleRangeChange,
      this.onChangeVisibleRange.bind(this),
    );

    const indicator: IndicatorTemplate<TradesIndicatorValue> = {
      name: 'Trades',
      calc: () => this.data.trades,
      draw: this.drawTrades.bind(this),
    };

    registerIndicator(indicator);

    this.chart.createIndicator('Trades', false, { id: 'candle_pane' });
    this.chart.overrideIndicator(indicator, 'candle_pane');
    // @ts-expect-error
    this.yAxis = this.chart._candlePane._axis;

    if (this.data.candles.length > 0) {
      this.isBlockUpdate = true;
      this.addNewCandles(this.data.candles);
      this.resize();
    } else {
      this.loadCandles()
        .then(() => {
          this.resize();
        })
        .catch(() => {});
    }
  }

  private addNewCandles(candlesResponse: ICandleData[]): void {
    const candles: KLineData[] = [];
    const chartCandlesLength = this.chart.getDataList().length;
    let maxPrecision = 0;

    candlesResponse.forEach((candle) => {
      candles.push({
        timestamp: candle.time,
        open: candle.open,
        high: candle.high,
        low: candle.low,
        close: candle.close,
        volume: candle.volume,
      });

      const precision = (`${candle.close}`.split('.')[1] ?? '').length;

      if (precision > maxPrecision) maxPrecision = precision;
    });

    if (candles.length > 0 && this.chartPrecision !== maxPrecision) {
      this.chartPrecision = maxPrecision;
      this.chart.setPriceVolumePrecision(maxPrecision, 2);
    }

    this.chart.applyMoreData(candles, true, () => {
      if (chartCandlesLength === 0) {
        this.resize();
        this.chart.scrollToRealTime();
      }
    });

    if (this.visibleRange.from !== 0 && chartCandlesLength === 0) {
      this.chart.scrollToDataIndex(this.visibleRange.from);
    }

    this.candleIndexCache = {};
    this.chart.getDataList().forEach((e, i) => {
      this.candleIndexCache[e.timestamp] = i;
    });

    this.onUpdateTrades(this.data.trades);
  }

  private resize(): void {
    // @ts-expect-error
    this.yAxis.setAutoCalcTickFlag(true);
    this.chart.resize();
  }

  //
  // Draw Utils
  //

  private drawCircle(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    stroke: string,
    data?: {
      bgColor: string;
      amount: number;
      text: string;
      textColor: string;
      textSize: number;
      profit?: string;
      time: string;
    },
  ): void {
    const radius = 4;
    const fill = 'white';
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
    if (fill) {
      ctx.fillStyle = fill;
      ctx.fill();
    }
    if (stroke) {
      ctx.lineWidth = 3;
      ctx.strokeStyle = stroke;
      ctx.stroke();
    }
    ctx.closePath();

    const r = radius;
    const startX = x - r;
    const endX = x + r;
    const startY = y - r;
    const endY = y + r;
    const { x: currX, y: currY } = this.cursorPos;

    if (data && currX >= startX && currX <= endX && currY >= startY && currY <= endY) {
      ctx.beginPath();
      ctx.setLineDash([]);

      let xi = x;
      let yi = y;

      const border = 5;
      ctx.font = `normal 14px ${this.props.tradesSettings.caption_text.fontFamily}, sans-serif`;
      const sizeWidthArr = [data.amount.toString(), data.text, data.time].map(
        (e) => ctx.measureText(e).width,
      );
      if (data.profit) {
        sizeWidthArr.push(sizeWidthArr[1] + ctx.measureText(data.profit).width + 5);
      }

      const width = Math.max(...sizeWidthArr) + border * 2;
      const height = border + 12 + border + 14 + border + 12 + border;
      ctx.moveTo(x, y);
      ctx.lineTo((xi -= 10), (yi -= 20));
      ctx.lineTo((xi -= width), yi);
      ctx.lineWidth = 1.5;
      ctx.strokeStyle = 'white';
      ctx.stroke();
      ctx.closePath();

      ctx.fillStyle = data.bgColor;
      ctx.fillRect(xi, yi - height, width, height);
      xi += border;
      yi -= height - 6 - border;
      const isProfit = data?.profit?.includes('+');
      drawText(
        ctx,
        data.amount.toString(),
        xi,
        yi,
        'left',
        isProfit
          ? this.props.tradesSettings.trade_size_caption.positive_quantity.color
          : this.props.tradesSettings.trade_size_caption.negative_quantity.color,
        12,
        this.props.tradesSettings.caption_text.fontFamily,
      );
      drawText(
        ctx,
        data.text,
        xi,
        (yi += 13 + border),
        'left',
        data.textColor,
        data.textSize,
        this.props.tradesSettings.caption_text.fontFamily,
      );
      if (data.profit) {
        const w = ctx.measureText(data.text).width;

        drawText(
          ctx,
          data.profit,
          xi + w + 5,
          yi,
          'left',
          data.profit[0] === '+' ? '#00FF1A' : '',
          14,
        );
      }

      drawText(ctx, data.time, xi, yi + 13 + border, 'left', 'white', 9);
    }
  }
}

const mapStateToProps = (state: IRootState): unknown => ({
  chart: state.chart,
  strategy: state.strategy,
  background: state.chartBackgroundSettings,
  tradesSettings: state.chartTradeSettings,
});

// mapDispatchToProps function to dispatch actions
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): unknown => ({
  setSelectedChart: (id: string) => {
    dispatch(setSelectedChart(id));
  },
  updateChart: (id: string, data: Partial<IChartDataState>) => {
    dispatch(updateChart({ id, data }));
  },
  setChartLoading: (value: boolean) => {
    dispatch(setChartLoading(value));
  },
  setFullScreenChart: (value: string | null) => {
    dispatch(setFullScreenChart(value));
  },
  updateSavedStrategy: (_id: string, data: Partial<SavedIHedgeStrategy | SavedIStrategy>) => {
    dispatch(updateSavedStrategy({ _id, data }));
  },
  setAllTrades: (trades: ITradeData[]) => {
    dispatch(setAllTrades(trades));
  },
  updateAllTrades: (trade: ITradeData) => {
    dispatch(updateAllTrades(trade));
  },
  setVisibleRange: (data: { from: number; to: number }) => {
    dispatch(setVisibleRange(data));
  },
});

// Connect the component to Redux
export default connect(mapStateToProps, mapDispatchToProps)(Chart);
