import { useState, type FC } from 'react';
import { StrategySelector } from '../StrategySelector/StrategySelector';
import {
  BaseCurrencyType,
  type IStrategy,
  MarginType,
  OrderSizeType,
  OrderTypes,
} from '../../../../../../interfaces/IStrategy';
import GTumbler from '../../../../../../components/GTumbler/GTumbler';
import { useSelector } from '../../../../../../redux/rootReducer';
import { useDispatch } from 'react-redux';
import {
  deleteSavedtrategy,
  setBackTest,
  updateSelectedStrategy,
} from '../../../../../../redux/strategy/reducer';
import ReversedMartingaleSettings from '../ReversedMartingaleSettings/ReversedMartingaleSettings';
import { replaceText } from '../../../../../../utils/string';
import ReEntryCooldownSettings from '../ReEntryCooldownSettings/ReEntryCooldownSettings';

const martingaleCycles = {
  0: [1, 2, 2, 4, 8],
};

const martingaleCyclesKeys: Record<string, number> = {};

for (const i in martingaleCycles)
  martingaleCyclesKeys[martingaleCycles[i].toString()] = parseInt(i);

const properties = [
  {
    key: 'deposit',
    name: 'Deposit',
    type: 'number',
    min: 10,
    max: 1000000,
  },
  {
    key: 'leverage',
    name: 'Leverage',
    suffix: 'X',
    type: 'number',
    min: 1,
    max: 200,
  },
  {
    key: 'order_size_type',
    name: 'Entry size type',
    type: 'select',
    options: [
      {
        id: OrderSizeType.Dollar,
        name: 'Fixed %quote_asset%',
      },
      {
        id: OrderSizeType.Percent,
        name: 'C.I. %',
      },
      {
        id: OrderSizeType.ReverseMartingale,
        name: 'Rev. martin',
      },
    ],
  },
  {
    key: 'martingale_cycle',
    name: 'Reverse martingale cycle',
    type: 'settings',
    filter: (property: IStrategy['property']) =>
      property.order_size_type === OrderSizeType.ReverseMartingale,
  },
  {
    key: 'reentry_cooldown',
    name: 'ReEntry Cooldown',
    type: 'reEntryCooldown',
  },
  {
    key: 'order_size_dollar.long',
    name: 'Entry size: Long trades (%quote_asset%)',
    type: 'number',
    trade: 0,
    min: 0,
    max: 1000000,
    filter: (property: IStrategy['property']) => property.order_size_type === OrderSizeType.Dollar,
  },
  {
    key: 'order_size_dollar.short',
    name: 'Entry size: Short trades (%quote_asset%)',
    type: 'number',
    trade: 1,
    min: 0,
    max: 1000000,
    filter: (property: IStrategy['property']) => property.order_size_type === OrderSizeType.Dollar,
  },
  {
    key: 'order_size_percent.long',
    name: 'Entry size: Long trades (%quote_asset%)',
    type: 'number',
    trade: 0,
    min: 0.01,
    max: 100,
    filter: (property: IStrategy['property']) => property.order_size_type === OrderSizeType.Percent,
  },
  {
    key: 'order_size_percent.short',
    name: 'Entry size: Short trades (%)',
    type: 'number',
    trade: 1,
    min: 0.01,
    max: 100,
    filter: (property: IStrategy['property']) => property.order_size_type === OrderSizeType.Percent,
  },
  {
    key: 'base_currency',
    name: 'Base currency',
    type: 'select',
    options: [
      {
        id: BaseCurrencyType.USDT,
        name: '%quote_asset%',
      },
    ],
  },
  {
    key: 'type_closing_orders',
    name: 'Type of closing orders',
    type: 'select',
    options: [
      {
        id: OrderTypes.Market,
        name: 'Market',
      },
      {
        id: OrderTypes.Limit,
        name: 'Limit',
      },
    ],
  },
  {
    key: 'commission_limit',
    name: 'Commission (Limit)',
    type: 'number',
    min: 0,
    max: 100,
    filter: (property: IStrategy['property']) => property.type_closing_orders === OrderTypes.Limit,
  },
  {
    key: 'commission_market',
    name: 'Commission (Market)',
    type: 'number',
    min: 0,
    max: 100,
    filter: (property: IStrategy['property']) => property.type_closing_orders === OrderTypes.Market,
  },
  {
    key: 'margin_type',
    name: 'Margin Mode',
    type: 'select',
    options: [
      {
        id: MarginType.Isolated,
        name: 'Isolated',
      },
      {
        id: MarginType.Cross,
        name: 'Cross',
      },
    ],
  },
  {
    key: 'infinite_deposit',
    name: 'Infinite Balance',
    type: 'switch',
  },
];

export const StrategyProperties: FC = () => {
  const strategyState = useSelector((state) => state.strategy);
  const strategy = strategyState.selectedStrategy;
  const strategyProperty = strategy.property;
  const isMobileVersion = window.innerWidth < 768;
  const [isMartingaleOpened, setIsMartingaleOpened] = useState(false);
  const [isReEntryCooldownOpened, setIsReEntryCooldownOpened] = useState(false);
  const dispatch = useDispatch();
  if (!strategyProperty) return;
  const quoteAsset =
    (strategy.ticker.quote_asset ?? 'USDT') === 'USDT' ? '$' : strategy.ticker.quote_asset;

  const setProperty = (property: IStrategy['property']): void => {
    dispatch(updateSelectedStrategy({ property }));
  };
  const runBackTest = (): void => {
    dispatch(deleteSavedtrategy(strategy._id));
    dispatch(setBackTest(true));
  };

  const getValue = (key: string): string => {
    let val: unknown = strategyProperty;

    key.split('.').forEach((e) => {
      val = val[e];
    });

    return val as string;
  };
  if (isMartingaleOpened) {
    return (
      <ReversedMartingaleSettings
        strategyProperty={strategyProperty}
        setProperty={setProperty}
        onClose={() => {
          setIsMartingaleOpened(false);
        }}
        onRunBackTest={runBackTest}
      />
    );
  }
  if (isReEntryCooldownOpened) {
    return (
      <ReEntryCooldownSettings
        strategyProperty={strategyProperty}
        setProperty={setProperty}
        onClose={() => {
          setIsReEntryCooldownOpened(false);
        }}
        onRunBackTest={runBackTest}
      />
    );
  }

  return (
    <>
      {properties.map(
        (e) =>
          (e.filter?.(strategyProperty) ?? true) &&
          (e.key === 'martingale_cycle_for' ? strategy.mode === 1 : true) && (
            <>
              <div
                key={e.key + e.name + String(e?.trade)}
                className="strategy__main__inline"
                style={{
                  marginTop: (e.type === 'settings' || e.type === 'reEntryCooldown') && '10px',
                }}
              >
                <h4>{replaceText(e.name, ['quote_asset', quoteAsset])}</h4>
                {e.type === 'switch' && (
                  <GTumbler
                    size={isMobileVersion ? 'large' : 'small'}
                    name={e.name}
                    checked={strategyProperty[e.key]}
                    onToggle={(value) => {
                      setProperty({
                        ...strategyProperty,
                        [e.key]: value,
                      });
                    }}
                  />
                )}
                {e.type === 'select' && (
                  <StrategySelector
                    key={strategyProperty[e.key]}
                    width="100px"
                    height={4}
                    options={e.options.map((e) => ({
                      id: e.id,
                      name: replaceText(e.name, ['quote_asset', quoteAsset]),
                    }))}
                    activeOption={strategyProperty[e.key] ?? e.options[0].id}
                    onChange={(value) => {
                      switch (e.key) {
                        case 'base_currency':
                          return;
                        default: {
                          setProperty({ ...strategyProperty, [e.key]: value });
                        }
                      }
                    }}
                  />
                )}
                {e.type === 'number' && (
                  <>
                    <input
                      type="text"
                      className="strategy__main__inline-input"
                      value={
                        e.key === 'martingale_cycle'
                          ? strategyProperty.martingale_cycle.length
                          : `${getValue(e.key)}` + (e.suffix ?? '')
                      }
                      onChange={(ev) => {
                        let value = parseFloat(ev.target.value);
                        if (isNaN(value)) value = e.min;
                        if (value < e.min) value = e.min;
                        if (value > e.max) value = e.max;
                        const temp = { ...strategyProperty };
                        const keys = e.key.split('.');

                        if (keys.length === 2) {
                          const elem = { ...temp[keys[0]] };
                          elem[keys[1]] = value;
                          temp[keys[0]] = elem;
                        } else {
                          temp[keys[0]] = value;
                        }
                        setProperty(temp);
                      }}
                    />
                  </>
                )}
                {e.type === 'settings' && (
                  <div
                    className="strategy__settingsIcon"
                    onClick={() => {
                      setIsMartingaleOpened(true);
                    }}
                  ></div>
                )}

                {e.type === 'reEntryCooldown' && (
                  <div
                    className="strategy__settingsIcon"
                    onClick={() => {
                      setIsReEntryCooldownOpened(true);
                    }}
                  ></div>
                )}
              </div>
            </>
          ),
      )}
      <div className="strategy__saveButtonContainer">
        <div style={{ color: 'white' }} className="strategy__saveButton" onClick={runBackTest}>
          Save
        </div>
      </div>
    </>
  );
};
