import { ApiServiceBase } from '../../ApiServiceBase';

import { type Exchanges } from '../../../interfaces/IStrategy';
import { type ICandleData } from '../../../interfaces/IChart';
import { type IChartDataState } from '../../../redux/chart/types';
import { GET_TIMEFRAME_NAME } from '../../../configs/chart';
import { type IFetchCandlesDto } from './types';

class ApiCandles extends ApiServiceBase {
  protected baseUrl = '/api/candles/';

  public async getCandles(
    exchange: Exchanges,
    ticker: IChartDataState['ticker'],
    tf: number,
    endTime?: number,
  ): Promise<ICandleData[]> {
    try {
      console.log('indside api', exchange, ticker, tf, endTime);
      const requestData: IFetchCandlesDto = {
        exchange,
        ticker:
          ticker.type === 'default'
            ? {
                base_asset: ticker.baseAsset,
                quote_asset: ticker.quoteAsset,
              }
            : ticker.tickers.map((ticker) => ({
                base_asset: ticker.baseAsset,
                quote_asset: ticker.quoteAsset,
                percent: ticker.percent,
              })),
        timeframe: GET_TIMEFRAME_NAME(tf),
        end_time: endTime,
      };

      const response = await this.post<IFetchCandlesDto, Record<string, string | number>[]>(
        'fetch',
        requestData,
      );

      return response.data.map((e) => ({
        time: e.time as number,
        open: parseFloat(e.open as string),
        high: parseFloat(e.high as string),
        low: parseFloat(e.low as string),
        close: parseFloat(e.close as string),
        volume: parseFloat(e.volume as string),
        trades: e.trades as number,
      }));
    } catch (e) {
      return [];
    }
  }
}

export const apiCandles = new ApiCandles();
