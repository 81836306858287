import { type MutableRefObject, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { selectTheme } from 'redux/theme/reducer';
import { setFullScreenMode, updateChart, useChartSelector } from 'redux/chart/reducer';
import StrategiesList from '../../StrategiesList';
import { useSelector } from 'redux/rootReducer';
import { HeaderDayPicker } from '../../HeaderDayPicker';
import { LoaderOverlay } from 'components/LoaderOverlay';
import TickerList from '../../TickerList';
import { useFakeScrollBar } from '../../../customHooks/useFakeScrollBar';
import { ChartInfoSelector } from 'components/ChartInfoSelector';
import ChartStylingSettings from 'pages/HomePage/components/ChartStyleSettings/ChartStyleSettings';
import useWindowWidth from 'hooks/useWindowWidth';
import classNames from 'classnames';
import styles from '../../../ChartWrapper.module.css';
import FullScreenIcon from 'assets/images/FullScreenIcon.svg';
import SmallScreenIcon from 'assets/icons/SmallScreenIcon.svg';
import ScreenIcon from 'assets/images/ScreenIcon.svg';
import strategyMetricsIcon from 'assets/icons/strategy-metrics.svg';

const ChartWrapperHeader = ({
  expandConstructor,
  isConstructorOpen,
}: {
  expandConstructor: () => void;
  isConstructorOpen: boolean;
}): JSX.Element => {
  const {
    selectedChart: chartId,
    isLoading,
    charts,
    fullScreenMode,
    isPreview,
  } = useSelector((state) => state.chart);
  const { selectedMergedStrategy, selectedStrategy, isFullBackTest, selectedStrategyId } =
    useSelector((state) => state.strategy);
  const theme = useSelector(selectTheme);
  const { ticker } = useChartSelector(chartId);
  const dispatch = useDispatch();
  const isCustomTicker = ticker.type === 'custom';
  const contentWrapperRef = useRef(null) as MutableRefObject<HTMLDivElement>;
  const fakeScrollBarRef = useFakeScrollBar(contentWrapperRef);
  const isMobileVersion = useWindowWidth() < 768;
  const updateChartsSize =
    () =>
    (chartContainer: { clientWidth: number; clientHeight: number }): void => {
      charts.forEach((e) => {
        dispatch(
          updateChart({
            id: e.id,
            data: {
              width: chartContainer?.clientWidth + Math.random() / 10000000,
              height: chartContainer?.clientHeight - 30 + Math.random() / 10000000,
            },
          }),
        );
      });
    };
  return (
    <div
      style={{
        backgroundColor: theme.chartHeaderBakcground,
        color: theme.textColor,
      }}
      className={classNames(
        styles.chartHeader,
        !fullScreenMode
          ? {
              [styles.chartHeaderCustomIndex]: isCustomTicker,
              [styles.chartHeaderMerged]:
                !selectedStrategy &&
                isFullBackTest &&
                selectedMergedStrategy?.strategies.length > 1 &&
                !isCustomTicker,
              [styles.chartHeaderMergedCustomIndex]:
                !selectedStrategy &&
                isFullBackTest &&
                selectedMergedStrategy?.strategies.length > 1 &&
                isCustomTicker,
            }
          : '',
      )}
    >
      <LoaderOverlay isShow={isLoading} position={'absolute'} imageWidth={0} />
      {/* 
        className={classNames(
          styles.chartSidebarIconWrapper,
          !fullScreenMode
            ? {
                [styles.chartSidebarIconWrapperCustomIndex]: isCustomTicker,
                [styles.chartSidebarIconWrapperMerged]:
                  !selectedStrategy &&
                  selectedMergedStrategy?.strategies.length > 1 &&
                  !isCustomTicker,
                [styles.chartSidebarIconWrapperMergedCustomIndex]:
                  !selectedStrategy &&
                  selectedMergedStrategy?.strategies.length > 1 &&
                  isCustomTicker,

   */}
      <div
        className={classNames(
          styles.chartHeaderInnerContainer,
          !fullScreenMode
            ? {
                [styles.chartHeaderInnerContainerCustomIndex]: isCustomTicker,
                [styles.chartHeaderInnerContainerMerged]:
                  !selectedStrategy &&
                  isFullBackTest &&
                  selectedMergedStrategy?.strategies.length > 1 &&
                  !isCustomTicker,
                [styles.chartHeaderInnerContainerMergedCustomIndex]:
                  !selectedStrategy &&
                  isFullBackTest &&
                  selectedMergedStrategy?.strategies.length > 1 &&
                  isCustomTicker,
              }
            : '',
        )}
      >
        {!fullScreenMode && !selectedStrategy && selectedMergedStrategy?.strategies.length > 1 && (
          <div className={styles.fakeScrollMainContainer} style={{ background: 'rgb(10, 8, 26)' }}>
            <StrategiesList isCustomTicker={isCustomTicker} />
          </div>
        )}
        <div className={styles.chartHeaderScrollContainer} ref={contentWrapperRef}>
          <div className={styles.chartHeaderContent}>
            <ChartInfoSelector />
            {(isFullBackTest || !selectedStrategyId) && (
              <div
                className={`${styles.chartHeaderRightContainer}  ${
                  isPreview ? styles.chartHeaderRightContainerPreview : ''
                }`}
                style={isMobileVersion ? { minWidth: 'unset' } : {}}
              >
                <div
                  className={`${styles.chartHeadertIconsContainer} ${
                    isPreview ? styles.chartHeadertIconsContainerPreview : ''
                  }`}
                >
                  {!isMobileVersion && !isPreview && <HeaderDayPicker />}
                  <img
                    src={!fullScreenMode ? FullScreenIcon : SmallScreenIcon}
                    onClick={() => {
                      dispatch(setFullScreenMode(!fullScreenMode));
                      updateChartsSize();
                    }}
                    alt="arrow"
                  />
                  <img src={ScreenIcon} alt="arrow" />
                  <ChartStylingSettings />
                </div>
              </div>
            )}
          </div>
          <div
            className={styles.fakeChartScrollBar2}
            style={
              !fullScreenMode && isCustomTicker
                ? { bottom: selectedMergedStrategy ? '34px' : '38px' }
                : {}
            }
            ref={fakeScrollBarRef}
          ></div>
        </div>
        {!fullScreenMode && isCustomTicker && <TickerList tickers={ticker.tickers} />}
      </div>
      {!fullScreenMode && !isConstructorOpen && expandConstructor && (
        <div
          onClick={() => {
            expandConstructor?.();
          }}
          className={styles.openStrategyBtn}
          style={{ bottom: charts.length > 1 ? '-70px' : '' }}
        >
          {' '}
          Open Constructor
          <img src={strategyMetricsIcon} alt="" />
        </div>
      )}
    </div>
  );
};

export default ChartWrapperHeader;
