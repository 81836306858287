import './Info.scss';
import { type FC } from 'react';
import { useSelector } from 'redux/rootReducer';
import { selectTheme } from 'redux/theme/reducer';
import { STATS_ELEMENTS } from './pages';
import { LoaderOverlay } from 'components/LoaderOverlay';
import useWindowWidth from 'hooks/useWindowWidth';
interface IProps {
  showLoader?: boolean;
}

export const Info: FC<IProps> = ({ showLoader }) => {
  const theme = useSelector(selectTheme);
  const { stats, isLoading } = useSelector((state) => state.chart);
  const { isFullBackTest } = useSelector((state) => state.strategy);
  const width = useWindowWidth();
  return (
    <>
      {showLoader && (
        <LoaderOverlay position={'absolute'} isShow={isLoading} isChart={false} imageWidth={140} />
      )}
      {stats.strategy_id && (
        <div className={`info ${!isFullBackTest && 'info_quickBackTest'}`}>
          {!isFullBackTest && width > 768 && (
            <div className="info_quickBackTest_header">
              <div className="info_quickBackTest_header_text"> Basic metrics</div>
            </div>
          )}
          {STATS_ELEMENTS.map((Element, i) => (
            <Element key={i} data={stats} theme={theme} isFullBackTest={isFullBackTest} />
          ))}
        </div>
      )}
    </>
  );
};
