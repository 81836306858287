import { useSelector } from 'redux/rootReducer';
import { useDispatch } from 'react-redux';
import {
  refreshTradeSettings,
  updateTradeSettingsProperty,
} from 'redux/chartTradeSettings/reducer';
import GTumbler from 'components/GTumbler/GTumbler';
import { CustomColorPicker } from '../../ColorPicker/ColorPicker';
import CustomFontSizeDropDown from '../../DropDownComponents/CustomFontSizeDropDown/CustomFontSizeDropDown';
import CustomDropDown from '../../DropDownComponents/CustomMultiLineDropDown/CustomStyleSettingsDropDown';
import CustomThicknessDropDown from '../../DropDownComponents/CustomThicknessDropDown/CustomThicknessDropDown';
import { fontFamilyTypes, numberSizes } from '../../../Data/dropdownData';
import useWindowWidth from 'hooks/useWindowWidth';
import { apiChartStyles } from 'services/api/ApiChartStyles/ApiChartStyles';

const TradeSettings = (): JSX.Element => {
  const settings = useSelector((state) => state.chartTradeSettings);
  const isMobileVersion = useWindowWidth() < 768;
  const dispatch = useDispatch();
  return (
    <div>
      <div className="container__content_main_content_header">
        LEVEL LINES
        <div className="propertyContainer__candleTypeDropDown"></div>
      </div>
      <div className="propertyContainer">
        TP Levels{' '}
        <div className="propertyContainer__thicknessColorContainer">
          <CustomThicknessDropDown
            activeOption={settings.tp_levels.thickness}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.tp_levels.thickness',
                value: e,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'tp_levels',
                  propertyNode2: 'thickness',
                  value: e,
                }),
              );
            }}
            header="Thickness"
          />
          <CustomColorPicker
            defaultColor={settings.tp_levels.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.tp_levels.color',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'tp_levels',
                  propertyNode2: 'color',
                  value: color,
                }),
              );
            }}
            name="Color"
          />
        </div>
      </div>
      <div className="propertyContainer">
        Sl Levels
        <div className="propertyContainer__thicknessColorContainer">
          <CustomThicknessDropDown
            activeOption={settings.sl_levels.thickness}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.sl_levels.thickness',
                value: e,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'sl_levels',
                  propertyNode2: 'thickness',
                  value: e,
                }),
              );
            }}
          />
          <CustomColorPicker
            defaultColor={settings.sl_levels.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.sl_levels.color',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'sl_levels',
                  propertyNode2: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Sl Trailing Levels
        <div className="propertyContainer__thicknessColorContainer">
          <CustomThicknessDropDown
            activeOption={settings.trailing_sl_levels.thickness}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.trailing_sl_levels.thickness',
                value: e,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'trailing_sl_levels',
                  propertyNode2: 'thickness',
                  value: e,
                }),
              );
            }}
          />
          <CustomColorPicker
            defaultColor={settings.trailing_sl_levels.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.trailing_sl_levels.color',
                value: color,
              });

              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'trailing_sl_levels',
                  propertyNode2: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Avg. entry levels
        <div className="propertyContainer__thicknessColorContainer">
          <CustomThicknessDropDown
            activeOption={settings.entry_levels.thickness}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.entry_levels.thickness',
                value: e,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'entry_levels',
                  propertyNode2: 'thickness',
                  value: e,
                }),
              );
            }}
          />
          <CustomColorPicker
            defaultColor={settings.entry_levels.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.entry_levels.color',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'entry_levels',
                  propertyNode2: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Liquidation level
        <div className="propertyContainer__thicknessColorContainer">
          <CustomThicknessDropDown
            activeOption={settings.liquidation_levels.thickness}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.liquidation_levels.thickness',
                value: e,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'liquidation_levels',
                  propertyNode2: 'thickness',
                  value: e,
                }),
              );
            }}
          />
          <CustomColorPicker
            defaultColor={settings.liquidation_levels.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.liquidation_levels.color',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'liquidation_levels',
                  propertyNode2: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        DCA levels{' '}
        <div className="propertyContainer__thicknessColorContainer">
          <CustomThicknessDropDown
            activeOption={settings.dca_levels.thickness}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.dca_levels.thickness',
                value: e,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'dca_levels',
                  propertyNode2: 'thickness',
                  value: e,
                }),
              );
            }}
          />
          <CustomColorPicker
            defaultColor={settings.dca_levels.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.dca_levels.color',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'dca_levels',
                  propertyNode2: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="container__content_main_content_header">
        TRADES BACKGROUND
        <div className="propertyContainer__candleTypeDropDown"></div>
      </div>
      <div className="propertyContainer">
        Profit area{' '}
        <div className="propertyContainer__colorsContainer">
          <CustomColorPicker
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.trades_background.profit_area.long',
                value: color,
              });

              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'trades_background',
                  propertyNode2: 'profit_area',
                  propertyNode3: 'long',
                  value: color,
                }),
              );
            }}
            defaultColor={settings.trades_background.profit_area.long.color}
            name="Long"
            showGradient
          />
          <CustomColorPicker
            defaultColor={settings.trades_background.profit_area.short.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.trades_background.profit_area.short',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'trades_background',
                  propertyNode2: 'profit_area',
                  propertyNode3: 'short',
                  value: color,
                }),
              );
            }}
            name="Short"
            showGradient
          />
        </div>
      </div>
      <div className="propertyContainer">
        Loss area{' '}
        <div className="propertyContainer__colorsContainer">
          <CustomColorPicker
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.trades_background.loss_area.long',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'trades_background',
                  propertyNode2: 'loss_area',
                  propertyNode3: 'long',
                  value: color,
                }),
              );
            }}
            defaultColor={settings.trades_background.loss_area.long.color}
            showGradient
          />
          <CustomColorPicker
            defaultColor={settings.trades_background.loss_area.short.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.trades_background.loss_area.short',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'trades_background',
                  propertyNode2: 'loss_area',
                  propertyNode3: 'short',
                  value: color,
                }),
              );
            }}
            showGradient
          />
        </div>
      </div>
      <div className="propertyContainer">
        DCA area{' '}
        <div className="propertyContainer__colorsContainer">
          <CustomColorPicker
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.trades_background.dca_area.long',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'trades_background',
                  propertyNode2: 'dca_area',
                  propertyNode3: 'long',
                  value: color,
                }),
              );
            }}
            defaultColor={settings.trades_background.dca_area.long.color}
            showGradient
          />
          <CustomColorPicker
            defaultColor={settings.trades_background.dca_area.short.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.trades_background.dca_area.short',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'trades_background',
                  propertyNode2: 'dca_area',
                  propertyNode3: 'short',
                  value: color,
                }),
              );
            }}
            showGradient
          />
        </div>
      </div>
      {/* <div className="container__content_main_content_header">
        SIDE BAR MARKS
        <div className="propertyContainer__candleTypeDropDown"></div>
      </div>
      <div className="propertyContainer">
        Entry point
        <div className="propertyContainer__thicknessColorContainer">
          <CustomArrowsDropDown
            onChange={() => {}}
            activeOption={{ id: settings.side_bar_marks.entry_point.size }}
            header="Size"
          />
          <CustomColorPicker
            defaultColor={settings.side_bar_marks.entry_point.color}
            onAddColor={(color) => {
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'side_bar_marks',
                  propertyNode2: 'entry_point',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Closing orders
        <div className="propertyContainer__thicknessColorContainer">
          <CustomArrowsDropDown
            onChange={(e) => {
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'side_bar_marks',
                  propertyNode2: 'closing_orders',
                  propertyNode3: 'size',
                  value: e,
                }),
              );
            }}
            activeOption={{ id: settings.side_bar_marks.closing_orders.size }}
          />
          <CustomColorPicker
            defaultColor={settings.side_bar_marks.closing_orders.color}
            onAddColor={(color) => {
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'side_bar_marks',
                  propertyNode2: 'closing_orders',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Averaging orders
        <div className="propertyContainer__thicknessColorContainer">
          <CustomArrowsDropDown
            onChange={(e) => {
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'side_bar_marks',
                  propertyNode2: 'averaging_orders',
                  propertyNode3: 'size',
                  value: e,
                }),
              );
            }}
            activeOption={{ id: settings.side_bar_marks.averaging_orders.size }}
          />
          <CustomColorPicker
            defaultColor={settings.side_bar_marks.averaging_orders.color}
            onAddColor={(color) => {
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'side_bar_marks',
                  propertyNode2: 'averaging_orders',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="container__content_main_content_header">
        UNDER/ABOVE BAR MARKS
        <div className="propertyContainer__candleTypeDropDown"></div>
      </div>
      <div className="propertyContainer">
        Entry point
        <div className="propertyContainer__thicknessColorContainer">
          <CustomDownArrowsDropDown
            onChange={(e) => {
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'under_above_marks',
                  propertyNode2: 'entry_point',
                  propertyNode3: 'size',
                  value: e,
                }),
              );
            }}
            activeOption={{ id: settings.under_above_marks.entry_point.size }}
            header="Size"
          />
          <CustomColorPicker
            defaultColor={settings.under_above_marks.entry_point.color}
            onAddColor={(color) => {
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'under_above_marks',
                  propertyNode2: 'entry_point',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Closing orders
        <div className="propertyContainer__thicknessColorContainer">
          <CustomDownArrowsDropDown
            onChange={(e) => {
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'under_above_marks',
                  propertyNode2: 'closing_orders',
                  propertyNode3: 'size',
                  value: e,
                }),
              );
            }}
            activeOption={{ id: settings.under_above_marks.closing_orders.size }}
          />
          <CustomColorPicker
            defaultColor={settings.under_above_marks.closing_orders.color}
            onAddColor={(color) => {
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'under_above_marks',
                  propertyNode2: 'closing_orders',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Averaging orders
        <div className="propertyContainer__thicknessColorContainer">
          <CustomDownArrowsDropDown
            onChange={(e) => {
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'under_above_marks',
                  propertyNode2: 'averaging_orders',
                  propertyNode3: 'size',
                  value: e,
                }),
              );
            }}
            activeOption={{ id: settings.under_above_marks.averaging_orders.size }}
          />
          <CustomColorPicker
            defaultColor={settings.under_above_marks.averaging_orders.color}
            onAddColor={(color) => {
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'under_above_marks',
                  propertyNode2: 'averaging_orders',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div> */}
      <div className="container__content_main_content_header">
        CAPTION TEXT
        <div className="propertyContainer__candleTypeDropDown"></div>
      </div>
      <div className="propertyContainer">
        Open Long
        <div className="propertyContainer__thicknessColorContainer">
          <CustomFontSizeDropDown
            options={numberSizes}
            activeOption={settings.caption_text.open_long.size}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.caption_text.open_long.size',
                value: { id: e, name: String(e) },
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'caption_text',
                  propertyNode2: 'open_long',
                  propertyNode3: 'size',
                  value: { id: e, name: String(e) },
                }),
              );
            }}
            header="Font size"
          />
          <CustomColorPicker
            defaultColor={settings.caption_text.open_long.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.caption_text.open_long.color',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'caption_text',
                  propertyNode2: 'open_long',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Open Short
        <div className="propertyContainer__thicknessColorContainer">
          <CustomFontSizeDropDown
            options={numberSizes}
            activeOption={settings.caption_text.open_short.size}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.caption_text.open_short.size',
                value: { id: e, name: String(e) },
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'caption_text',
                  propertyNode2: 'open_short',
                  propertyNode3: 'size',
                  value: { id: e, name: String(e) },
                }),
              );
            }}
          />
          <CustomColorPicker
            defaultColor={settings.caption_text.open_short.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.caption_text.open_short.color',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'caption_text',
                  propertyNode2: 'open_short',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        TP Levels
        <div className="propertyContainer__thicknessColorContainer">
          <CustomFontSizeDropDown
            options={numberSizes}
            activeOption={settings.caption_text.tp_levels.size}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.caption_text.tp_levels.size',
                value: { id: e, name: String(e) },
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'caption_text',
                  propertyNode2: 'tp_levels',
                  propertyNode3: 'size',
                  value: { id: e, name: String(e) },
                }),
              );
            }}
          />
          <CustomColorPicker
            defaultColor={settings.caption_text.tp_levels.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.caption_text.tp_levels.color',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'caption_text',
                  propertyNode2: 'tp_levels',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>

      <div className="propertyContainer">
        DCA Levels
        <div className="propertyContainer__thicknessColorContainer">
          <CustomFontSizeDropDown
            options={numberSizes}
            activeOption={settings.caption_text.dca_levels.size}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.caption_text.dca_levels.size',
                value: { id: e, name: String(e) },
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'caption_text',
                  propertyNode2: 'dca_levels',
                  propertyNode3: 'size',
                  value: { id: e, name: String(e) },
                }),
              );
            }}
          />
          <CustomColorPicker
            defaultColor={settings.caption_text.dca_levels.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.caption_text.dca_levels.color',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'caption_text',
                  propertyNode2: 'dca_levels',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Liquidation Levels
        <div className="propertyContainer__thicknessColorContainer">
          <CustomFontSizeDropDown
            options={numberSizes}
            activeOption={settings.caption_text.liquidation_levels.size}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.caption_text.liquidation_levels.size',
                value: { id: e, name: String(e) },
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'caption_text',
                  propertyNode2: 'liquidation_levels',
                  propertyNode3: 'size',
                  value: { id: e, name: String(e) },
                }),
              );
            }}
          />
          <CustomColorPicker
            defaultColor={settings.caption_text.liquidation_levels.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.caption_text.liquidation_levels.color',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'caption_text',
                  propertyNode2: 'liquidation_levels',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        SL Levels
        <div className="propertyContainer__thicknessColorContainer">
          <CustomFontSizeDropDown
            options={numberSizes}
            activeOption={settings.caption_text.sl_levels.size}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.caption_text.sl_levels.size',
                value: { id: e, name: String(e) },
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'caption_text',
                  propertyNode2: 'sl_levels',
                  propertyNode3: 'size',
                  value: { id: e, name: String(e) },
                }),
              );
            }}
          />
          <CustomColorPicker
            defaultColor={settings.caption_text.sl_levels.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.caption_text.sl_levels.color',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'caption_text',
                  propertyNode2: 'sl_levels',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>

      <div className="propertyContainer">
        SL Trailing Levels
        <div className="propertyContainer__thicknessColorContainer">
          <CustomFontSizeDropDown
            options={numberSizes}
            activeOption={settings.caption_text.trailing_sl_levels.size}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.caption_text.trailing_sl_levels.size',
                value: { id: e, name: String(e) },
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'caption_text',
                  propertyNode2: 'trailing_sl_levels',
                  propertyNode3: 'size',
                  value: { id: e, name: String(e) },
                }),
              );
            }}
          />
          <CustomColorPicker
            defaultColor={settings.caption_text.trailing_sl_levels.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.caption_text.trailing_sl_levels.color',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'caption_text',
                  propertyNode2: 'trailing_sl_levels',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Font Family
        <div className="propertyContainer__dropDown">
          <CustomDropDown
            largeList
            options={fontFamilyTypes}
            activeOption={{
              id: Number(
                fontFamilyTypes.findIndex((item) => item.name === settings.caption_text.fontFamily),
              ),
              name: settings.caption_text.fontFamily.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.caption_text.fontFamily',
                value: fontFamilyTypes[value - 1].name,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'caption_text',
                  propertyNode2: 'fontFamily',
                  value: fontFamilyTypes[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>

      <div className="container__content_main_content_header">
        TRADE SIZE CAPTION:
        <div className="propertyContainer__candleTypeDropDown"></div>
      </div>

      <div className="propertyContainer">
        Positive quantity(buy)
        <div className="propertyContainer__thicknessColorContainer">
          <CustomFontSizeDropDown
            options={numberSizes}
            activeOption={settings.trade_size_caption.positive_quantity.size}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.trade_size_caption.positive_quantity.size',
                value: { id: e, name: String(e) },
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'trade_size_caption',
                  propertyNode2: 'positive_quantity',
                  propertyNode3: 'size',
                  value: { id: e, name: String(e) },
                }),
              );
            }}
            header="Font size"
          />
          <CustomColorPicker
            defaultColor={settings.trade_size_caption.positive_quantity.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.trade_size_caption.positive_quantity.color',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'trade_size_caption',
                  propertyNode2: 'positive_quantity',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Negative quantity(sell)
        <div className="propertyContainer__thicknessColorContainer">
          <CustomFontSizeDropDown
            options={numberSizes}
            activeOption={settings.trade_size_caption.negative_quantity.size}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.trade_size_caption.negative_quantity.size',
                value: { id: e, name: String(e) },
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'trade_size_caption',
                  propertyNode2: 'negative_quantity',
                  propertyNode3: 'size',
                  value: { id: e, name: String(e) },
                }),
              );
            }}
          />
          <CustomColorPicker
            defaultColor={settings.trade_size_caption.negative_quantity.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.trade_size_caption.negative_quantity.color',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'trade_size_caption',
                  propertyNode2: 'negative_quantity',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="container__content_main_content_header">
        CAPTION FOR LEVEL LINES:
        <div className="propertyContainer__candleTypeDropDown"></div>
      </div>

      <div className="propertyContainer">
        TP
        <div className="propertyContainer__thicknessColorContainer">
          <CustomFontSizeDropDown
            options={numberSizes}
            activeOption={settings.level_lines_caption.tp.size}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.level_lines_caption.tp.size',
                value: { id: e, name: String(e) },
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'level_lines_caption',
                  propertyNode2: 'tp',
                  propertyNode3: 'size',
                  value: { id: e, name: String(e) },
                }),
              );
            }}
            header="Font size"
          />
          <CustomColorPicker
            defaultColor={settings.level_lines_caption.tp.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.level_lines_caption.tp.color',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'level_lines_caption',
                  propertyNode2: 'tp',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        SL
        <div className="propertyContainer__thicknessColorContainer">
          <CustomFontSizeDropDown
            options={numberSizes}
            activeOption={settings.level_lines_caption.sl.size}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.level_lines_caption.sl.size',
                value: { id: e, name: String(e) },
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'level_lines_caption',
                  propertyNode2: 'sl',
                  propertyNode3: 'size',
                  value: { id: e, name: String(e) },
                }),
              );
            }}
          />
          <CustomColorPicker
            defaultColor={settings.level_lines_caption.sl.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.level_lines_caption.sl.color',
                value: color,
              });

              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'level_lines_caption',
                  propertyNode2: 'sl',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>

      <div className="propertyContainer">
        Sl Trailing
        <div className="propertyContainer__thicknessColorContainer">
          <CustomFontSizeDropDown
            options={numberSizes}
            activeOption={settings.level_lines_caption.trailing_sl.size}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.level_lines_caption.trailing_sl.size',
                value: { id: e, name: String(e) },
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'level_lines_caption',
                  propertyNode2: 'trailing_sl',
                  propertyNode3: 'size',
                  value: { id: e, name: String(e) },
                }),
              );
            }}
          />
          <CustomColorPicker
            defaultColor={settings.level_lines_caption.trailing_sl.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.level_lines_caption.trailing_sl.color',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'level_lines_caption',
                  propertyNode2: 'trailing_sl',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Averages(DCA)
        <div className="propertyContainer__thicknessColorContainer">
          <CustomFontSizeDropDown
            options={numberSizes}
            activeOption={settings.level_lines_caption.averages.size}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.level_lines_caption.averages.size',
                value: { id: e, name: String(e) },
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'level_lines_caption',
                  propertyNode2: 'averages',
                  propertyNode3: 'size',
                  value: { id: e, name: String(e) },
                }),
              );
            }}
          />
          <CustomColorPicker
            defaultColor={settings.level_lines_caption.averages.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.level_lines_caption.averages.color',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'level_lines_caption',
                  propertyNode2: 'averages',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Liquidation
        <div className="propertyContainer__thicknessColorContainer">
          <CustomFontSizeDropDown
            options={numberSizes}
            activeOption={settings.level_lines_caption.liquidation.size}
            onChange={(e) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.level_lines_caption.liquidation.size',
                value: { id: e, name: String(e) },
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'level_lines_caption',
                  propertyNode2: 'liquidation',
                  propertyNode3: 'size',
                  value: { id: e, name: String(e) },
                }),
              );
            }}
          />
          <CustomColorPicker
            defaultColor={settings.level_lines_caption.liquidation.color}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'trades.level_lines_caption.liquidation.color',
                value: color,
              });
              dispatch(
                updateTradeSettingsProperty({
                  propertyNode1: 'level_lines_caption',
                  propertyNode2: 'liquidation',
                  propertyNode3: 'color',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="container__content_main_content_header">
        OTHER SETTINGS
        <div className="propertyContainer__candleTypeDropDown"></div>
      </div>

      <div className="propertyContainer">
        Trade table visibility
        <GTumbler
          checked={settings.tradeStatisticsVisible}
          size={isMobileVersion ? 'large' : null}
          name="candle.priceMark.high"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'trades.tradeStatisticsVisible',
              value,
            });
            dispatch(
              updateTradeSettingsProperty({
                propertyNode1: 'tradeStatisticsVisible',
                value,
              }),
            );
          }}
        />
      </div>

      <button
        className="container__content_main_content_refresh"
        onClick={() => {
          apiChartStyles.refreshChartStylingSettings('trades');
          dispatch(refreshTradeSettings());
        }}
      >
        Refresh settings
      </button>
    </div>
  );
};

export default TradeSettings;
