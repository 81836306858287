import React, { type FC, useEffect, type Dispatch, type RefObject } from 'react';
import { connect } from 'react-redux';
import { type AnyAction } from '@reduxjs/toolkit';
import { setMobileContent } from 'redux/homePage/reducer';
import type { MobileContentPages } from 'redux/homePage/types';
import { useSelector, type IRootState } from '../../redux/rootReducer';
import { selectCustomTickers } from '../../redux/customTicker/reducer';
import { type IStrategyState } from '../../redux/strategy/types';
import { selectTheme, toggleTheme } from '../../redux/theme/reducer';
import { type IChartDataState, type IChartState } from '../../redux/chart/types';
import { DefaultHomePageState, type IHomePageState as IState } from './DefaultState';
import { HistorySectionModes } from '../../enums/History';
import { type ITradeData } from '../../interfaces/ITrade';
import { webSocketService } from '../../services/WebSocketService';
import { type IStrategyTickerData } from '../../services/api/Strategy/types/StrategyResponse';
import {
  type ImperativePanelGroupHandle,
  type ImperativePanelHandle,
  Panel,
  PanelGroup,
} from 'react-resizable-panels';
import { ResizeHandle } from '../../components/ResizeHandle';
import { History } from './components/History/History';
import StrategyEditor from './components/StrategyEditor/StrategyEditor';
import Header from './components/Header';
import { StrategyMetrics } from './components/StrategyMetrics/StrategyMetrics';
import { TradesList } from './components/TradesList';
import { StrategyTrades } from './components/StrategyMetrics/StrategyTrades';
import { HomePageMobileTab } from './components/HomePageMobileTab/HomePageMobileTab';
import { MobileContentSwitcherMenu } from './components/MobileContentSwitcherMenu/MobileContentSwitcherMenu';
import { Charts } from './components/ChartContainer/ChartMainContainer';
import { LoaderOverlay } from 'components/LoaderOverlay';
import ChartWrapperHeader from './components/Chart/components/ChartWrapper/components/ChartWrapperHeader';
import styles from './HomePageStyles.module.css';
import classNames from 'classnames';
import { Info } from './components/Log/components/Info';

interface IProps {
  toggleTheme?: () => void;
  customTickers?: IStrategyTickerData[];
  chart?: IChartState;
  mobileContent?: MobileContentPages;
  setChartLoading?: (value: boolean) => void;
  updateChart?: (id: string, data: Partial<IChartDataState>) => void;
  setBackTest?: (value: boolean) => void;
  setSelectedChart?: (id: string) => void;
  setMergedStrategyData?: (value: IStrategyState['mergedStrategyData']) => void;
  setReduxMobileContent?: (value: MobileContentPages) => void;
  strategy?: IStrategyState;
}

class HomePage extends React.Component<IProps, IState> {
  public chartContainerRef: RefObject<ImperativePanelHandle>;
  public historyContainerRef: RefObject<ImperativePanelHandle>;
  public groupPanelSection: RefObject<ImperativePanelGroupHandle>;
  public chartPanelsSection: RefObject<ImperativePanelGroupHandle>;
  public chartPanelInnerSection: RefObject<ImperativePanelGroupHandle>;
  public state: IState = DefaultHomePageState;
  public theme: { primaryColor: string; secondaryColor: string };
  private historyRo: ResizeObserver | null = null;
  public isConstructorOpen = true;

  constructor(props: IProps) {
    super(props);
    this.chartContainerRef = React.createRef();
    this.historyContainerRef = React.createRef();
    this.groupPanelSection = React.createRef<ImperativePanelGroupHandle>();
    this.chartPanelsSection = React.createRef<ImperativePanelGroupHandle>();
    this.chartPanelInnerSection = React.createRef<ImperativePanelGroupHandle>();
  }

  public componentDidMount(): void {
    const historyContainer = document.querySelector('#history_container');

    if (historyContainer) {
      this.historyRo = new ResizeObserver((entries) => {
        const entry = entries[0];

        if (entry.target === historyContainer) {
          setTimeout(() => {
            this.setState({
              historyContainerSize: {
                width: historyContainer.clientWidth,
                height: historyContainer.clientHeight,
              },
            });
          }, 0);
        }
      });

      this.historyRo.observe(historyContainer);
    }

    if (!webSocketService.active) {
      webSocketService.open();
    }
  }

  public componentWillUnmount(): void {
    webSocketService.close();
  }

  public changeMobileContent = (
    showTerminalValue: 'chart' | 'home' | 'metrics' | 'constructor' | 'trades',
  ): void => {
    this.props.setReduxMobileContent(showTerminalValue);
  };

  public setHistorySectionMode = (mode: HistorySectionModes | null): void => {
    if (mode === HistorySectionModes.FULL_SCREEN) {
      this.groupPanelSection.current.setLayout([0, 100]);
    }
    if (mode === HistorySectionModes.COLLAPSE) {
      this.groupPanelSection.current.setLayout([95, 5]);
    }
    if (!mode) {
      this.groupPanelSection.current.setLayout([75, 25]);
    }

    this.setState({ historySectionMode: mode });
  };

  public collapseConstructor = (): void => {
    this.groupPanelSection.current.setLayout([100, 0]);
    this.isConstructorOpen = false;
  };

  public expandConstructor = (): void => {
    this.isConstructorOpen = true;
    this.groupPanelSection.current.setLayout([75, 25]);
  };

  public getPrecisions = (trade: ITradeData): [price: number, qty: number] => {
    return [
      (`${trade.average_enter}`.split('.')[1] ?? '').length,
      (`${trade.qty}`.split('.')[1] ?? '').length,
    ];
  };

  public Hook: FC = () => {
    const isFullBackTest = useSelector((state) => state.strategy.isFullBackTest);
    // useBackTestActivator();

    useEffect(() => {
      if (!isFullBackTest && this.props.strategy.selectedStrategyId) {
        this?.chartPanelInnerSection?.current?.setLayout([50, 50]);
      }
    }, [isFullBackTest]);
    return <></>;
  };

  public render(): React.ReactNode {
    const showMobileVersion = window.innerWidth < 768;
    const contentStateValue: MobileContentPages = this.props.mobileContent;
    const { theme } = this.state;
    // add interface
    const props: any = this.props;
    // add interface
    // const selectedChart: any = props?.selectedChart;
    return (
      <div style={{ backgroundColor: theme.homeBackground }} className={styles.root}>
        <this.Hook />
        {!showMobileVersion && (
          <>
            <nav className={styles.navigation}>
              <Header></Header>
            </nav>
            <PanelGroup direction={`${showMobileVersion ? 'vertical' : 'horizontal'}`}>
              <Panel className={styles.rowContainer} defaultSize={85}>
                <PanelGroup direction="vertical">
                  <PanelGroup direction="horizontal" ref={this.groupPanelSection}>
                    <Panel
                      style={{ display: 'flex', flexDirection: 'column' }}
                      className={`${styles.chartWrapper}  ${
                        props.fullScreenMode && styles.chartWrapperFullScreen
                      } `}
                      defaultSize={75}
                    >
                      <div>
                        {' '}
                        <ChartWrapperHeader
                          expandConstructor={this.expandConstructor}
                          isConstructorOpen={this.isConstructorOpen}
                        />
                      </div>
                      <PanelGroup direction="horizontal">
                        {!props.fullScreenMode && props.isFullBackTest && (
                          <>
                            <Panel
                              style={{
                                maxWidth:
                                  props.showStatistics && props.isStatisticsData ? '390px' : '0px',
                                minWidth:
                                  props.showStatistics && props.isStatisticsData ? '390px' : '0px',
                                marginRight:
                                  props.showStatistics && props.isStatisticsData ? '10px' : '0px',
                              }}
                            >
                              <StrategyMetrics showLoader={false} />
                            </Panel>
                          </>
                        )}
                        <Panel>
                          {' '}
                          <PanelGroup
                            ref={this.chartPanelInnerSection}
                            direction="vertical"
                            // style={{ border: '3px solid green' }}
                          >
                            <Panel
                              collapsible={true}
                              className={classNames(styles.rowContainer, 'chart_container')}
                              ref={this.chartContainerRef}
                              style={{ background: !props.isFullBackTest && 'rgba(19, 14, 45, 1)' }}
                              defaultSize={65}
                              minSize={30}
                              id="main"
                            >
                              {!props.strategy.selectedStrategyId ||
                              (props.strategy.selectedStrategyId && props.isFullBackTest) ? (
                                <Charts
                                  setState={(e) => {
                                    this.setState({ previousChartsPosition: e });
                                  }}
                                  expandConstructor={this.expandConstructor}
                                  isConstructorOpen={this.isConstructorOpen}
                                  setHistoryContainerSize={(e) => {
                                    this.setState({ historyContainerSize: e });
                                  }}
                                />
                              ) : (
                                <PanelGroup direction="horizontal">
                                  <Panel
                                    style={{
                                      maxWidth:
                                        props.showStatistics && props.isStatisticsData
                                          ? '390px'
                                          : '0px',
                                      minWidth:
                                        props.showStatistics && props.isStatisticsData
                                          ? '390px'
                                          : '0px',
                                      marginRight:
                                        props.showStatistics && props.isStatisticsData
                                          ? '10px'
                                          : '0px',
                                    }}
                                  >
                                    <StrategyMetrics showLoader={false} />
                                  </Panel>
                                  <Panel>
                                    <Info showLoader={true} />
                                  </Panel>
                                </PanelGroup>
                              )}
                            </Panel>
                            {!props.fullScreenMode && (
                              <>
                                <ResizeHandle className={styles.resizeHandleHorizontal} />
                                <Panel
                                  collapsible
                                  defaultSize={35}
                                  maxSize={100}
                                  minSize={10}
                                  ref={this.historyContainerRef}
                                >
                                  <History
                                    historyMode={this.state.historySectionMode}
                                    setHistorySectionMode={this.setHistorySectionMode}
                                    containerSize={this.state.historyContainerSize}
                                    isConstructorOpen={this.isConstructorOpen}
                                  />
                                </Panel>
                              </>
                            )}
                          </PanelGroup>
                        </Panel>
                      </PanelGroup>
                    </Panel>
                    <ResizeHandle
                      className={classNames(styles.resizeHandleVertical, {
                        invisible: this.state.historySectionMode,
                      })}
                    />
                    <Panel
                      className={styles.rowContainer}
                      maxSize={window.innerWidth > 1900 ? 25 : 35}
                      minSize={20}
                      collapsible={true}
                      defaultSize={25}
                    >
                      <div className={classNames(styles.content)}>
                        <StrategyEditor
                          setStrategyEnabled={(value) => {
                            this.setState({ strategyOn: value as boolean });
                          }}
                          strategyEnabled={this.state.strategyOn}
                          collapseConstructor={this.collapseConstructor}
                        />
                      </div>
                    </Panel>
                  </PanelGroup>
                </PanelGroup>
              </Panel>
            </PanelGroup>
          </>
        )}
        {showMobileVersion && (
          <div className={styles.mobileContainer}>
            <div
              className={`${styles.chart_Mobcontainer} ${
                props.fullScreenMode ? styles.chart_Mobcontainer_full : ''
              }`}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: contentStateValue !== 'chart' && '0%',
                overflow: contentStateValue !== 'chart' ? 'hidden' : '',
              }}
            >
              <div style={{ backgroundColor: 'red' }}>
                {' '}
                <ChartWrapperHeader
                  expandConstructor={this.expandConstructor}
                  isConstructorOpen={this.isConstructorOpen}
                />
              </div>
              <PanelGroup direction="horizontal">
                <Panel
                  collapsible={true}
                  className={classNames(styles.rowContainer, 'chart_container')}
                  ref={this.chartContainerRef}
                  defaultSize={100}
                  minSize={100}
                  id="main"
                >
                  <Charts
                    setState={(e) => {
                      this.setState({ previousChartsPosition: e });
                    }}
                    setHistoryContainerSize={(e) => {
                      this.setState({ historyContainerSize: e });
                    }}
                  />
                </Panel>
              </PanelGroup>
            </div>
            <div
              style={{
                height: contentStateValue !== 'home' && '0%',
                paddingTop: contentStateValue !== 'home' && '0px',
              }}
              className={classNames(styles.constructorContent, styles.mobileHomePage)}
            >
              <HomePageMobileTab />
            </div>
            <div
              style={{ height: contentStateValue !== 'constructor' && '0%' }}
              className={classNames(styles.constructorContent)}
            >
              <StrategyEditor
                setStrategyEnabled={(value) => {
                  this.setState({ strategyOn: value as boolean });
                }}
                strategyEnabled={this.state.strategyOn}
              />
            </div>
            <div
              style={{
                height: contentStateValue !== 'metrics' && '0%',
                overflow: window.innerWidth < 768 && 'scroll',
              }}
              className={classNames(styles.constructorContent)}
            >
              <div className={styles.chartInfoSelectorTicker}>
                {this.props.strategy?.selectedStrategy?.name}
              </div>
              <History
                historyMode={this.state.historySectionMode}
                setHistorySectionMode={this.setHistorySectionMode}
                containerSize={this.state.historyContainerSize}
                isConstructorOpen={this.isConstructorOpen}
              />
              <StrategyMetrics showLoader={false} />
            </div>
            <div
              style={{
                height: contentStateValue !== 'trades' && '0%',
                overflow: contentStateValue !== 'trades' && 'hidden',
              }}
              className={classNames(styles.constructorContent)}
            >
              <LoaderOverlay isShow={props.isChartLoading} position={'absolute'} imageWidth={0} />
              <div className={styles.constructorContentHeader}>Trades list</div>
              <TradesList />
              <StrategyTrades />
            </div>
            <MobileContentSwitcherMenu />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState): any => ({
  theme: selectTheme(state),
  customTickers: selectCustomTickers(state),
  strategy: state.strategy,
  selectedChart: state.chart.selectedChart,
  isChartLoading: state.chart.isLoading,
  fullScreenMode: state.chart.fullScreenMode,
  mobileContent: state.homePage.mobileContent,
  showStatistics: state.chart.showStatistics,
  isStatisticsData: state.chart.isShowStatisticsData,
  isFullBackTest: state.strategy.isFullBackTest,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): unknown => ({
  toggleTheme: () => {
    dispatch(toggleTheme());
  },
  setReduxMobileContent: (value: string) => {
    dispatch(setMobileContent(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
