import { type Dispatch, type FC, useEffect, useState } from 'react';
import { useSelector } from 'redux/rootReducer';
import { useDispatch } from 'react-redux';
import {
  deleteSavedtrategy,
  setBackTest,
  setMergedStrategyData,
  updateSelectedStrategy,
} from 'redux/strategy/reducer';
import type { ICustomTickerElement } from 'redux/customTicker/types';
import {
  getNewChartData,
  setAllTrades,
  setChartDateConfig,
  setCharts,
  setSelectedChart,
  useChartSelector,
} from 'redux/chart/reducer';
import { type AnyAction } from '@reduxjs/toolkit';

import StrategyOtherFilters from '../StrategyOtherFilters/StrategyOtherFilters';
import StrategyAdditionalSettings from '../StrategyAdditional/StrategyAdditional';
import { StrategyName } from '../StrategyName/StrategyName';
import { StrategySwitcher } from '../StrategySwitcher/StrategySwitcher';
import { StrategyOtherFiltersSetting } from '../StrategyOtherFiltersSettings/StrategyOtherFilterSettings';
import { StrategySettings } from '../StrategySettings/StrategySettings';
import { StrategyConstructor } from './StrategyConstructor';
import { StrategyProperties } from './StrategyProperties';
import { StrategyTypeSettings } from '../StrategyTypeSettings/StrategyTypeSettings';
import {
  type IHedgeStrategy,
  IndicatorsList,
  IndicatorSource,
  type ISettingsIndicator,
  type IStrategy,
  OrderTypes,
  StrategyModes,
  type SavedIStrategy,
  type SavedIHedgeStrategy,
} from 'interfaces/IStrategy';
import { apiStrategy } from 'services/api/Strategy/ApiStrategy';
import { type IStrategyTickerData } from 'services/api/Strategy/types/StrategyResponse';

import { StrategyType } from 'enums/Strategy';
import { COLORS } from 'configs/chart';
import { STRATEGY_SETTINGS } from 'configs/strategy';

import {
  compareStrategyHeaderData,
  isHedgeModeStrategy,
  isOneWayModeStrategy,
} from 'utils/strategy';
import './Strategy.scss';
import TgBotIcon from 'assets/icons/IconTgBot.svg';
import TgBotSettings from '../TgBot/TgBotSettings/TgBotSettings';
// import BackTestTypeDropDown from 'components/BackTestTypeDropDown/BackTestTypeDropDown';

interface IProps {
  updateHistoryName: (id: string, name: string) => void;
  isChartLoading: boolean;
  strategyNames: string[];
}

const constructorPages = [
  {
    name: 'Constructor',
    id: 0,
  },
  {
    name: 'Properties',
    id: 1,
  },
];

export const updateChartsForStrategy = (
  dispatch: Dispatch<AnyAction>,
  customTickers: IStrategyTickerData[],
  strategy: IStrategy | IHedgeStrategy,
  isFullBackTest: boolean,
  isMerge: boolean,
  chartShortTicker?:
    | {
        type: 'default';
        baseAsset: string;
        quoteAsset: string;
      }
    | {
        type: 'custom';
        _id: string;
        name: string;
        tickers: ICustomTickerElement[];
      },
  chartLongTicker?:
    | {
        type: 'default';
        baseAsset: string;
        quoteAsset: string;
      }
    | {
        type: 'custom';
        _id: string;
        name: string;
        tickers: ICustomTickerElement[];
      },
  savedStrategies?: (SavedIStrategy | SavedIHedgeStrategy)[],
): void => {
  if (strategy) {
    const savedStrategy = savedStrategies?.find((e) => e._id === strategy._id);
    const hedgeTrades =
      isHedgeModeStrategy(savedStrategy) &&
      [...savedStrategy.shortTrades, ...savedStrategy.longTrades].sort((a, b) => a.id - b.id);
    if (!isMerge)
      if (strategy.mode === StrategyModes.HEDGE) {
        savedStrategy && dispatch(setAllTrades(hedgeTrades));
      } else {
        dispatch(setAllTrades(isOneWayModeStrategy(savedStrategy) ? savedStrategy?.trades : []));
      }
    if (strategy.ticker.id) {
      const customTicker = customTickers.find((e) => e._id === strategy.ticker.id);
      if (customTicker) {
        if (strategy.mode === StrategyModes.HEDGE) {
          if (
            chartShortTicker.type === 'default' ||
            chartLongTicker.type === 'default' ||
            (chartShortTicker.type === 'custom' && customTicker._id !== chartLongTicker?._id)
          ) {
            dispatch(
              setCharts([
                getNewChartData({
                  id: '0',
                  strategyId: strategy._id,
                  ticker: {
                    type: 'custom',
                    _id: customTicker._id,
                    name: customTicker.name,
                    tickers: customTicker.tickers,
                  },
                  timeframe: strategy.strategies.short.timeframe,
                  exchange: strategy.exchange,
                  chartType: 'SHORT',
                }),
                getNewChartData({
                  id: '1',
                  strategyId: strategy._id,
                  ticker: {
                    type: 'custom',
                    _id: customTicker._id,
                    name: customTicker.name,
                    tickers: customTicker.tickers,
                  },
                  timeframe: strategy.strategies.long.timeframe,
                  exchange: strategy.exchange,
                  chartType: 'LONG',
                }),
              ]),
            );
          }
        } else {
          dispatch(
            setCharts([
              getNewChartData({
                id: '0',
                strategyId: strategy._id,
                timeframe: strategy.timeframe,
                ticker: {
                  type: 'custom',
                  _id: customTicker._id,
                  name: customTicker.name,
                  tickers: customTicker.tickers,
                },
                exchange: strategy.exchange,
              }),
            ]),
          );
        }
      } else {
        // updateStrategy({
        //   timeframe: 900000,
        //   exchange: Exchanges.BinanceFutures,
        //   ticker: {
        //     base_asset: 'BTC',
        //     quote_asset: 'USDT',
        //   },
        // });
        // dispatch(setBackTest(true));
      }
    } else {
      if (strategy.mode === StrategyModes.HEDGE) {
        savedStrategy && dispatch(setAllTrades(hedgeTrades));
        // if (!isFullBackTest) {
        //   dispatch(
        //     setCharts([
        //       getNewChartData({
        //         id: '0',
        //         strategyId: strategy._id,
        //         ticker: {
        //           type: 'default',
        //           baseAsset: strategy.ticker.base_asset,
        //           quoteAsset: strategy.ticker.quote_asset,
        //         },
        //         timeframe: strategy.strategies.short.timeframe,
        //         exchange: strategy.exchange,
        //         chartType: 'SHORT',
        //         trades: isHedgeModeStrategy(savedStrategy) ? savedStrategy?.shortTrades : [],
        //       }),
        //     ]),
        //   );
        //   return;
        // }

        dispatch(
          setCharts([
            getNewChartData({
              id: '0',
              strategyId: strategy._id,
              ticker: {
                type: 'default',
                baseAsset: strategy.ticker.base_asset,
                quoteAsset: strategy.ticker.quote_asset,
              },
              timeframe: strategy.strategies.short.timeframe,
              exchange: strategy.exchange,
              chartType: 'SHORT',
              trades: isHedgeModeStrategy(savedStrategy) ? savedStrategy?.shortTrades : [],
            }),
            getNewChartData({
              id: '1',
              strategyId: strategy._id,
              ticker: {
                type: 'default',
                baseAsset: strategy.ticker.base_asset,
                quoteAsset: strategy.ticker.quote_asset,
              },
              timeframe: strategy.strategies.long.timeframe,
              exchange: strategy.exchange,
              chartType: 'LONG',
              trades: isHedgeModeStrategy(savedStrategy) ? savedStrategy?.longTrades : [],
            }),
          ]),
        );
      } else {
        dispatch(
          setCharts([
            getNewChartData({
              id: '0',
              strategyId: strategy._id,
              ticker: {
                type: 'default',
                baseAsset: strategy.ticker.base_asset,
                quoteAsset: strategy.ticker.quote_asset,
              },
              exchange: strategy.exchange,
              timeframe: strategy.timeframe,
              trades: isOneWayModeStrategy(savedStrategy) ? savedStrategy?.trades : [],
            }),
          ]),
        );
      }
    }
  }
};

const strategiesPool: Record<string, IStrategy | IHedgeStrategy> = {};
let oldStrategy: (IStrategy | IHedgeStrategy) | null = null;
let oldChartId: string | null = null;

export const Strategy: FC<IProps> = ({ updateHistoryName, isChartLoading, strategyNames }) => {
  const {
    selectedStrategy: strategy,
    mergedStrategyData,
    savedStrategies,
    isFullBackTest,
    selectedMergedStrategy,
  } = useSelector((state) => state.strategy);
  const strategyId = strategy?._id;
  const [activePage, setActivePage] = useState<number>(0);
  const [strategyType, setStrategyType] = useState<StrategyType>(StrategyType.FixedRisk);
  const [strategyTypeLong, setStrategyTypeLong] = useState<StrategyType>(StrategyType.FixedRisk);
  const [strategyTypeShort, setStrategyTypeShort] = useState<StrategyType>(StrategyType.FixedRisk);
  const [isStrategySettings, setStrategySettings] = useState<[number, number] | null>(null);
  const [isOtherFilters, setIsOtherFilters] = useState(false);
  const [isTgBotSettings, setIsTgBotSettings] = useState(false);
  const [isOpenedShortSettingsTab, setOpenedShortSettingsTab] = useState<[number, number] | null>(
    null,
  );
  const [isOpenedLongSettingsTab, setOpenedLongSettingsTab] = useState<[number, number] | null>(
    null,
  );
  const [openedShortTabCondition, setOpenedShortTabCondition] = useState<number | null>(null);
  const [openedLongTabCondition, setOpenedLongTabCondition] = useState<number>(0);
  const [isStrategyTypeSettings, setStrategyTypeSettings] = useState<boolean>(false);
  const [isStrategyTypeSettingsLong, setStrategyTypeSettingsLong] = useState<boolean>(false);
  const [isStrategyTypeSettingsShort, setStrategyTypeSettingsShort] = useState<boolean>(false);
  const [isBackTestDropDown] = useState<boolean>(false);
  const [strategyName, setStrategyName] = useState<string>(strategy?.name);
  const [isStrategyUpdated, setStrategyIsUpdated] = useState(0);
  const { createdTickers: customTickers } = useSelector((e) => e.customTickers);

  const selectedChartId = useSelector((state) => state.chart.selectedChart);

  const [
    {
      id: chartShortID = null,
      timeframe: chartShortTimeframe = null,
      ticker: chartShortTicker = null,
    } = {},
    {
      id: chartLongID = null,
      timeframe: chartLongTimeframe = null,
      ticker: chartLongTicker = null,
    } = {},
  ] = useSelector((state) => state.chart.charts || []);

  // const chartShortID = useSelector((state) => state.chart.charts[0]?.id);
  // const chartLongID = useSelector((state) => state.chart.charts[1]?.id);
  // const chartShortTimeframe = useSelector((state) => state.chart.charts[0]?.timeframe);
  // const chartLongTimeframe = useSelector((state) => state.chart.charts[1]?.timeframe);
  // const chartShortTicker = useSelector((state) => state.chart.charts[0]?.ticker);
  // const chartLongTicker = useSelector((state) => state.chart.charts[1]?.ticker);

  if (!oldChartId) oldChartId = selectedChartId;
  const selectedChartData = useChartSelector(selectedChartId);
  const openedChart = selectedChartData.chartType?.toLowerCase() ?? null;
  const errorName =
    strategyNames.findIndex(
      (name) => name.toLowerCase().trim() === strategyName?.toLowerCase().trim(),
    ) !== -1;
  const dispatch = useDispatch();
  const updateStrategy = (data: Partial<IStrategy | IHedgeStrategy>): void => {
    dispatch(updateSelectedStrategy(data));
    setStrategyIsUpdated(isStrategyUpdated + 1);
  };

  useEffect(() => {
    if (isStrategyUpdated % 2 !== 0) {
      dispatch(setBackTest(true));
      setStrategyIsUpdated(isStrategyUpdated + 1);
    }
  }, [isStrategyUpdated]);
  useEffect(() => {
    updateChartsForStrategy(
      dispatch,
      customTickers,
      strategy,
      isFullBackTest,
      !!selectedMergedStrategy?._id,
      chartShortTicker,
      chartLongTicker,
      savedStrategies,
    );
  }, [strategy?.ticker]);
  useEffect(() => {
    setStrategyTypeSettings(false);
    setStrategyTypeSettingsLong(false);
    setStrategyTypeSettingsShort(false);
    setStrategySettings(null);

    if (oldChartId !== selectedChartId) {
      oldChartId = selectedChartId;
      return;
    }
    if (strategy && compareStrategyHeaderData(strategy, selectedChartData, selectedChartId)) {
      if (selectedChartData.ticker.type === 'custom') {
        if (strategy.mode === StrategyModes.HEDGE) {
          updateStrategy({
            strategies: {
              ...strategy.strategies,
              long: {
                ...strategy.strategies.long,
                timeframe: chartShortTimeframe,
              },
              short: {
                ...strategy.strategies.short,
                timeframe: chartLongTimeframe,
              },
            },
            exchange: strategy.exchange,
            ticker: {
              id: selectedChartData.ticker._id,
              tickers: selectedChartData.ticker.tickers.map((e) => ({
                base_asset: e.baseAsset,
                quote_asset: e.quoteAsset,
                percent: e.percent,
              })),
            },
          });
          dispatch(setBackTest(true));
        } else {
          updateStrategy({
            timeframe: selectedChartData.timeframe,
            exchange: strategy.exchange,
            ticker: {
              id: selectedChartData.ticker._id,
              tickers: selectedChartData.ticker.tickers.map((e) => ({
                base_asset: e.baseAsset,
                quote_asset: e.quoteAsset,
                percent: e.percent,
              })),
            },
          });
          dispatch(setBackTest(true));
        }
      } else {
        if (strategy.mode === StrategyModes.HEDGE) {
          updateStrategy({
            strategies: {
              ...strategy.strategies,
              short: { ...strategy.strategies.short, timeframe: chartShortTimeframe },
              long: { ...strategy.strategies.long, timeframe: chartLongTimeframe },
            },
            exchange: selectedChartData.exchange,
            ticker: {
              base_asset: selectedChartData.ticker.baseAsset,
              quote_asset: selectedChartData.ticker.quoteAsset,
            },
          });
          dispatch(setBackTest(true));
        } else {
          console.log(selectedChartData.ticker);
          updateStrategy({
            timeframe: selectedChartData.timeframe,
            exchange: selectedChartData.exchange,
            ticker: {
              base_asset: selectedChartData.ticker.baseAsset,
              quote_asset: selectedChartData.ticker.quoteAsset,
            },
          });
          dispatch(setBackTest(true));
        }
      }
    }
  }, [
    selectedChartId,
    selectedChartData.ticker.type,
    selectedChartData.timeframe,
    selectedChartData.ticker.type === 'custom'
      ? selectedChartData.ticker.tickers
      : selectedChartData.ticker.baseAsset,
  ]);
  useEffect(() => {
    setStrategySettings(null);
    setStrategyTypeSettings(false);
    setStrategyTypeSettingsLong(false);
    setStrategyTypeSettingsShort(false);
    setActivePage(0);

    if (!strategyId) return;
    const existedStrategy = strategiesPool[strategyId];
    if (existedStrategy) {
      if (existedStrategy.mode === StrategyModes.ONE_SIDE) {
        if (existedStrategy.settings?.average) {
          setStrategyType(StrategyType.DCA);
        } else {
          setStrategyType(StrategyType.FixedRisk);
        }
      } else {
        if (existedStrategy.strategies.long.settings.average) {
          setStrategyTypeLong(StrategyType.DCA);
        } else {
          setStrategyTypeLong(StrategyType.FixedRisk);
        }
        if (existedStrategy.strategies.short.settings.average) {
          setStrategyTypeShort(StrategyType.DCA);
        } else {
          setStrategyTypeShort(StrategyType.FixedRisk);
        }
      }
      updateStrategy(existedStrategy);
      return;
    }

    apiStrategy.getStrategy(strategyId).then((result) => {
      strategiesPool[strategyId] = result;
      // поглянути
      if (result) {
        if (result.mode === StrategyModes.ONE_SIDE) {
          if (result.settings.average) {
            setStrategyType(StrategyType.DCA);
          } else {
            setStrategyType(StrategyType.FixedRisk);
          }
        } else {
          if (result.strategies.long.settings.average) {
            setStrategyTypeLong(StrategyType.DCA);
          } else {
            setStrategyTypeLong(StrategyType.FixedRisk);
          }
          // setting type for short chart
          if (result.strategies.short.settings.average) {
            setStrategyTypeShort(StrategyType.DCA);
          } else {
            setStrategyTypeShort(StrategyType.FixedRisk);
          }
        }
      }

      updateStrategy(result);
    });
  }, [strategyId]);

  useEffect(() => {
    if (!strategy) return;
    if (strategy.mode === StrategyModes.ONE_SIDE) {
      const settings = { ...strategy.settings };

      switch (strategyType) {
        case StrategyType.FixedRisk: {
          delete settings.average;
          if (!('stop_loss' in settings)) {
            settings.stop_loss = 1.0;
          }
          break;
        }
        case StrategyType.DCA: {
          delete settings.stop_loss;
          if (!settings.average) {
            settings.average = {
              orders_type: OrderTypes.Market,
              orders: [[1, 1.5]],
            };
          }
        }
      }

      updateStrategy({ ...strategy, settings });
    } else {
      const settingsLong = { ...strategy.strategies.long.settings };
      const settingsShort = { ...strategy.strategies.short.settings };

      switch (strategyTypeLong) {
        case StrategyType.FixedRisk: {
          delete settingsLong.average;
          if (!('stop_loss' in settingsLong)) {
            settingsLong.stop_loss = 1.0;
          }
          break;
        }
        case StrategyType.DCA: {
          delete settingsLong.stop_loss;
          if (!settingsLong.average) {
            settingsLong.average = {
              orders_type: OrderTypes.Market,
              orders: [[1, 1.5]],
            };
          }
        }
      }
      switch (strategyTypeShort) {
        case StrategyType.FixedRisk: {
          delete settingsShort.average;
          if (!('stop_loss' in settingsShort)) {
            settingsShort.stop_loss = 1.0;
          }
          break;
        }
        case StrategyType.DCA: {
          delete settingsShort.stop_loss;
          if (!settingsShort.average) {
            settingsShort.average = {
              orders_type: OrderTypes.Market,
              orders: [[1, 1.5]],
            };
          }
        }
      }
      updateStrategy({
        strategies: {
          ...strategy.strategies,
          long: { ...strategy.strategies.long, settings: settingsLong },
          short: { ...strategy.strategies.short, settings: settingsShort },
        },
      });
    }
  }, [strategyType, strategyTypeLong, strategyTypeShort]);

  useEffect(() => {
    if (oldStrategy?._id) {
      if (oldStrategy._id !== strategy?._id) {
        dispatch(setChartDateConfig({ dateFrom: null, dateTo: null }));
      }
    }

    if (strategy) {
      setStrategyName(strategy.name);
      if (strategiesPool[strategy._id] && strategiesPool[strategy._id].name !== strategy.name) {
        updateHistoryName(strategy._id, strategy.name);
        dispatch(updateSelectedStrategy({ name: strategyName }));
      }

      strategiesPool[strategy._id] = strategy;

      if (oldStrategy?._id !== strategy._id) {
        dispatch(setBackTest(true));
      }
    }

    oldStrategy = strategy;
  }, [strategy]);

  useEffect(() => {
    if (strategy) {
      dispatch(updateSelectedStrategy({ name: strategyName }));
    }
  }, [strategyName]);
  useEffect(() => {
    if (openedChart === 'long' && isOpenedLongSettingsTab) {
      setStrategySettings(() => isOpenedLongSettingsTab);
    }
    if (openedChart === 'short' && isOpenedShortSettingsTab) {
      setStrategySettings(() => isOpenedShortSettingsTab);
    }
  }, [openedChart, isStrategySettings]);

  const saveStrategy = (): void => {
    if (strategyName !== strategy.name) {
      updateStrategy({ name: strategyName });
    }
    dispatch(deleteSavedtrategy(strategy._id));
    apiStrategy
      .saveStrategy({ ...strategy, name: strategyName })
      .then(() => {
        dispatch(setBackTest(true));
      })
      .catch(() => {});
  };
  const saveStrategyName = (): void => {
    apiStrategy.getStrategy(strategyId).then((result) => {
      if (result) {
        const resultStrategy = result;
        if (resultStrategy.mode === StrategyModes.ONE_SIDE) {
          delete resultStrategy.settings.average._id;
        }
        apiStrategy.saveStrategy({ ...resultStrategy, name: strategyName }).then(() => {});
      }
    });
  };

  const addNewCond = (groupId: number): void => {
    const strategyType = selectedChartData.chartType?.toLowerCase();
    const indicators = (
      strategy.mode === StrategyModes.HEDGE
        ? strategy.strategies[strategyType].indicators
        : strategy.indicators
    ).slice();
    const group = indicators[groupId]?.slice() ?? [];

    group.push({
      name: IndicatorsList.RSI_OverLevels,
      settings: {
        interval: 14,
        over_sold: 30,
        over_bought: 70,
        smooth_type: IndicatorsList.SMA,
        source: IndicatorSource.Close,
      },
    });

    indicators[groupId] = group;

    if (strategy.mode === StrategyModes.HEDGE) {
      updateStrategy({
        strategies: {
          ...strategy.strategies,
          [strategyType]: { ...strategy.strategies[strategyType], indicators },
        },
      });
    } else {
      updateStrategy({ indicators });
    }
  };
  const removeCond = (condId: number): void => {
    const strategyType = selectedChartData.chartType?.toLowerCase();
    const indicators = (
      strategy.mode === StrategyModes.HEDGE
        ? strategy.strategies[strategyType].indicators
        : strategy.indicators
    ).slice();

    indicators.splice(condId, 1);

    if (strategy.mode === StrategyModes.HEDGE) {
      updateStrategy({
        strategies: {
          ...strategy.strategies,
          [strategyType]: { ...strategy.strategies[strategyType], indicators },
        },
      });
    } else {
      updateStrategy({ indicators });
    }
  };
  const removeCondGroup = (groupId: number, condId: number): void => {
    const strategyType = selectedChartData.chartType?.toLowerCase();
    const indicators = (
      strategy.mode === StrategyModes.HEDGE
        ? strategy.strategies[strategyType].indicators
        : strategy.indicators
    ).slice();
    const group = indicators[groupId].slice();

    if (!group || group[condId] === undefined || (indicators.length === 1 && group.length === 1))
      return;

    group.splice(condId, 1);
    indicators[groupId] = group;

    if (group.length === 0) {
      indicators.splice(groupId, 1);
    }

    if (strategy.mode === StrategyModes.HEDGE) {
      updateStrategy({
        strategies: {
          ...strategy.strategies,
          [strategyType]: { ...strategy.strategies[strategyType], indicators },
        },
      });
    } else {
      updateStrategy({ indicators });
    }
  };

  const addNewCondGroup = (): void => {
    const strategyType = selectedChartData.chartType?.toLowerCase();
    if (strategy.mode === StrategyModes.HEDGE) {
      const indicators = strategy.strategies[strategyType].indicators.slice();
      const index = indicators.push([]) - 1;
      addNewCond(index);
    } else {
      const indicators = strategy.indicators.slice();
      const index = indicators.push([]) - 1;
      addNewCond(index);
    }
  };
  const changeCond = (groupId: number, condId: number, value: IndicatorsList): void => {
    const strategyType = selectedChartData.chartType?.toLowerCase();
    const indicators = (
      strategy.mode === StrategyModes.HEDGE
        ? strategy.strategies[strategyType].indicators
        : strategy.indicators
    ).slice();

    const group = indicators[groupId].slice();
    if (!group?.[condId]) return;
    group[condId] = {
      name: value,
      settings: { ...STRATEGY_SETTINGS[value] },
    };
    indicators[groupId] = group;

    if (strategy.mode === StrategyModes.HEDGE) {
      updateStrategy({
        strategies: {
          ...strategy.strategies,
          [strategyType]: { ...strategy.strategies[strategyType], indicators },
        },
      });
    } else {
      updateStrategy({ indicators });
    }
  };
  const changeSettings = (settings: ISettingsIndicator, name: number): void => {
    if (isStrategySettings === null) return;
    const strategyType = selectedChartData.chartType?.toLowerCase();
    const indicators = (
      strategy.mode === StrategyModes.HEDGE
        ? strategy.strategies[strategyType].indicators
        : strategy.indicators
    ).slice();
    const group = indicators[isStrategySettings[0]].slice();

    if (!group?.[isStrategySettings[1]]) return;
    const elem = { ...group[isStrategySettings[1]] };
    elem.name = name;
    elem.settings = settings;
    group[isStrategySettings[1]] = elem;
    indicators[isStrategySettings[0]] = group;

    if (strategy.mode === StrategyModes.HEDGE) {
      updateStrategy({
        strategies: {
          ...strategy.strategies,
          [strategyType]: { ...strategy.strategies[strategyType], indicators },
        },
      });
    } else {
      updateStrategy({ indicators });
    }
  };
  if (!strategy) return;

  return (
    <div className="strategy">
      {isStrategySettings === null &&
        !isStrategyTypeSettings &&
        !isStrategyTypeSettingsLong &&
        !isStrategyTypeSettingsShort &&
        !isOtherFilters &&
        !isTgBotSettings && (
          <>
            <div className="strategy__main">
              {activePage === 0 && (
                <>
                  {strategy.mode === StrategyModes.HEDGE && (
                    <div className="strategy_hedgeContainer">
                      <StrategySwitcher
                        options={constructorPages}
                        activeOption={activePage}
                        onChange={setActivePage}
                        height={35}
                        activeColor={COLORS.LIGHT_VIOLET}
                      />
                      <StrategyName
                        onChange={(name) => {
                          setStrategyName(name);
                        }}
                        onSave={saveStrategyName}
                        name={strategy?.name ? strategyName : ''}
                        isMergedStrategy={false}
                      />
                      <div className="strategy__strategiesSwitcher">
                        <div
                          style={{ backgroundColor: openedChart === 'long' ? '#30D960' : '' }}
                          className="strategy__strategiesSwitcher__item"
                          onClick={() => {
                            dispatch(setSelectedChart(chartLongID));
                          }}
                        >
                          Long
                        </div>
                        <div
                          style={{ backgroundColor: openedChart === 'short' ? '#D04243' : '' }}
                          className="strategy__strategiesSwitcher__item"
                          onClick={() => {
                            dispatch(setSelectedChart(chartShortID));
                          }}
                        >
                          Short
                        </div>
                      </div>
                    </div>
                  )}
                  {strategy.mode === StrategyModes.ONE_SIDE && (
                    <>
                      <StrategyName
                        onChange={(name) => {
                          setStrategyName(name);
                        }}
                        onSave={saveStrategyName}
                        name={strategy?.name ? strategyName : ''}
                        isMergedStrategy={false}
                      />
                      <StrategySwitcher
                        options={constructorPages}
                        activeOption={activePage}
                        onChange={setActivePage}
                        height={35}
                        activeColor={COLORS.LIGHT_VIOLET}
                      />
                    </>
                  )}

                  <StrategyConstructor
                    setStrategyType={setStrategyType}
                    strategyType={strategyType}
                    setStrategyTypeSettings={setStrategyTypeSettings}
                    setStrategyTypeSettingsLong={setStrategyTypeSettingsLong}
                    setStrategyTypeSettingsShort={setStrategyTypeSettingsShort}
                    setStrategyTypeLong={setStrategyTypeLong}
                    setStrategyTypeShort={setStrategyTypeShort}
                    strategyTypeLong={strategyTypeLong}
                    strategyTypeShort={strategyTypeShort}
                    addNewCondGroup={addNewCondGroup}
                    addNewCond={addNewCond}
                    removeCondGroup={removeCondGroup}
                    removeCond={removeCond}
                    changeCond={changeCond}
                    isOpenedLongSettingsTab={isOpenedLongSettingsTab}
                    isOpenedShortSettingsTab={isOpenedShortSettingsTab}
                    openedLongTabCondition={openedLongTabCondition}
                    setOpenedLongTabCondition={setOpenedLongTabCondition}
                    openedShortTabCondition={openedShortTabCondition}
                    setOpenedShortTabCondition={setOpenedShortTabCondition}
                    openSettings={(groupId, condId) => {
                      openedChart === 'long' && setOpenedLongSettingsTab([groupId, condId]);
                      openedChart === 'short' && setOpenedShortSettingsTab([groupId, condId]);
                      setStrategySettings([groupId, condId]);
                    }}
                  />
                  <StrategyOtherFilters setIsOtherFilters={setIsOtherFilters} />
                  {strategy.mode === StrategyModes.HEDGE && <StrategyAdditionalSettings />}
                  <div
                    className="strategy__footer"
                    style={{
                      height: isBackTestDropDown && '140px',
                      paddingTop: isBackTestDropDown && '10px',
                      justifyContent: isBackTestDropDown && 'flex-start',
                    }}
                  >
                    <div className="strategy__footer_buttons">
                      <button
                        className="strategy__footer-automatize"
                        onClick={() => {
                          setIsTgBotSettings(true);
                        }}
                      >
                        Automation/alerts
                        <img src={TgBotIcon} alt="tgbot" />
                      </button>
                      <button
                        className="strategy__footer-save"
                        style={{
                          backgroundColor:
                            isChartLoading || strategyName?.length === 0 || errorName ? 'gray' : '',
                          cursor:
                            isChartLoading || strategyName?.length === 0 || errorName
                              ? 'not-allowed'
                              : '',
                        }}
                        onClick={() => {
                          if (isChartLoading || strategyName?.length === 0 || errorName) return;

                          if (
                            mergedStrategyData?.strategies.findIndex(
                              (strategy) => strategyId === strategy,
                            ) !== -1 &&
                            mergedStrategyData
                          ) {
                            saveStrategy();
                          } else {
                            dispatch(setMergedStrategyData(null));
                          }
                          saveStrategy();
                        }}
                      >
                        Save settings
                      </button>
                    </div>
                    {/* <div className="strategy__footer_dropDown">
                      <BackTestTypeDropDown onChange={setIsBackTestDropDown} />
                    </div> */}
                  </div>
                </>
              )}

              {activePage === 1 && (
                <>
                  {' '}
                  <StrategySwitcher
                    options={constructorPages}
                    activeOption={activePage}
                    onChange={setActivePage}
                    height={35}
                    activeColor={COLORS.LIGHT_VIOLET}
                  />
                  <StrategyProperties />
                </>
              )}
            </div>
          </>
        )}
      {isStrategySettings &&
        strategy.mode === StrategyModes.HEDGE &&
        !isOtherFilters &&
        !isTgBotSettings && (
          <>
            {openedChart === 'long' &&
              strategy.strategies.long.indicators[isStrategySettings[0]]?.[
                isStrategySettings[1]
              ] && (
                <>
                  <StrategySettings
                    onClose={() => {
                      setStrategySettings(null);
                      setOpenedLongSettingsTab(null);
                    }}
                    changeCond={(value) => {
                      changeCond(isStrategySettings[0], isStrategySettings[1], value);
                    }}
                    onChange={changeSettings}
                    indicator={
                      isOpenedLongSettingsTab
                        ? strategy.strategies.long.indicators[isOpenedLongSettingsTab[0]]?.[
                            isOpenedLongSettingsTab[1]
                          ]
                        : strategy.strategies.long.indicators[isStrategySettings[0]]?.[
                            isStrategySettings[1]
                          ]
                    }
                    isStrategySettings={isStrategySettings}
                    indicators={strategy.strategies.long.indicators.slice()}
                    strategyType={'long'}
                  />
                </>
              )}

            {openedChart === 'short' &&
              strategy.strategies.short.indicators[isStrategySettings[0]]?.[
                isStrategySettings[1]
              ] && (
                <StrategySettings
                  onClose={() => {
                    setOpenedShortSettingsTab(null);
                    setStrategySettings(null);
                  }}
                  changeCond={(value) => {
                    changeCond(isStrategySettings[0], isStrategySettings[1], value);
                  }}
                  onChange={changeSettings}
                  indicator={
                    isOpenedShortSettingsTab
                      ? strategy.strategies.short.indicators[isOpenedShortSettingsTab[0]]?.[
                          isOpenedShortSettingsTab[1]
                        ]
                      : strategy.strategies.short.indicators[isStrategySettings[0]]?.[
                          isStrategySettings[1]
                        ]
                  }
                  isStrategySettings={isStrategySettings}
                  indicators={strategy.strategies.short.indicators.slice()}
                  strategyType="short"
                />
              )}
          </>
        )}
      {isStrategySettings &&
        strategy.mode === StrategyModes.ONE_SIDE &&
        !isOtherFilters &&
        !isTgBotSettings && (
          <StrategySettings
            onClose={() => {
              setStrategySettings(null);
            }}
            changeCond={(value) => {
              changeCond(isStrategySettings[0], isStrategySettings[1], value);
            }}
            onChange={changeSettings}
            indicator={strategy.indicators[isStrategySettings[0]]?.[isStrategySettings[1]]}
            isStrategySettings={isStrategySettings}
            indicators={strategy.indicators.slice()}
          />
        )}

      {isStrategyTypeSettings && !isOtherFilters && !isTgBotSettings && (
        <StrategyTypeSettings
          type={strategyType}
          onClose={() => {
            setStrategyTypeSettings(false);
          }}
        />
      )}
      {isStrategyTypeSettingsLong && !isOtherFilters && !isTgBotSettings && (
        <StrategyTypeSettings
          strategyType="long"
          type={strategyTypeLong}
          onClose={() => {
            setStrategyTypeSettingsLong(false);
          }}
        />
      )}
      {isStrategyTypeSettingsShort && !isOtherFilters && !isTgBotSettings && (
        <StrategyTypeSettings
          strategyType="short"
          type={strategyTypeShort}
          onClose={() => {
            setStrategyTypeSettingsShort(false);
          }}
        />
      )}
      {isOtherFilters && (
        <StrategyOtherFiltersSetting
          onClose={() => {
            setIsOtherFilters(false);
          }}
        />
      )}
      {isTgBotSettings && (
        <TgBotSettings
          onClose={() => {
            setIsTgBotSettings(false);
          }}
        />
      )}
    </div>
  );
};
